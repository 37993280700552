import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { callApi } from "../../actions/index";
import SNav from "./nav";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./BZ.css";

function BZ(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (props._BZ === undefined || props._BZ.display_template === undefined) {
    return null;
    //    return <Loader />;
  } else {
    //console.log(props._BZ);
    let responseData = props._BZ.business_category.map((item, i) => {
      let business = props._BZ.businesses.filter((items, i) => {
        return item.b_id_ref.indexOf(items.bid) !== -1;
      });
      let abc = business.map((sitem, i) => {
        return (
          <Link
            style={{ textDecoration: "none" }}
            to={
              props._CS?.col_status === 1
                ? `/store/${sitem.buname}/shop`
                : `/shop`
            }
            onClick={(i) => {
              props.callApi({
                operation: "/products_by_category",
                caller_ref: "BZ.render",
                params: {
                  bid: sitem.bid,
                  sid: sitem.sid,
                  catid: "root",
                  zip: props._BZ.query.params.zip,
                },
              });
            }}
            className="one-store-1 col-lg-4 col-md-6 col-sm-6 col-12 mt-md-0 my-3"
          >
            <h5 className="card-title m-0">{sitem.bname}</h5>
            <div className="one_store_top">
              <div className="business-logo-address position-absolute ">
                <LazyLoadImage
                  src={props._BZ.uris[sitem.blogo.uri] + sitem.blogo.src}
                  className="logo-store mb-2"
                  loading="lazy"
                  alt=""
                />
                <small className="text-center business-address text-dark">
                  {sitem.s_address && sitem.s_address.state
                    ? sitem.s_address.state
                    : sitem.s_address && sitem.s_address.state
                    ? sitem.s_address.city
                    : sitem.s_address && sitem.s_address.street}
                </small>
              </div>
              <LazyLoadImage
                loading="lazy"
                alt=""
                src={`${props._BZ.uris[sitem.bbanner.uri] + sitem.bbanner.src}`}
                className="banner-img img-fluid w-100 d-flex align-items-center"
              />
            </div>

            <div className="d-flex flex-column flex-xl-row justify-content-between mt-0 p-0 ">
              <div className="lead col-12 p-0">
                {typeof sitem.fulfilment == "object" ? (
                  <small className="lead d-flex justify-content-start">
                    {sitem.fulfilment.map((fitem, i) => (
                      <div
                        className="d-types"
                        style={{
                          color: "var(--color_purple)",
                          fontSize: ".65em",
                        }}
                      >
                        {fitem}
                      </div>
                    ))}
                  </small>
                ) : null}
              </div>
            </div>
          </Link>
        );
      });
      return (
        <div>
          <h4 className="store-name mt-3">{item.bcname}</h4>
          <div className="row d-flex px-lg-5 mt-3 border-bottom">{abc}</div>
        </div>
      );
    });
    return (
      <div>
        <SNav />
        <div className="container-fluid bg-white ">
          <span
            style={{
              color: "var(--color_purple)",
              right: "0",
              marginTop: "2.1rem",
            }}
            className="position-absolute font-weight-bold pe-3 pe-md-5 d-md-block d-none"
          >
            view all stores &nbsp;<i className="fab fa-windows"></i>
          </span>
          <div className="container-fluid px-md-5 p-3">
            {responseData}
            <div className="container-fluid px-md-5 p-3">
              <div className="row justify-content-between align-items-center px-md-5 px-4 py-1 mt-4">
                <h4 className="store-name" style={{ opacity: "0" }}>
                  All stores
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state);
  return {
    _BZ: state.item.BZ,
    _GLOBAL: state.item.GLOBAL,
    _CS: state.item.CS,
  };
};

export default withRouter(connect(mapStateToProps, { callApi })(BZ));
