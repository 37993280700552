import React from 'react';
import { Link } from "react-router-dom";
import DNav from "../Navs/nav";
import DSubnav from "../Navs/subnav";
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CardGiftcardTwoToneIcon from '@material-ui/icons/CardGiftcardTwoTone';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';

function AccountSideBar(props) {
   
    
    // import { browserHistory } from 'react-router';

    // //Your initialization
    
    // browserHistory.listen( location =>  {
    //  //Do your stuff here
    // });
   
    React.useEffect(()=>{
        const RemoveBeforeEffect=()=>{
                document.querySelectorAll('.MyAccountSideBarRow').forEach(div=>{
                    div.classList.remove('MyAccountSideBarSelectedDiv')
                })
        }
        document.addEventListener('click',function(e){
            if(e.target.classList.contains('MyAccountSideBarRow')){
                RemoveBeforeEffect()
                e.target.classList.add("MyAccountSideBarSelectedDiv")
            }
            else if(e.target.classList.contains('MyAccountSideBarLink')){   
                RemoveBeforeEffect()
                e.target.parentElement.classList.add('MyAccountSideBarSelectedDiv')
            }
            else if(e.target.classList.contains('MyAccountSideBarSpan')){
                RemoveBeforeEffect()
                e.target.classList.add("MyAccountSideBarSelectedDiv")
            }
           
        },{passive: true})
    })
    return (
        <div>
        <DNav />
        <DSubnav />
        <div className='d-lg-flex d-none mt-3 ms-3' id="MyAccountSideBar">
            <div className={(window.location.href.split("/")[4]==="orders")?"row MyAccountSideBarRow MyAccountSideBarSelectedDiv":"row MyAccountSideBarRow"}><Link className='MyAccountSideBarLink w-100' to="/account/orders" style={{textDecoration:'none'}}>
                <ListAltRoundedIcon fontSize="small"/> &nbsp;
                Your orders
                </Link></div>
                <div className={(window.location.href.split("/")[4]==="address")?"row MyAccountSideBarRow MyAccountSideBarSelectedDiv":"row MyAccountSideBarRow"}><Link className='MyAccountSideBarLink w-100' to="/account/addresses" style={{textDecoration:'none'}}>
              <LocationOnIcon fontSize="small" /> &nbsp;
                Addresses
                </Link>
            </div>
            <div className={(window.location.href.split("/")[4]==="payment")?"row MyAccountSideBarRow MyAccountSideBarSelectedDiv":"row MyAccountSideBarRow"}>
            <Link className='MyAccountSideBarLink w-100' to="/account/payments" style={{textDecoration:'none'}}>
                <CreditCardIcon fontSize="small" /> &nbsp;
                Payments
                </Link>
            </div>
            <div className="row MyAccountSideBarRow">
                <StarBorderOutlinedIcon fontSize="small" /> &nbsp;
                Rapo Express
            </div>
            <div className="row MyAccountSideBarRow">
                <PermIdentityIcon fontSize="small" /> &nbsp;
                Invite friends
            </div>
            <div className="row MyAccountSideBarRow">
                <CreditCardIcon fontSize="small" /> &nbsp;
                Credit,promos & gift
            </div>
            <div className="row MyAccountSideBarRow">
                <CardGiftcardTwoToneIcon fontSize="small" /> &nbsp;
                Buy a gift card
            </div>

            <div className="row MyAccountSideBarRow">
                <InsertEmoticonIcon fontSize="small" /> &nbsp;
                Shop with friends
            </div>

            <div className="row MyAccountSideBarRow">
                <HelpOutlineIcon fontSize="small" /> &nbsp;
                Help
            </div>
                <div className="row MyAccountSideBarRow">
                <SettingsIcon fontSize="small" /> &nbsp;
                Account settings
            </div>
            <div className="row MyAccountSideBarRow">
                <NotificationsActiveOutlinedIcon fontSize="small" /> &nbsp;
                Notifications
            </div>
        </div>
        </div>
    )
}

export default AccountSideBar
