import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { callApi, saveAccountClickedRoute } from "../actions/index";
import PropTypes from "prop-types";
import { logoutUser } from "../actions/authentication";
import Clear from "@material-ui/icons/Clear";

import {
  handleAccountC,
  openCart,
  handleAccountO,
  handleLoginO,
} from "./event_functions";

import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CardGiftcardTwoToneIcon from "@material-ui/icons/CardGiftcardTwoTone";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import SettingsIcon from "@material-ui/icons/Settings";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";

function AcCa(props) {
  const onLogout = (e) => {
    e.preventDefault();
    props.logoutUser(props.history);
  };
  // const [windowWidth, setWidth] = React.useState(window.innerWidth);
  // window.addEventListener("resize", function () {
  //   setWidth(window.innerWidth);
  // });

  useEffect(() => {
    document.addEventListener("click", function (e) {
      let targetEl = e.target;
      if (targetEl.classList.contains("handleAccountOpenDiv")) {
        if (document.getElementsByClassName("account-list")[0]) {
          document.getElementsByClassName("account-list")[0].style.visibility =
            "visible";
          document.getElementsByClassName("account-list")[0].style.opacity =
            "1";
          document.getElementsByClassName("u-icon")[0].style.visibility =
            "visible";
          document.getElementsByClassName("d-icon")[0].style.visibility =
            "hidden";
        }
      } else {
        if (document.getElementsByClassName("account-list")[0]) {
          document.getElementsByClassName("account-list")[0].style.visibility =
            "hidden";
          document.getElementsByClassName("account-list")[0].style.opacity =
            "0";
        }
        if (document.getElementsByClassName("u-icon")[0]) {
          document.getElementsByClassName("u-icon")[0].style.visibility =
            "hidden";
          document.getElementsByClassName("d-icon")[0].style.visibility =
            "visible";
        }
      }
    });
  });

  const { isAuthenticated, user } = props._auth;
  return (
    <div className="d-flex right-ul-cp0" >
      <div className="account-div d-flex position-relative">
        {isAuthenticated ? (
          <i className="user-i fas fa-user"></i>
        ) : (
          <i
            className="signin-i fas fa-sign-in-alt mt-sm-1"
            style={{ fontSize: "1.6em" }}
            onClick={handleLoginO}
          />
        )}
        <p id="account" className="d-flex" style={{ position: "relative" }}>
          &nbsp;<div className="user-name">
           Hi, {isAuthenticated ? user.name.split(" ")[0] : "Sign In"}
          </div>
          <div className="icons d-flex justify-content-center" 
          style={{ width: "25px"}}
          >
            <i
              className="fas fa-chevron-down d-icon handleAccountOpenDiv"
              onClick={(e) => {
                handleAccountO();
              }}
            ></i>
            <i
              className="fas fa-chevron-up mt-2 u-icon"
              style={{ visibility: "hidden" }}
            ></i>
          </div>
        </p>

        {/* --------------------- */}

        {/* {isAuthenticated ? <Link to="/account/orders" style={{ "textDecoration": "none","width":"100%"}}>
           <li style={{ "background":"pink"}}>
          <ListAltRoundedIcon fontSize="small"/> &nbsp;
                Your orders
          </li></Link> : <Link style={{ "textDecoration": "none","width":"100%"}} onClick={()=>{handleLoginO();props.saveAccountClickedRoute("/account/orders");}}>
            <li style={{ "background":"pink"}}>
   <ListAltRoundedIcon fontSize="small"/> &nbsp;
                Your orders
              </li></Link>} */}
        {/*--------------------------- */}
        <ul className="account-list a-list">
          <li className="d-flex pt-3 justify-content-between">
            <span className="d-flex">
              <i className="far fa-user-circle" style={{ fontSize: "2em" }}></i>{" "}
              <h6>Hi, {isAuthenticated ? user.name : "User"}</h6>
            </span>{" "}
            {isAuthenticated ? null : (
              <button className="login-btn" onClick={handleLoginO}>
                Sign In
              </button>
            )}
            <Clear
              className="d-sm-none d-block"
              onClick={(i) => {
                handleAccountC();
              }}
            />
          </li>
          <hr />

          {isAuthenticated ? (
            <Link to="/account/orders" style={{ textDecoration: "none" }}>
              <li>
                <ListAltRoundedIcon fontSize="small" className="account_list_a" /> &nbsp;{" "}
                <span className="account_list_a">Your orders</span>
              </li>
            </Link>
          ) : (
            <Link
              onClick={() => {
                handleLoginO();
                props.saveAccountClickedRoute("/account/orders");
              }}
              style={{ textDecoration: "none" }}
            >
              <li>
                <ListAltRoundedIcon fontSize="small" className="account_list_a" /> &nbsp;{" "}
                <span className="account_list_a">Your orders</span>
              </li>{" "}
            </Link>
          )}

          {isAuthenticated ? (
            <Link to="/account/addresses" style={{ textDecoration: "none" }}>
              <li>
                <LocationOnIcon fontSize="small" className="account_list_a" /> &nbsp;{" "}
                <span className="account_list_a">Addresses</span>
              </li>
            </Link>
          ) : (
            <Link
              onClick={() => {
                handleLoginO();
                props.saveAccountClickedRoute("/account/addresses");
              }}
              style={{ textDecoration: "none" }}
            >
              <li>
                <LocationOnIcon fontSize="small" className="account_list_a" /> &nbsp;{" "}
                <span className="account_list_a">Addresses</span>
              </li>
            </Link>
          )}

          {isAuthenticated ? (
            <Link to="/account/payments" style={{ textDecoration: "none" }}>
              <li>
                <CreditCardIcon fontSize="small" className="account_list_a" /> &nbsp;{" "}
                <span className="account_list_a">Payments</span>
              </li>
            </Link>
          ) : (
            <Link
              onClick={() => {
                handleLoginO();
                props.saveAccountClickedRoute("/account/payments");
              }}
              style={{ textDecoration: "none" }}
            >
              <li>
                <CreditCardIcon fontSize="small" className="account_list_a" /> &nbsp;{" "}
                <span className="account_list_a">Payments</span>
              </li>
            </Link>
          )}
          <li>
            <StarBorderOutlinedIcon fontSize="small" className="account_list_a" /> &nbsp;{" "}
            <span className="account_list_a">Rapo Express</span>
          </li>
          <li>
            <PermIdentityIcon fontSize="small" className="account_list_a" /> &nbsp;{" "}
            <span className="account_list_a">Invite Friends</span>
          </li>
          <li>
            <CreditCardIcon fontSize="small" className="account_list_a" /> &nbsp;{" "}
            <span className="account_list_a">Credit,promos & gift</span>
          </li>
          <li>
            <CardGiftcardTwoToneIcon fontSize="small" className="account_list_a" /> &nbsp;{" "}
            <span className="account_list_a">Buy a gift card</span>
          </li>
          <hr />
          <li>
            <InsertEmoticonIcon fontSize="small" className="account_list_a" /> &nbsp;{" "}
            <span className="account_list_a">Shop with friends</span>
          </li>
          <li>
            <HelpOutlineIcon fontSize="small" className="account_list_a" /> &nbsp;{" "}
            <span className="account_list_a">Help</span>
          </li>
          <li>
            <SettingsIcon fontSize="small" className="account_list_a" /> &nbsp;{" "}
            <span className="account_list_a">Account Settings</span>
          </li>
          <li>
            <NotificationsActiveOutlinedIcon fontSize="small" className="account_list_a" /> &nbsp;{" "}
            <span className="account_list_a">Notifications</span>
          </li>
          {props._auth.isAuthenticated ? (
            <li
              className=""
              onClick={(e, i) => {
                onLogout(e);
                handleAccountC(e);
              }}
            >
              <hr />

              <Link to="/" className=" ">
                {" "}
                <i className="fas fa-sign-out-alt account_list_a"></i>
                &nbsp; <span className="account_list_a">Log out</span>
              </Link>
            </li>
          ) : null}
        </ul>
        {/* kuch */}

        {/* kuch */}
      </div>

      {isAuthenticated ? (
        <div className="carts ml-1" onClick={openCart}>
          <i className=" cart-i fas fa-shopping-cart"></i> &nbsp;
          <span
            className="item"
            style={{
              display: `${props._CART.summary?.qty !== 0 ? "block" : "none"}`,
            }}
          >
            {props._CART.summary?.qty !== 0 ? props._CART.summary?.qty : null}
          </span>
        </div>
      ) : null}
    </div>
  );
}
AcCa.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log(state);
  return {
    _auth: state.auth,
    _CART: state.item.CART,
  };
};

export default connect(mapStateToProps, {
  callApi,
  logoutUser,
  saveAccountClickedRoute,
})(AcCa);
