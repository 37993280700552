import React, { useRef,useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { callApi } from "../../actions/index";
// import Loader from "../Loader/loader";
import LoadingBar from "react-top-loading-bar";
//import Cart from "../cart/cart";
import DNav from "../Navs/nav";
import DSubnav from "../Navs/subnav";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./DB.css";

function DB(props) {
  const ref = useRef(null);
  const barColor = "#f11946";

  useEffect(() => {
    ref.current?.continuousStart();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  if (props._DB === undefined || props._DB.display_template === undefined) {
    //    return null;
    return <LoadingBar color={barColor} ref={ref} /> 
    // return <Loader />;
  } else {
    //console.log(props._DB, "bd-business");
    //      const _DB = JSON.parse(JSON.stringify(props._DB));

    const category = props._DB.category.map((obj, i) => {
      return (
        <div className="catagory" key={i}>
          <LazyLoadImage
            className="back-img"
            src={props._DB.uris[obj.clogo?.uri] + obj.clogo?.src}
            loading="lazy"
            alt=""
          />
          <Link
            to={
              props._CS.col_status === 1
                ? `/store/${props._DB.business.buname}/category/${obj.catpath}`
                : `/category/${obj.catpath}`
            }
            onClick={(i) => {
              props.callApi({
                operation: "/products_by_category",
                caller_ref: "DB.render1",
                params: {
                  bid: props._DB.business.bid,
                  sid: props._DB.business.sid,
                  catid: obj.cid,
                  address: props._LOC.address,
                  location: props._LOC.location,
                  rad: props._LOC.rad,
                },
              });
            }}
          >
            <h5 className="py-2" style={{ color: "var(--color_purple)" }}>
              {obj.cname}
            </h5>
          </Link>
          {typeof obj.category == "object" ? (
            <div style={{ zIndex: "5", paddingBottom: "30px" }}>
              {obj.category.slice(0, 5).map((sobj, i) => (
                <Link
                  to={
                    props._CS.col_status === 1
                      ? `/store/${props._DB.business.buname}/category/${sobj.catpath}`
                      : `/category/${sobj.catpath}`
                  }
                  onClick={(i) => {
                    props.callApi({
                      operation: "/products_by_category",
                      caller_ref: "DB.render2",
                      params: {
                        bid: props._DB.business.bid,
                        sid: props._DB.business.sid,
                        catid: sobj.cid,
                        address: props._LOC.address,
                        location: props._LOC.location,
                        rad: props._LOC.rad,
                      },
                    });
                  }}
                >
                  <p className="db_dp_name">{sobj.cname}</p>
                </Link>
              ))}
            </div>
          ) : null}
          {obj.category.length > 5 ? (
            // <span>View more ></span>
            <Link
              to={
                props._CS?.col_status === 1
                  ? `/store/${props._DB.business.buname}/category/${obj.cuname}`
                  : `/category/${obj.cuname}`
              }
              onClick={(i) => {
                props.callApi({
                  operation: "/products_by_category",
                  caller_ref: "DB.render3",
                  params: {
                    bid: props._DB.business.bid,
                    sid: props._DB.business.sid,
                    catid: obj.cid,
                    address: props._LOC.address,
                    location: props._LOC.location,
                    rad: props._LOC.rad,
                  },
                });
              }}
            >
              <span>View more...</span>
            </Link>
          ) : null}
        </div>
      );
    });

    return (
      <div>
        <DNav />
        <DSubnav />
        <div className="container-fluid departments px-0 p-0">
          <h2 className="text-sm-center text-left">Departments</h2>
          <div className="department-box">{category}</div>
        </div>
      </div>
    );
  }
}
// department-box py-md-3 py-0 p-0 d-flex flex-wrap justify-content-center
const mapStateToProps = (state) => {
  return {
    _DB: state.item.DB,
    _LOC: state.item.LOC,
    _CS: state.item.CS,
  };
};
export default connect(mapStateToProps, {
  callApi,
})(DB);
