export function getCLICKEDEntity(type, props) {
//console.log(type,props._PDs)
  switch (type) {
    case "PD":
      let __pid = props._CLICK?.pid;
    //console.log(__pid)
    //console.log(props._CLICK)
      let __PD = props._PDs.find((pd) => {
            //console.log(pd.product[0].id,'clicked pid:', __pid);
        return pd.product[0].id === __pid;
      });

      /*if (!__PD) {
        __PD = props._PD;
      } else {
        __PD = __PD[0];
      //console.log(__PD);
      }*/
    //console.log(__PD);
      if(__PD ){ //&& __PD.length !== 0) {
        //__PD = __PD[0];
        //console.log('__PD exists', __PD)
          return __PD;
      } else {
        //console.log('__PD is null')
          return null
      }

      //return __PD;

    default:
    //console.log("default in getCLICKEDEntity");
      return null;
  }
}

export function dconsole(obj){
  console.log(obj)
}