import React from "react";
import { Link } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";
import { connect } from "react-redux";
import { callApi } from "../../../actions/index";
// import missingImg from "../assets/missing-img.jpeg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "../Account.css";

function OL(props) {
  React.useEffect(() => {
    props.callApi({
      operation: "/order_list",
      caller_ref: "OL.useEffect",
      params: {
        // uid: localStorage.getItem('jwtToken')
      },
    });
  }, []); // eslint-disable-line

  let missingImg =
    "https://firebasestorage.googleapis.com/v0/b/rapo-prod.appspot.com/o/pimages%2Fmissing-item-image?alt=media&token=26ad9768-4511-4d20-bd35-c499f2717cdd";

  const response = props._OL.ol?.map((os) => {
    return (
      <div
        className="bg-white px-4 py-2 mb-3 rounded mx-2"
        style={{ boxShadow: "1px 1px 7px 2px rgb(230, 228, 228)" }}
      >
        <div className="col-12 d-flex p-0">
          <div className="col-md-4 order-1 col-7 py-md-0 py-2 d-flex flex-column justify-content-center">
            <h6 className="h6">Order placed</h6>
            <span className="p-0">
              {/* <small >Apr 20,2020, 2:53 PM</small> */}
              <small>{os.order_date}</small>
            </span>
          </div>
          <div className="col-lg-3 col-md-4 order-lg-2 order-2"></div>
          <div className="col-lg-5 col-md-4 order-3 order-1 col-4 d-flex flex-md-row flex-column justify-content-between mt-md-0 mt-2 ms-auto  p-0">
            <div className="col-md-3 d-flex flex-md-column flex-row align-items-center text-center p-0">
              <h6 className="h6 mt-md-0 mt-2">Items</h6>
              <span className="d-md-none">&nbsp;&nbsp;</span>
              <span className="p-0">
                <small>{os.qty}</small>
              </span>
            </div>
            <div className="col-md-3 d-flex flex-md-column flex-row align-items-center text-center p-0">
              <h6 className="h6 mt-md-0 mt-2">Total</h6>
              <span className="d-md-none">&nbsp;&nbsp;</span>
              <span className="p-0">
                <small>${os.amount}</small>
              </span>
            </div>
            <div className="col-md-5 d-flex flex-md-column flex-row text-center p-0 align-items-center">
              <h6 className="h6 mt-md-0 mt-2">
                <u>Order</u> Status
              </h6>
              <span className="d-md-none">&nbsp;&nbsp;</span>
              <span className="p-0">
                <small>{os.status === 0 ? "Pending" : "Picked up"}</small>
              </span>
            </div>
          </div>
        </div>
        {/* ------------- */}
        <div className="orders_div w-100">
          {os.sorders.map((sorder) => {
            return (
              <div className="col-12 position-relative p-0">
                <div
                  className="col-12 position-relative"
                  style={{ paddingBottom: "1rem", marginTop: "-8px" }}
                >
                  <div className="col-xl-4 col-lg-4 col-md-6 d-flex MyAccountHr text-center justify-content-center align-items-center m-auto">
                    {/* <img src={Pickup} className="img-fluid MyAccountHrIcon" alt="" /> */}
                    {sorder.fulfilment?.type === "pickup" ? (
                      <i className="fas fa-truck-pickup mr-1 mr-sm-2"></i>
                    ) : sorder.fulfilment?.type === "delivery" ? (
                      <i className="fas fa-truck-loading mr-1 mr-sm-2"></i>
                    ) : sorder.fulfilment?.type === "ship" ? (
                      <i className="fas fa-dolly-flatbed mr-1 mr-sm-2"></i>
                    ) : sorder.fulfilment?.type === "in-Store" ? (
                      <i className="fas fa-store-alt mr-1 mr-sm-2"></i>
                    ) : null}
                    <small style={{ fontWeight: 300, fontSize: "0.7rem" }}>
                      {sorder.fulfilment?.time_slot
                        ? sorder.fulfilment?.time_slot.sdate +
                          " " +
                          sorder.fulfilment?.time_slot.stime +
                          " - " +
                          sorder.fulfilment?.time_slot.etime
                        : null}
                    </small>
                    &nbsp;
                    <small style={{ fontWeight: 300, fontSize: "0.7rem" }}>
                      {sorder.s_address.street},{" "}
                      {sorder.s_address.state
                        ? "," + sorder.s_address.state
                        : null}
                    </small>
                  </div>
                  <div className="col-12 border-bottom "></div>
                </div>

                <div className="col-12 p-0 d-flex">
                  <div className="col-lg-5 col-md-4 col-7 mt-md-0 py-md-0 py-1 d-flex p-0">
                    {/* <img src={sorder.uris[sorder?.blogo?.uri] + sorder?.blogo?.src} className='img-fluid MyAccountStoreImg' alt="" /> &nbsp; */}

                    <div className="" style={{ lineHeight: "20px" }}>
                      <h6 className="h6">
                        {sorder.bname}(
                        <small
                          style={{ fontSize: "12px", fontWeight: "normal" }}
                        >
                          {sorder.s_address.street}{" "}
                          {sorder.s_address.state
                            ? "," + sorder.s_address.state
                            : null}
                        </small>
                        )
                      </h6>
                      {/* <span className='p-0' style={{ lineHeight: "0.9rem" }}>
                                                <small>{sorder.s_address.street} {sorder.s_address.state ? ("," + sorder.s_address.state) : null}</small>
                                            </span> */}
                    </div>
                  </div>

                  <div className=" col-lg-5 col-4 ms-auto d-flex flex-md-row flex-column justify-content-between align-items-center p-0 pt-md-0">
                    <div className="col-md-3 d-flex flex-md-column flex-row align-items-center text-center p-0 ">
                      <span className="p-0">
                        <small>{sorder.qty}</small>
                      </span>
                    </div>
                    <div className="col-md-3 d-flex flex-md-column flex-row align-items-center text-center p-0">
                      <span className="p-0">
                        {/* <small >${Math.round(sorder.amount*10)/10}</small> */}
                        <small>${sorder.amount.toFixed(2)}</small>
                      </span>
                    </div>
                    <div className="col-md-5 d-flex flex-md-column flex-row text-center p-0 align-items-center">
                      <span className="p-0">
                        <small>
                          {os.status === 0 ? "Pending" : "Picked up"}
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="p-0 m-0 w-100 orderItemImgs">
                  <div className="w-100 d-flex px-lg-1 px-0 align-items-center p-0">
                    {sorder?.product?.map((pr) => (
                      <LazyLoadImage
                        loading="lazy"
                        src={
                          pr?.pthumb
                            ? pr?.uris[pr?.pthumb?.uri] + pr?.pthumb?.src
                            : missingImg
                        }
                        className="img-fluid mt-1 orderItemImg ms-1"
                        alt="not found"
                      />
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-12 mt-md-0 mt-3 d-flex justify-content-end p-0 ">
          <h6 className="m-0 purple h6 d-flex align-items-center">
            <Link
              to={"/account/orderdetails/" + os._id}
              style={{ textDecoration: "none" }}
              onClick={(e, i) => {
                props.callApi({
                  operation: "/order_details",
                  caller_ref: "OD.render1",
                  params: {
                    // uid: localStorage.getItem('jwtToken'),
                    oid: os._id,
                  },
                });
              }}
            >
              View order details
            </Link>
          </h6>{" "}
          <ChevronRight />
        </div>
      </div>
    );
  });

  return (
    <div className="col-lg-10 ms-xl-4 ms-lg-3 orders_main">
      <div className="d-flex align-items-center mt-3 px-2">
        {/* <Link to="/">
                        <small>Home</small>
                    </Link>
                    <ChevronRight className='UIChevronRight' /> */}
        <Link to="/account">
          <small>My account</small>
        </Link>
        <ChevronRight className="UIChevronRight" />
        <small>Your orders</small>
      </div>
      <div className="row py-2 pl-2 m-0 px-2">
        <h5>Your Orders</h5>
      </div>
      {/* ---------------order---------------------- */}
      {response}
      {/* ---------------------order------------------- */}
    </div>
  );
}
const mapStateToProps = (state) => {
  //console.log(state);
  return {
    //auth: state.auth,
    _OL: state.item.OL,
  };
};

export default connect(mapStateToProps, {
  callApi,
})(OL);
