import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { ChevronRight } from "@material-ui/icons";
import { connect } from "react-redux";
import {
  callApi,
  setZipcode,
  updateFulfilmentAid,
  setClickedIndex,
  checkPAPopup
} from "../../../actions/index";
import { showAddressPopup,clearFormData } from "../../event_functions";
import "../../CP0/CP0.css";
//import AddressPopup from "../../AddressPopup";

function Address(props) {

  const deleteAddressByAid = (_aid) => {
    props.callApi({
      operation: "/delete_address",
      caller_ref: "Address.deleteAddressByAid",
      params: {
        // bid: props._GLOBAL.business?.bid,
        aid: _aid,
      },
    });
  }

  const setDefaultAddressByAid = (_aid) => {

    const _sel_addr = props._LA.address.find((addr) => {
      return addr._id === _aid;
    })

    props.callApi({
      operation: "/edit_address",
      caller_ref: "Address.setDefaultAddressByAid",
      params: {
        aid: _aid,
        // bid: props._GLOBAL.business.bid,
        name: _sel_addr.name,
        addr_line1: _sel_addr.addr_line1,
        addr_line2: _sel_addr.addr_line2,
        city: _sel_addr.city,
        state: _sel_addr.state,
        country: _sel_addr.country,
        zipcode: _sel_addr.zipcode,
        phone: _sel_addr.phone,
        del_instruction: _sel_addr.del_instruction,
        checkedDefaultAddress: true,
      },
    });
  }
  


  useEffect(() => {
    //console.log(props._RESPONSE);
    // if (
    //   props._RESPONSE.msg === "Address added" ||
    //   props._RESPONSE.msg === "Address deleted" ||
    //   props._RESPONSE.msg === "Address updated"
    // )
    //   props.callApi({
    //     operation: "/list_address",
    //     caller_ref: "Address.useEffect",
    //     params: {
    //       bid: props._GLOBAL.business.bid,
    //     },
    //   });
  }, [props._RESPONSE]);

  useEffect(() => {
    //console.log("Calling Addresses from backend....",props._GLOBAL.business);
    //load only if addresses are not loaded before for first time after refresh
    if (!props._LA?.address && props._GLOBAL.business?.bid) {
      props.callApi({
        operation: "/list_address",
        caller_ref: "Address.useEffect",
        params: {
          // bid: props._GLOBAL.business?.bid,
        },
      });
    }
    //props.syncAccount();
  }, [props._GLOBAL]); // eslint-disable-line


//console.log("### Address #########", props._LA);
  return (
      <div className="col-lg-10 ms-xl-5 ms-lg-3 px-1 orders_main">
        {/* <AddressPopup /> */}
        <div className="d-flex align-items-center mt-3">
          {/* <Link to="/">
            <small>Home</small>
          </Link>
          <ChevronRight className="UIChevronRight" /> */}
          <Link to="/account">
            <small>My account</small>
          </Link>
          <ChevronRight className="UIChevronRight" />
          <small>Addresses</small>
        </div>
        <div className="row py-2">
          <h5>Your Addresses</h5>
        </div>
        <div className="row d-flex flex-wrap rounded">
          {props._LA.address?.map((obj, i) => (
            <div
              // key={i}
              className="each-addr-box col-xl-3 col-lg-4 col-md-4 col-sm-6 p-1"
              onClick={(e, i) => {
                // getSelectedAddress(e, obj);
              }}
            >
              <div
                // address-box
                className="address-white-box order"
                style={{ boxShadow: "0px 0px 3px 1px rgb(230, 228, 228)" }}
              >
                <div className="card-body d-flex flex-column p-2">
                  <h6 className="DeliveryInstructionH1">{obj.name}</h6>
                  <div className="address-123 d-flex flex-column">
                    <small>{obj.addr_line1}</small>
                    <small>{obj.addr_line2}</small>
                    <small>
                      {obj.city}, {obj.state} {obj.zipcode}
                    </small>
                    <small>{obj.country}</small>
                  </div>
                  <h6 className="DeliveryInstructionH2" style={{}}>
                    Delivery Instructions
                  </h6>
                  <small
                    style={{ color: "grey" }}
                    className="pb-1 DeliveryInstructionDiv position-relative overflow-hidden"
                  >
                    {obj.del_instruction}
                  </small>
                  <div className="col d-flex flex-wrap justify-content-between px-1">
                    <div className="d-flex">
                      <small
                        className="text-primary editFormBtn"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          props.setClickedIndex(obj._id);
                          showAddressPopup(e);
                          props.checkPAPopup("account")
                        }}
                      >
                        Edit
                      </small>{" "}
                      &nbsp;&nbsp;
                      <small
                        className="text-danger "
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          deleteAddressByAid(obj._id);
                        }}
                      >
                        Remove
                      </small>
                    </div>
                    {obj.checkedDefaultAddress === true ? (
                      <small>
                        <i className="fas fa-check"></i> &nbsp;Default
                      </small>
                    ) : (
                      <small
                        className="default-address text-primary"
                        onClick={(e, i) => {
                          setDefaultAddressByAid(obj._id)
                        }}
                      >
                        Make Default
                      </small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 px-1">
            <div className="add-addr-box d-flex flex-column justify-content-center align-items-center mt-1">
              <i
                style={{
                  color: "var(--color_purple)",
                  cursor: "pointer",
                  fontSize: "3em",
                }}
                className="mb-2 fas fa-plus-circle"
                onClick={(e) => {
                  props.setClickedIndex("");
                  showAddressPopup(e);
                  clearFormData();
                }}
              ></i>

              <h6>Add New Address</h6>
            </div>
          </div>
          {/* <AddressPopup
                      checkState={CheckState}
                      editedData={editedData === "" ? null : editedData}
                    /> */}
         
        </div>
      </div>

  );
}

// -----------------------------------------

const mapStateToProps = (state) => {
  //console.log("State change event in Address",state);
  return {
    _BZ: state.item.BZ,
    _GLOBAL: state.item.GLOBAL,
    _ZR: state.item.ZR,
    _SS: state.item.SS,
    _LA: state.item.LA,
    _CART: state.item.CART,
    _RESPONSE: state.item.RESPONSE,
    _PAPopup:state.item.PAPopup
  };
};

export default withRouter(
  connect(mapStateToProps, {
    callApi,
    setZipcode,
    updateFulfilmentAid,
    setClickedIndex,
    checkPAPopup
  })(Address)
);
