import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactImageMagnify from "react-image-magnify";

const LeftImages = (props) => {
    const handleGallery = (e) => {
      if (e.target.classList.contains("w3-hover-opacity")) {
        props.setItemDetailPage(e.target.src);
      } else {
        props.setItemDetailPage(null);
      }
      let divs = document.querySelectorAll(".w3-hover-opacity");
      divs.forEach((div) => {
        div.classList.remove("selected-img");
      });
      e.currentTarget.classList.add("selected-img");
    };
    // console.log(props.obj?.imgs,"uris")
    return (
        <div className="col-lg-6 col-md-5 left-image d-flex flex-lg-row flex-column">
        {props.obj?.imgs.length > 1
          ? 
            (
              <div className="col-lg-2 order-2 col-12 d-lg-block d-flex p-0 justify-content-center">
                {typeof props.obj?.imgs == "object"
                  ? props.obj?.imgs.map((img, i) => (
                      <div
                        className={
                          props.popup === false
                            ? "w3-third col-lg-11 col-md-3 col-sm-2 col-2 p-0 px-md-3 mt-2 px-1"
                            : `w3-third col-lg-12 col-md-3 col-sm-2 col-2  px-md-3 px-1 mt-2 `
                        }
                      >
                        {
                          //console.log(props.obj.imgs[0],'i',i,'oooooooooo')
                        }
                        <LazyLoadImage
                          src={`${props.__PD.uris[img?.uri] + img?.src}`}
                          loading="lazy"
                          style={{
                            width: "100%",
                            cursor: "pointer",
                            borderRadius: "5px",
                            border: "1px solid var(--color_purple)",
                          }}
                          onClick={handleGallery}
                          onMouseOver={handleGallery}
                          className={` w3-hover-opacity w-75 ${
                            i === 0 ? "selected-img" : ""
                          }`}
                          alt=""
                        />
                      </div>
                    ))
                  : null}
              </div>
            )
          : null}

        <div
          className={
            props.popup === false
              ? "col-lg-10 col-md-11  col-sm-9 m-auto order-lg-2 order-1 col-11 d-flex align-items-center justify-content-center "
              : " col-lg-10 col-md-11 col-sm-9 m-auto order-lg-2  order-1 col-11 d-flex align-items-center justify-content-center"
          }
        >
          <div
            className={
              props.popup === false
                ? "col-lg-9 col-10 mr-auto py-md-0 py-3 m-auto "
                : "col-lg-10 col-8 py-md-0 mr-lg-auto mr-0 m-lg-0 m-auto py-3 p-0"
            }
          >
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: props.obj?.title,
                  isFluidWidth: true,
                  src:
                    props.itemDetailPage === null
                      ?
                       props.__PD.uris[props.obj?.imgs[0]?.uri] + props.obj?.imgs[0]?.src
                      : props.itemDetailPage,
                },
                largeImage: {
                  src:
                    props.itemDetailPage === null
                      ? props.__PD.uris[props.obj?.imgs[0]?.uri] + props.obj?.imgs[0]?.src
                      : props.itemDetailPage,
                  width: 1200,
                  height: 1200,
                },
                enlargedImageContainerDimensions: {
                  width: props.__PD.uris.length ? "175%" : "175%",
                  height: props.obj.vars ? "100%" : "100%",
                },
                shouldHideHintAfterFirstActivation: false,
                shouldUsePositiveSpaceLens: true,
              }}
              className="img-fluid "
              id="item-detail-page"
              style={{ zIndex: 2 }}
            />
          </div>
        </div>
        </div>
    )
}

export default LeftImages
