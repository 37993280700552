import React, { useRef, useEffect } from "react";

import { connect } from "react-redux";
import CategorySidenav from "./category_sidenav";
import { Link } from "react-router-dom";
import { callApi } from "../../actions/index";
import CAROUSEL from "../CAROUSEL/CAROUSEL";
import DNav from "../Navs/nav";
import DSubnav from "../Navs/subnav";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LoadingBar from "react-top-loading-bar";

function CP1(props) {
  const ref = useRef(null);
  const barColor = "#f11946";
  useEffect(() => {
    ref.current?.continuousStart();
    //props.syncCart();
    // console.log(props,'syncCart is called....')
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //  console.log('######## In CP1 rendering....')

  if (props._CP1 && props._CP1.display_template !== undefined) {
    const linkpaths = props._CP1.path.slice(0, props._CP1.path.length - 1);

    const linkpath = linkpaths.map((path) => {
      return (
        <span style={{ textDecoration: "none" }}>
          &nbsp;
          <Link
            to={
              props._CS.col_status === 1
                ? `/store/${props._CP2.business.buname}/category/${path.catpath}`
                : `/category/${path.catpath}`
            }
            onClick={() => {
              props.callApi({
                operation: "/products_by_category",
                caller_ref: "CP1.useEffect",
                params: {
                  bid: props._CP1.business.bid,
                  sid: props._CP1.business.sid,
                  catpath: path.catpath,
                  address: props._LOC.address,
                  location: props._LOC.location,
                  rad: props._LOC.rad,
                },
              });
            }}
          >
            {path.ename}
          </Link>
          &nbsp; /
        </span>
      );
    });
    // ==========check empty products=========
    const _CP1_category = props._CP1.category.filter((item, i) => {
      return item.product.length > 0;
    });
    //console.log(props._CP1.category, "_CP1_category");
    const response = _CP1_category.map((item, index1) => {
      return (
        <CAROUSEL
          key={index1}
          item={item}
          uris={props._CP1.uris}
          bid={props._CP1.business.bid}
          sid={props._CP1.business.sid}
          buname={props._CP1.business.buname}
        />
      );
    });

    const Cp1_error = () => {
      let bannerOfCp1 = document.querySelector(".cp1_banner");
      bannerOfCp1.style.display = "none";
    };
    return (
      <div>
        <DNav />
        <DSubnav />
        <div className="sub-category d-flex p-0">
          <div className="col-2 d-lg-block d-none">
            <CategorySidenav />
          </div>
          <div
            className="col-lg-10 col-12 px-md-3 px-0 pt-md-0 pt-3"
            style={{ background: "rgb(224, 224, 224)" }}
          >
            <div className="pt-4 ps-0">
              <h1>{props.ID}</h1>
            </div>

            {props._CP1.uris[props._CP1.cbanner?.uri] +
              props._CP1.cbanner?.src ===
            isNaN ? null : (
              <div
                className="cp1_banner img-fluid col-12 p-0 text-center"
                id="bannerOfCp1"
              >
                <LazyLoadImage
                  src={`${
                    props._CP1.uris[props._CP1.cbanner?.uri] +
                    props._CP1.cbanner?.src
                  }`}
                  onError={Cp1_error}
                  className="img-fluid col-12 p-0"
                  loading="lazy"
                  id="bannerOfCp1"
                  alt=""
                />
              </div>
            )}
            <div className="upper-link mt-2 ps-2">
              <Link
                to={
                  props._CS?.col_status === 1
                    ? `/store/${props._CP1.business.buname}/shop`
                    : `/shop`
                }
                style={{ textDecoration: "none" }}
              >
                <i
                  className="fas fa-store-alt"
                  style={{ color: "var(--color_purple)" }}
                  onClick={(i) => {
                    props.callApi({
                      operation: "/products_by_category",
                      caller_ref: "CP1.render",
                      params: {
                        bid: props._CP1.business.bid,
                        sid: props._CP1.business.sid,
                        catid: "root",
                        address: props._LOC.address,
                        location: props._LOC.location,
                        rad: props._LOC.rad,
                      },
                    });
                  }}
                ></i>
              </Link>{" "}
              &nbsp;/
              {linkpath} &nbsp;
              <span style={{ textDecoration: "none" }}>{props._CP1.cname}</span>
            </div>
            {response}
          </div>
        </div>
      </div>
    );
  } else {
    // return null;
    return <LoadingBar color={barColor} ref={ref} />
    //    return <Loader />;
  }
}
const mapStateToProps = (state) => {
  return {
    _CP1: state.item.CP1,
    _CART: state.item.CART,
    _LOC: state.item.LOC,
    _CS: state.item.CS,
  };
};
export default connect(mapStateToProps, {
  callApi,
})(CP1);
