import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import DNav from "../Navs/nav";
import DSubnav from "../Navs/subnav";
import { getCLICKEDEntity } from "../Utils/Functions";
import Clear from "@material-ui/icons/Clear";
import { Link } from "react-router-dom";
import { callApi, set_display_template } from "../../actions/index";
// import Loader from "../Loader/loader";
import LoadingBar from "react-top-loading-bar";
import PdDetail from "./PdDetail";
//import { LazyLoadImage } from "react-lazy-load-image-component";

// components
import RightCard from "./RightCard";
import LeftImages from "./LeftImages";
import TextNavPd from "./TextNavPd";
import CAROUSEL from "./PD-CAROUSEL";

function PD(props) {
  // -----------carousel code start------------------

  useEffect(() => {
    props.callApi({
      operation: "/products_by_category",
      caller_ref: "CP0.subnav.subnav.render1",
      params: {
        buname: "houstonwholesaleflooring",
        sid: "cnR8ijzljYNMOmT0Lapj",
        catid: "root",
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const CP0_category = props._CP0.category?.filter((item, i) => {
    return item.product.length > 0;
  });
  // console.log('carousel----',props._CP0)

  // ----------------------carousel code end

  const ref = useRef(null);
  const barColor = "#f11946";
  const handlePopup = () => {
    let _dt = props._GLOBAL.display_template;
    document.body.style.overflowY = "auto";
    if (_dt) {
      props.set_display_template(_dt);
      if (
        _dt.startsWith("cp1") ||
        _dt.startsWith("cp2") ||
        _dt.startsWith("cp3")
      ) {
        //console.log(_dt);
        //window.location.href = props._GLOBAL.url; //back button notworking in CP cases
        window.history.back();
      } else {
        window.history.back();
      }
    } else {
      //console.log("display_template is undefined");
    }
  };

  //Below useEffect is called when Browser back and forward buttons clicked, to handle those events...
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        //this is called when Browser Refresh or back and front then we get parameters from browser URL
        //If PDpopup we load different parameters than PDPage
        const getPDData = () => {
          let _sid;
          let _pid = "";
          let _buname = "";

          if (props.popup === true) {
            _pid = window.location.href.split("/")[6];
            _buname = window.location.href.split("/")[4];
          } else {
            _pid = window.location.href.split("/")[7];
            _sid = window.location.href.split("/")[5];
            _buname = window.location.href.split("/")[4];
          }

          //console.log("about to getClickedEntity");
          let __PD = getCLICKEDEntity("PD", props);
          //console.log(__PD);

          let _s_pid = "";
          if (__PD && __PD.product) {
            _s_pid = __PD.product.filter((item) => {
              //console.log(item.id, _pid);
              return item.id === _pid;
            });
            //console.log(_s_pid.length, _pid);
          }

          //some times we are having old PD data, so above check make sure we have clicked PD data
          // if PD data doesn't exist, then we load PD for that pid
          if (_s_pid.length === 0) {
            //console.log("is it calling api?");
            props.callApi({
              operation: "/products_details",
              caller_ref: "PD.getPDData",
              params: {
                buname: _buname,
                sid: _sid,
                pid: _pid,
              },
            });
          }
        };
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          //console.log("Forward event happened???");
          getPDData();
          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          //console.log("Backward event happened???");
          getPDData();
          // Handle back event
        }
      }
    });
  }, [locationKeys]); // eslint-disable-line

  //This use effect is called once when page component is mounted on refresh or page load.
  useEffect(() => {
    ref.current?.continuousStart();
    //console.log("PD useEffect called");
    let _bid = "";
    let _sid = "";
    let _pid = "";
    let _buname = "";

    if (props.popup === false) {
      if (props.redirect && props.redirect === true) {
        //http://localhost:3000/store/kroger/item/00VU89rg2Vtpof9AkR11
        _pid = window.location.href.split("/")[6];
        _buname = window.location.href.split("/")[4];
      } else {
        _pid = window.location.href.split("/")[7];
        _sid = window.location.href.split("/")[5];
        _buname = window.location.href.split("/")[4];
      }
    }

    // let _s_pid = "";
    // console.log(props);
    // let __PD = getCLICKEDEntity("PD", props);
    // console.log(__PD, props._CLICK?.pid);

    if (!props._CLICK?.pid) {
      if (props.popup === false) {
        //PDPage not PDPopup
        //console.log("Is this PDPage??");
        // if (__PD && __PD.product) {
        //   //came via. click rather than refresh
        //   _s_pid = __PD.product.find((item) => {
        //   //console.log(item.id, _pid);
        //     return item.id === _pid;
        //   });
        // //console.log(_s_pid, _s_pid.length, _pid);
        // }
        //if (_s_pid.length === 0) {
        //console.log("is it calling api in PDPage?");
        // props.callApi({
        //   operation: "/products_details",
        //   caller_ref: "PD2.useEffect1",
        //   params: {
        //     buname: _buname,
        //     bid: _bid,
        //     sid: _sid,
        //     pid: _pid,
        //   },
        // });
        //}
      } else {
        //console.log(props._CLICK.pid);
        if (!__PD && !props._CLICK?.pid) {
            //go inside only on refresh but not clicked
          //console.log(
          //   "PD.componentDidMount PD doesn't exist...................",
          //   __PD
          // );
          let _global = JSON.parse(localStorage.getItem("global"));
          //console.log(_global, "global is null....");
          if (
            _global !== undefined &&
            _global !== null &&
            _global.business.sid !== undefined
          ) {
            //console.log("global exists in PD$$$$$$$$$$$$$$$$$$$$$");
            _bid = _global.business.bid;
            _sid = _global.business.sid;
            _pid = _global.pid;
          }
          _pid = window.location.href.split("/")[6];
          _buname = window.location.href.split("/")[4];
          //console.log("pid:", _pid, "sid:", _sid, "buname:", _buname);

          //if (_s_pid.length === 0) {
          //console.log("is it calling api in PDPopup?");
          // props.callApi({
          //   operation: "/products_details",
          //   caller_ref: "PD.useEffect2",
          //   params: {
          //     buname: _buname,
          //     bid: _bid,
          //     sid: _sid,
          //     pid: _pid,
          //   },
          // });
          //}
        }
      }
    }

    //console.log("PD sync cart is called...");
    //props.syncCart();
    //console.log("useEffect called..");
    // if(document.querySelector('#item-detail-page')===null?null:)
  }, []); // eslint-disable-line

  // -----------------
  let [_obj, setObj] = useState("");
  //  let [_objs, setObjs] = useState("");

  //console.log(props);
  let __PD = getCLICKEDEntity("PD", props);

  const [ptData, setPtData] = useState([]);

  useEffect(() => {
    // console.log("###### PT for PD outside");
    if (__PD?.product[0]){
      if (props._PT[__PD?.product[0].pt_key] !== undefined) {
        setPtData(props._PT[__PD?.product[0]?.pt_key]);
      }
    }
  }, [props._PT, __PD?.product[0].pt_key]); // eslint-disable-line react-hooks/exhaustive-deps

  // const [variantImgs, setVariantImgs] = useState();

  // useEffect(() => {
  //   setVariantImgs(getSelectedVariantImages()[0])
  // }, [__PD]);

  const getSelectedVariantImages = (prod) => {
    let v_imgs = [];
    //console.log(__PD?.product[0], prod);
    if (prod?.vars) {
      prod.vars.forEach((v) => {
        v.opts.forEach((o) => {
          if (o.dsp === 2 && o.imgs.length > 0) {
            o.imgs.forEach((i) => {
              v_imgs.push(i);
            });
          }
        });
      });
    }
    return v_imgs;
  };

  const [itemDetailPage, setItemDetailPage] = useState(null);

  if (__PD?.product === undefined) {
    //return null;
    return <LoadingBar color={barColor} ref={ref} />;
    //return <Loader />;
  } else {
    let obj = _obj === "" ? __PD.product[0] : _obj;

    //Insert Variant images for first time
    const insertVariantImages = (prod) => {
      //console.log(prod.imgs);
      const v_imgs = getSelectedVariantImages(prod);
      //console.log(v_imgs);
      if (!prod.variantImgsInserted) {
        v_imgs.forEach((vi) => {
          prod.imgs.push(vi);
        });
        prod.variantImgsInserted = true;
      }
    };
    __PD.product.forEach((pd) => {
      insertVariantImages(pd);
    });
    //
    // -------------------varients rendering end

    return (
      <div>
        {props.popup === false ? (
          <div>
            <DNav />
            <DSubnav />
          </div>
        ) : null}
        <div
          style={{ background: "rgb(224, 224, 224)" }}
          className={props.popup === false ? "mt-5 pt-4 px-3" : ""}
        >
          <div
            className={
              props.popup === false
                ? "item-detail-page item-detail-pg w-100 text-dark text-center d-block"
                : "item-detail-page fixed-top w-100 h-100 py-md-5 text-dark text-center d-block"
            }
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.700)",
              overflow: "scroll",
              overflowX: "hidden",
            }}
          >
            <div
              className={
                props.popup === false
                  ? "container-fluid m-auto bg-white position-relative p-2 mt-3 border"
                  : "container bg-dark m-auto bg-white position-relative  p-md-4 px-md-0 px-3"
              }
              style={{
                borderRadius: props.popup === false ? null : "5px",
                background: "rgb(224, 224, 224)",
              }}
            >
              <Link
                // style={{ color: "var(--color_purple)" }}
                onClick={handlePopup}
                className={
                  props.popup === false
                    ? "position-relative"
                    : "position-absolute"
                }
                style={{
                  right: "10px",
                  top: "5px",
                  textDecoration: "none",
                  color: "var(--color_purple)",
                  cursor: "pointer",
                }}
              >
                {props.popup === true ? (
                  <span className="" id="popup-close">
                    <Clear />
                  </span>
                ) : (
                  ""
                )}
              </Link>
              <TextNavPd __PD={__PD} _CS={props._CS} popup={props.popup} />
              <div
                className={
                  props.popup === false
                    ? "d-flex flex-md-row flex-column justify-content-between pb-3 py-4 px-1 px-sm-4"
                    : "d-flex flex-md-row flex-column justify-content-between pb-3 py-4 px-1 px-sm-4"
                }
              >
                <LeftImages
                  __PD={__PD}
                  obj={obj}
                  setItemDetailPage={setItemDetailPage}
                  itemDetailPage={itemDetailPage}
                  popup={props.popup}
                />
                <RightCard
                  __PD={__PD}
                  obj={obj}
                  setObj={setObj}
                  setItemDetailPage={setItemDetailPage}
                  _CS={props._CS}
                  popup={props.popup}
                />
              </div>
            </div>
            <PdDetail ptData={ptData} PD={__PD} popup={props.popup} />
            {props._CP0.category && CP0_category[0] && props.popup === false ? (
              <div className="border border-white bg-white">
                <CAROUSEL
                  item={CP0_category[0]}
                  uris={props._CP0.uris}
                  bid={props._CP0.business.bid}
                  sid={props._CP0.business.sid}
                  buname={props._CP0.business.buname}
                  _CS={props._CS.col_status}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log("Global, PD - mapStateToProps: /PD", state.item);
  return {
    _CP0: state.item.CP0,
    _PDs: state.item.PDs,
    _GLOBAL: state.item.GLOBAL,
    _auth: state.auth,
    _CART: state.item.CART,
    _CLICK: state.item.CLICK,
    _CS: state.item.CS,
    _BZ: state.item.BZ,
    _PT: state.item.PT,
  };
};
export default connect(mapStateToProps, {
  callApi,
  set_display_template,
  getCLICKEDEntity,
})(PD);
