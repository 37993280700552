import React, { useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import "./CP0.css";
import { callApi } from "../../actions/index";
import CAROUSEL from "../CAROUSEL/CAROUSEL";
import Nav from "./nav";
import Subnav from "./subnav";
//import Cart from "../CART/CART";
// import Category_nav from '../BC/BC'
import "./CP0.css";
import LoadingBar from "react-top-loading-bar";

function CP0(props) {
  const ref = useRef(null);
  const barColor = "#f11946";
  useEffect(() => {
    ref.current?.continuousStart();
    // let _bid = "";
    // let _sid = "";
    let _buname = "";
    if (
      props._CP0 === undefined ||
      props._CP0 === null ||
      props._CP0.display_template === undefined
    ) {
      //console.log("CP0.componentDidMount CP0 doesn't exist");
      let _global = JSON.parse(localStorage.getItem("global"));
      if (
        _global !== undefined &&
        _global !== null &&
        _global?.business?.sid !== undefined
      ) {
        // _bid = _global.business.bid;
        // _sid = _global.business.sid;
      }
      _buname = window.location.href.split("/")[4];
      if (_buname !== _global?.business?.buname) {
        // _sid = "";
        // _bid = "";
      }
      if (_buname?.length > 0) {
        // _bid = "";
      }
      // let _LOC;
      if (props._LOC?.address) {
        // _LOC = props._LOC;
      } else {
        // _LOC = JSON.parse(localStorage.getItem("LOC"));
      }
      // let _address = _LOC?.address;
      // let _location = _LOC?.location;
      // let _rad = _LOC?.rad;
    }
    //props.syncCart();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.onscroll = function () {
      const animate_div = document.querySelector(".animate-nav");

      const forms = document.querySelector("#form-input");
      const navbar = document.getElementById("navbar");
      const btns = document.querySelectorAll(".white");
      const animate_logo2 = document.querySelector(".animate_logo2");
      const navbar_2 = document.querySelector(".navbar_2");
      let scroll = window.pageYOffset;
      // console.log(scroll)
      if (scroll >= 185) {
        if (animate_div) {
          animate_div.style.position = "fixed";
          animate_div.style.top = "66px";
          animate_div.classList.add("animate-navs");
        }
        if (forms) {
          forms.classList.add("fixed-top");
          forms.style.paddingTop = "1vh";
        }
        if (navbar_2) {
          navbar_2.classList.remove("d-none");
        }
        if (navbar) {
          navbar.style.display = "none";
        }

        btns.forEach((btn) => {
          btn.style.color = "var(--color_white)";
        });
      } else {
        if (navbar_2) {
          navbar_2.classList.add("d-none");
        }
        // if(navbar_2) {
        //   navbar_2.style.display = "none";
        // }
        if (animate_div) {
          animate_div.style.position = "relative";
          animate_div.style.top = "0px";
          animate_div.classList.remove("animate-navs");
        }
        // animate_d_div.classList.remove('animate-d-navs')
        if (forms) {
          forms.classList.remove("fixed-top");
        }
        if (animate_logo2) {
          animate_logo2.style.border = "none";
        }
        if (navbar) {
          navbar.style.display = "flex";
          navbar.style.background = "transparent";
        }

        btns.forEach((btn) => {
          btn.style.color = "white";
        });
      }
    };
  });


  // implement infinite scrolling with intersection observer
  let bottomBoundaryRef = useRef(null);
 // const [pager, pagerDispatch] = useReducer(pageReducer, { page: 0 })

  const scrollObserver = useCallback(
    node => {
      new IntersectionObserver(entries => {
        entries.forEach(en => {
          if (en.intersectionRatio > 0) {
            //pagerDispatch({ type: 'ADVANCE_PAGE' });
            let _query = props._CP0.query;
            let _cat_scroll_key = props._CP0.cat_scroll_key; //props._CP0.category[props._CP0.category.length-1].cat_scroll_key;        
            let _scroll_key = _query.params.scroll_key || -1;
            // console.log("header bottom reached ########## sk:", _scroll_key,"csk:",_cat_scroll_key, props._CP0);
            if (_scroll_key < _cat_scroll_key) {
              _query.params.scroll_key = _cat_scroll_key; //props._CP0.cat_scroll_key;
              props.callApi(_query);
            }
            // console.log("load page....")
          }
        });
      }).observe(node);
    },
    [props._CP0]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (bottomBoundaryRef.current) {
      scrollObserver(bottomBoundaryRef.current);
    }
  }, [scrollObserver, bottomBoundaryRef]);

//  console.log("######## In CP0 rendering....", props._CP0);
  if (props._CP0 === undefined || props._CP0.display_template === undefined) {
    // return null;
    return <LoadingBar color={barColor} ref={ref} />
    //    return <Loader />;
  } else {
    const CP0_category = props._CP0.category.filter((item, i) => {
      return item.product.length > 0;
    });
    const CP0_categoryFilter = CP0_category.map((item, i) => {
      return (
        <CAROUSEL
          item={item}
          uris={props._CP0.uris}
          bid={props._CP0.business.bid}
          sid={props._CP0.business.sid}
          buname={props._CP0.business.buname}
          _CS={props._CS.col_status}
        />
      );
    });

    return (
      <div className="">
        <Nav />
        <Subnav />
        <div
          className="container-fluid px-md-5 px-0 pt-lg-0 pt-1"
          style={{ minHeight: "65vh" }}
          id="CP0"
        >
          {CP0_categoryFilter}
        </div>
        {props._CP0.cat_scroll_key !== undefined ? (
          <div
            className="dots saving text-center"
            id="dotss"
          >
            <span>.</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </div>
        ) : null}
        {props._CP0.cat_scroll_key !== undefined ? <div id='page-bottom-boundary' style={{ border: '1px solid red' }} ref={bottomBoundaryRef}></div>:null}

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log("CP0 state change")
  return {
    _CP0: state.item.CP0,
    _CART: state.item.CART,
    _LOC: state.item.LOC,
    _CS: state.item.CS,
  };
};
export default connect(mapStateToProps, {
  callApi,
})(CP0);
