import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { callApi } from "../../actions/index";

let category_query = {
  operation: "/products_by_category",
  params: {
    bid: "",
    buname: "",
    zip: "",
    catid: "",
    catpath: "",
    userid: "",
  },
};

class category_sidenav extends Component {
  render() {
    //console.log(this.props._CP1.category, "produce nav");
    const listitem = this.props._CP1.category.map((obj, i) => {
      return (
        <div
        // key={i}
        >
          <Link
            to={
              this._CS === 1
                ? `/store/${this.props._CP1.business.buname}/category/${obj.catpath}`
                : `/category/${obj.catpath}`
            }
            onClick={(i) => {
              category_query.params.bid = this.props._CP1.business.bid;
              category_query.params.catid = obj.cid;
              category_query.params.sid = this.props._CP1.business.sid;
              category_query.params.zip = this.props._CP1.query.params.zip;
              category_query.caller_ref = "category_sidenav.render";
              this.props.callApi(category_query);
            }}
          >
            <p style={{ color: "var(--color_purple)" }}>{obj.cname}</p>
          </Link>
        </div>
      );
    });

    return (
      <React.Fragment>
        <div
          className="category-sidenav p-4 d-md-block d-none"
          style={{ zIndex: "1" }}
        >
          <Link
            to={
              this._CS === 1
                ? `/store/${this.props._CP1.business.buname}/departments`
                : `/departments`
            }
          >
            <h5 style={{ color: "var(--color_purple)" }}>Departments</h5>
          </Link>
          {listitem}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state.item.stores)
  return {
    _CP1: state.item.CP1,
    _CS: state.item.CS,
  };
};
export default connect(mapStateToProps, { callApi })(category_sidenav);
