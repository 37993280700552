import Axios from "axios";

export function set_display_template(dt) {
  return (dispatch, getState) => {
    dispatch({ type: "display_template", payload: dt });
  };
}
export function checkCache(query, getState) {
  if (query.operation === "/products_details") {
    //console.log(query, getState().item.PDs);
    let _pid = query.params.pid;
    let m_PD = getState().item.PDs.find((pd) => {
      //console.log(pd, pd.product[0].id, _pid);
      return pd.product[0].id === _pid;
    });
    //console.log(m_PD);
    if (!m_PD) {
      //.length === 0) {
      //PD.length === 0 means PD doesn't exist
      //console.log("PD doesn't exist");
      return null;
    } else {
      m_PD.source = "cache";
      //      m_PD[0].source = "cache";
      //console.log("PD exists after", m_PD);
      return m_PD; //[0];
    }
  } else if (query.operation === "/departments_of_business") {
    let _DB = getState().item.DB;
    //console.log(_DB);
    if (
      _DB &&
      _DB.business &&
      _DB.business.bid === query.params.bid &&
      _DB.business.pid === query.params.pid
    ) {
      _DB.source = "cache";
      return _DB;
    } else {
      return null;
    }
  }
}

export function callApi(query, global) {
  ////console.log("Inside callApi called...", global);
  return (dispatch, getState) => {
    if (global !== undefined) {
      //console.log("are we setting global now???");
      dispatch({ type: "global", payload: global });
    }
    try {
      if (query.operation === "/products_details") {
        let _pid = query.params.pid;
        let _CLICK = getState().item.CLICK;
        _CLICK.pid = _pid;
        //console.log(_CLICK);
        dispatch({ type: "click", payload: _CLICK });
      }
      let entity = checkCache(query, getState);
      if (entity) {
        //console.log("Loading Entity from Cache",entity);
        dispatch({
          type: entity.display_template,
          payload: entity,
        });
      } else {
        console.log(">>>> 1. calling backend now to fetch data...", query);
        let url = "";
        if (
          query.operation === "/address_lookup" ||
          query.operation === "/geo_location"
        ) {
          //url = "http://localhost:3001/lookup";  //local server
          //url = "http://dev.api.rapo.store/lookup"; //development server
          url = "/lookup"; //production server
        } else {
          // url = "http://localhost:3001/api"; //local server
          //url = "http://dev.api.rapo.store/api";  //development server
          url = "/api"; //production server
        }
        Axios.post(url, query, { timeout: 50000 }).then((res) => {
          if (query === undefined) {
            //console.log("query is undefined");
            return null;
          } else {
            //const resp = ;
            //console.log(resp);
            console.log(
              ">>>> 2. In callApi ... Query:",
              query,
              "Response:",
              res.data
            );
            //console.log(JSON.stringify(res.data));
            if (res.data.data) {
              res.data.data.display_template = res.data.display_template;
              res.data.data.source = "server";
              res.data.data.query = query;
              // if (res.data.data.business?.col_status === undefined) {
              //   if (res.data.data.business) {
              //     res.data.data.business.col_status = getState().item.CS;
              //   }
              // }
              // else if (
              //   getState().item.CS &&
              //   res.data.data.business.col_status !== getState().item.CS
              // ) {
              //   console.log(res.data.data.business.col_status,getState().item.CS);
              //   res.data.data.business.col_status = getState().item.CS;
              // }
              if (
                ["cp0", "cp1", "cp2", "cp3"].indexOf(
                  res.data.data.display_template
                ) > -1
              ) {
                res.data.data.cp_display_path =
                  "/category/" + res.data.data.catpath;
                //console.log('##### res.data.data.cp_display_path',res.data.data.cp_display_path)
              }
              //console.log(res.data, res.data.data.display_template,"index.callApi response data....");
              if (res.data.data.display_template) {
                //console.log('payload:',res.data.data, 'type:',res.data.data.display_template,"before calling dispath");
                dispatch({
                  type: res.data.data.display_template,
                  payload: res.data.data,
                });
              }
            } else if (
              res.data &&
              res.data.msg?.toLowerCase() === "not authorized"
            ) {
              //console.log("$$$$$$$$$$$$$$$$$",res.data);
              window.location.href = window.location.origin + "/";
            } else {
              console.log("No where to go so here...");
            }
          }
        });
      }
    } catch (e) {
      console.log(e, "index.callApi Error");
      //dispatch({ type: types.GET_DATA_FAIL, payload: e })
    }
  };
}

export function setLocation(loc) {
  return { type: "set_location", payload: loc };
}

export function syncLocation() {
  return { type: "sync_location", payload: null };
}

export function updateFulfilmentAid(aid) {
  return { type: "fulfilment_aid", payload: aid };
}

export function resetSecureData() {
  return { type: "reset_data", payload: null };
}

export function setType(_type) {
  return { type: "type", payload: _type };
}

export function setZipcode(data) {
  console.log(data, "address lookup");
  return { type: "AL", payload: data };
}

export function getScrollPosition(data) {
  //console.log(data, "scrollposition");
  return { type: "scroll", payload: data };
}

export function setClickedIndex(data) {
  console.log("cidddddd", data);
  return { type: "clicked_index", payload: data };
}

export function saveAccountClickedRoute(data) {
  return { type: "clicked_account_route", payload: data };
}

export function setBZPopupDT(data) {
  return { type: "bzpopup-dtype", payload: data };
}

export function checkPAPopup(data) {
  //console.log(data,'koooooooo2')
  return { type: "pm-popup", payload: data };
}

// export function updateAddressToAccount(data) {
//   return { type: "update_address", payload: data };
// }
// export function setDefaultAddress(data) {
//   return { type: "set_default_address", payload: data };
// }

// export function addAddressToAccount(data) {
//   return { type: "add_address", payload: data };
// }
// export function deleteAddress(data) {
// //console.log(data, "index");
//   return { type: "delete_address", payload: data };
// }

//adds single item from cart for '+' functionality
// export function addItem2Cart(sid, item) {

// //console.log(sid, item, "addItem2Cart");
//   let data = {};
//   data.item = item;
//   data.sid = sid;
//   data.operation = "ADD";
// //console.log("sending query data:", data);
//   return { type: "cart", payload: data };
// }

//removes single item from cart for '-' functionality
// export function removeItem4Cart(sid, item) {
// //console.log(sid, item, "removeItem4Cart");
//   let data = {};
//   data.item = item;
//   data.sid = sid;
//   data.operation = "REMOVE";
// //console.log("sending query data:", data);
//   return { type: "cart", payload: data };
// }

//dell single item from cart page irrespective of quantity for
//that item. this is delete/trash button functionality for item on cart
// export function deleteItem4Cart(sid, item) {
// //console.log(sid, item, "deleteItem4Cart");
//   let data = {};
//   data.item = item;
//   data.sid = sid;
//   data.operation = "DELETE";
// //console.log("sending query data:", data);
//   return { type: "cart", payload: data };
// }

//this method is used to update quantity for cart item via. text field or dropdown with quantity count
// export function updateCartItemQty(sid, item, i_qty) {
// //console.log(sid, item, "updateCartItemQty");
//   let data = {};
//   data.item = item;
//   data.sid = sid;
//   data.i_qty = Number(i_qty);
//   //data.i_qty = Number((i_qty)?i_qty:0);
//   data.operation = "UPDATE_QTY";
// //console.log("sending query data:", data);
//   return { type: "cart", payload: data };
// }

//this method is used to add item cart for first time from details page by selecting more than one item
//from dropdown or text field.
// export function addItem2CartWithQty(sid, item, i_qty) {
// //console.log(sid, item, i_qty, "addItem2CartWithQty");
//   let data = {};
//   data.item = item;
//   data.sid = sid;
//   data.i_qty = Number(i_qty);
//   data.operation = "ADD_QTY";
// //console.log("sending query data:", data);
//   return { type: "cart", payload: data };
// }

/*export function addMessage2CartItem(sid, item, pref) {
//console.log(sid, item, "addMessage2CartItem");
  let data = {};
  data.item = item;
  data.sid = sid;
  data.pref = pref;
  data.operation = "ADD_MSG";
//console.log("sending query data:", data);
  return { type: "cart", payload: data };
}*/

//copies local storage CART data into Redux memory useful on refresh or reopen scenarios
// in future this data is saved in backend DB for the logged in user
// export function syncCart() {
// //console.log("sync_cart");
//   return { type: "sync_cart", payload: "" };
// }

// export function syncAccount() {
// //console.log("sync_account");
//   return { type: "sync_account", payload: "" };
// }

// export function saveFulfilment(fulfilment) {
//   return { type: "fulfilment", payload: fulfilment };
// }
