import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//import Clear from "@material-ui/icons/Clear";
import { callApi, checkPAPopup, setClickedIndex } from "../../actions/index";
//import { Link } from "react-router-dom";
import "./CART.css";
import { consts } from "react-elastic-carousel";
import ScartDate from "./ScartDate";
//import ScartTime from "./ScartTime";
import {
  showAddressPopup,
  showPaymentPopup,
  clearFormData,
} from "../event_functions";

const Fulfilment = (props) => {
  // const [fulfilments, getFulfilments] = useState(
  //     gethFulfilmentsBusinesses(props._BZ.business_category)
  //   );
  const [clickedFulfilment, setClickedFulfilment] = useState("Pickup");
  const [Card, setCard] = useState();
  const [Address, setAddress] = useState({});
  useEffect(() => {
    const _sel_card = props._LC?.cards?.find((card) => {
      return card._id === props.scart?.fulfilment?.card_key;
    });
    setCard(_sel_card);

    if (!props.scart.fulfilment?.type && props.scart.fulfilments.length > 0) {
      //console.log('##########',props.scart.fulfilment?.type);
      setClickedFulfilment(getFulfilmentsForScart(props.scart?.sid)[0]);
      //onFulfilmentClicked(null, getFulfilmentsForScart(props.scart?.sid), props.scart?.sid, props.scart?.bid);      
    } else {
      setClickedFulfilment(props.scart.fulfilment?.type);
    }

    const _sel_addr = props._LA?.address?.find((addr) => {
      return addr._id === props.scart?.fulfilment?.add_key;
    });
    //console.log(_sel_addr, "aaaaaa");
    setAddress(_sel_addr);
    
  }, [props.scart]); // eslint-disable-line

  //cards defaultation
  const setCardToFulfilment = (_cid, _sid) => {

    props.callApi({
      operation: "/save_fulfilment_to_cart",
      caller_ref: "Fulfilment.saveCardToFulfilment",
      params: {
        //bid: `${props._GLOBAL.business?.bid}`,
        sid: _sid,
        cid: _cid,
      },
    });
    removePaymentMethodExpend(_sid);
  };

  const saveAddressFulfilment = (e, _aid, _sid) => {
    //console.log("saveAddressFulfilment", _aid);
    // setClickedAddress(_aid);

    props.callApi({
      operation: "/save_fulfilment_to_cart",
      caller_ref: "Fulfilment.saveAddressFulfilment",
      params: {
        //bid: `${props._GLOBAL.business?.bid}`,
        sid: _sid,
        addr: _aid,
      },
    });
    //////Removing tick mark for all and select tickmark for clicked address
    var addrtick = document.querySelectorAll(".addr-tick");

    if (e.currentTarget.classList.contains("addr-tick")) {
      addrtick.forEach((btn) => {
        btn.classList.remove("addr-tick-select");
      });
      e.currentTarget.classList.add("addr-tick-select");
    }
    removeAddressDivExpend(_sid);
  };

  // cards defaultation
  // ----------address expand collapse methods------------
  const handleAddressDivExpend = (cartid) => {
    document
      .querySelector("#AddressDivExpand_" + cartid)
      .classList.remove("d-none");
    removePaymentMethodExpend(cartid);
    removefulfilmentExpend(cartid);
    document.querySelector(".addr-change_" + cartid).classList.add("d-none");
    document.querySelector(".addr-done_" + cartid).classList.remove("d-none");
  };
  const removeAddressDivExpend = (cartid) => {
    if(document.querySelector("#AddressDivExpand_" + cartid)) {
      document.querySelector("#AddressDivExpand_" + cartid).classList.add("d-none");
      if (document.querySelector(".addr-change_" + cartid)) {
        document.querySelector(".addr-change_" + cartid).classList.remove("d-none");
        document.querySelector(".addr-done_" + cartid).classList.add("d-none");
      }
    }
  };
  // ----------payment expand collapse methods------------
  const paymentMethodDivExpend = (cartid) => {
    document
      .querySelector("#paymentMethodDivExpend_" + cartid)
      .classList.remove("d-none");
    removefulfilmentExpend(cartid);
    removeAddressDivExpend(cartid);
    document.querySelector(".payment-change_" + cartid).classList.add("d-none");
    document
      .querySelector(".payment-done_" + cartid)
      .classList.remove("d-none");
  };
  const removePaymentMethodExpend = (cartid) => {
    (document.querySelector("#paymentMethodDivExpend_" + cartid))?.classList.add("d-none");
    (document.querySelector(".payment-change_" + cartid))?.classList.remove("d-none");
    (document.querySelector(".payment-done_" + cartid))?.classList.add("d-none");
  };
  // ----------fulfilment expand collapse methods------------
  const fulfilmentDivExpend = (_scart) => {
    let _sid = _scart.sid;
    document
      .querySelector("#fulfilmentDivExpend_" + _sid)
      .classList.remove("d-none");
    removeAddressDivExpend(_sid);
    removePaymentMethodExpend(_sid);
    document
      .querySelector(".fulfilment-change_" + _sid)
      .classList.add("d-none");
    document
      .querySelector(".fulfilment-done_" + _sid)
      .classList.remove("d-none");
    
    let _f_type = _scart.fulfilment?.type? _scart.fulfilment?.type : clickedFulfilment.toLowerCase()
    if (_sid  !== props._SS?.business?.sid) {
      props.callApi({
        operation: "/store_schedule",
        caller_ref: "Fulfilment.fulfilmentDivExpend",
        params: {
          bid: _scart?.bid,
          sid: _sid,
          type: _f_type,
        },
      });

    } 
    SelFulfilmentBtn(null, _sid, _f_type)
  };

  const removefulfilmentExpend = (cartid) => {
    (document.querySelector("#fulfilmentDivExpend_" + cartid))?.classList.add("d-none");
    (document.querySelector(".fulfilment-change_" + cartid))?.classList.remove("d-none");
    (document.querySelector(".fulfilment-done_" + cartid))?.classList.add("d-none");
  };
  const removeAllDivsExpend = (cartid) => {
    removeAddressDivExpend(cartid);
    removefulfilmentExpend(cartid);
    removePaymentMethodExpend(cartid);
  };
  const __fulIcons = {
    Pickup: "fas fa-truck-pickup",
    Delivery: "fas fa-truck-loading",
    Ship: "fas fa-dolly-flatbed",
    "In-Store": "fas fa-store-alt",
  };

  const getFulfilmentsForScart = (sid) => {
    let fulfilments = [];
    if(props.scart?.fulfilments) {
      fulfilments = props.scart?.fulfilments
    } else {
      const _bz = props._BZ?.businesses?.find((bz) => {
        return bz.sid === sid;
      });
      //console.log("xxxxx", _bz, sid);
      fulfilments = _bz?.fulfilment;
    }
    fulfilments = fulfilments?.filter((ful) => {
      return ful === "Pickup" || ful === "Delivery";
    });
    // console.log(fulfilments, "fulfilment cart", sid)
    return fulfilments;
  };

  const onFulfilmentClicked = (e, ftype, _sid, _bid) => {
    let _f_type = ftype?.trim();
    //console.log(" cp0 ???", _f_type);

    if (_f_type) {
      setClickedFulfilment(_f_type);
    }
    if (_f_type === "Pickup" || _f_type === "Delivery") {
      props.callApi({
        operation: "/store_schedule",
        caller_ref: "Fulfilment.onFulfilmentClicked",
        params: {
          bid: _bid,
          sid: _sid,
          type: _f_type.toLowerCase(),
        },
      });
    }
    SelFulfilmentBtn(e, _sid, _f_type)
  };

  const SelFulfilmentBtn = (e, _sid, _f_type) => {
    let fulbtn = document.getElementById("fulfilmentDivExpend_" + _sid);
    var btnAdd = fulbtn.querySelectorAll(".s-ful-btn");
    btnAdd.forEach((btn) => {
      btn.classList.remove("btn-active");
      btn.classList.add("btn-deactive");
    });
    if(e) {
      e.currentTarget.classList.add("btn-active");
      e.currentTarget.classList.remove("btn-deactive");
    } else {
      console.log('###########');
      if(document.getElementById(_sid+_f_type.toLowerCase())){
        document.getElementById(_sid+_f_type.toLowerCase()).classList.add("btn-active");
        document.getElementById(_sid+_f_type.toLowerCase()).classList.remove("btn-deactive");
      }
    }
  }
  const carouselArrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? (
        <i
          className="fas fa-arrow-alt-circle-left fa-2x"
          style={{ color: "var(--color_purple)"}}
        ></i>
      ) : (
        <i
          className="fas fa-arrow-alt-circle-right fa-2x"
          style={{ color: "var(--color_purple)"}}
        ></i>
      );
    return (
      <div className="anotherCarouselBtn" onClick={onClick} disabled={isEdge}>
        {pointer}
      </div>
    );
  };
  window.addEventListener('click', function(e){   
    if (document.getElementById('collapseOne_'+props.scart.sid)?.contains(e.target)){
      // Clicked in box
    } else{
      // Clicked outside the box
      //console.log("Clicked outside the box")
      removeAllDivsExpend(props.scart.sid);
    }
  });
  //const { isAuthenticated, user } = props._auth;
  //const { isAuthenticated } = props._auth;
  return (
    <div className="w-100 position-relative">
      <div>
        <div className="px-2 m-auto position-relative accordion-item ">
          <div
            className=" col-12 p-0  accordion-header m-auto"
            id={"headingOne_" + props.scart.sid}
            onClick={(e) => {
              removeAllDivsExpend(props.scart.sid);
            }}
          >
            {/* col-lg-10 col-md-11 col-sm-11 MyAccountHr*/}
            <small
              className="w-100 accordion-button accordion-button-l collapsed p-1 d-flex px-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={"#collapseOne_" + props.scart.sid}
              aria-expanded="false"
              aria-controls={"collapseOne_" + props.scart.sid}
              style={{
                fontWeight: 400,
                fontSize: "0.6rem",
                color: "var(--color_purple)",
              }}
            >
              <div className="">
                {props.scart.fulfilment?.type === "pickup" ? (
                  <span className="d-flex flex-column pe-2 text-center">
                    <i className="fas fa-truck-pickup"></i> <span>Pickup</span>
                  </span>
                ) : props.scart.fulfilment?.type === "delivery" ? (
                  <span className="d-flex flex-column pe-2 text-center">
                    <i className="fas fa-truck-loading"></i>{" "}
                    <span>Delivery</span>
                  </span>
                ) : props.scart.fulfilment?.type === "ship" ? (
                  <span className="d-flex flex-column pe-2 text-center">
                    <i className="fas fa-dolly-flatbed"></i> <span>Ship</span>
                  </span>
                ) : props.scart.fulfilment?.type === "in-store" ? (
                  <span className="d-flex flex-column pe-2 text-center">
                    <i className="fas fa-store-alt"></i> <span>In-Store</span>
                  </span>
                ) : null}
              </div>
              <div className="d-flex flex-column text-center justify-content-center align-items-start">
                <div
                  className={
                    props.scart.c_stat?.f.c === 1 ||
                    !props.scart.fulfilment?.time_slot?.date
                      ? "sel__msg"
                      : ""
                  }
                >
                  {props.scart.c_stat?.f.c === 1 ||
                  !props.scart.fulfilment?.time_slot?.date
                    ? "Select time slot"
                    : "on " +
                      props.scart.fulfilment?.time_slot?.date +
                      ", " +
                      props.scart.fulfilment?.time_slot?.ft +
                      "-" +
                      props.scart.fulfilment?.time_slot?.et}
                </div>
                <div
                  className={props.scart.c_stat?.a.c === 1 ? "sel__msg" : ""}
                >
                  {props.scart.c_stat?.a.c === 1
                    ? "Select address"
                    : props.scart.fulfilment?.type === "pickup"
                    ? "to: " +
                      props.scart.s_address?.street +
                      ", " +
                      props.scart.s_address?.city +
                      ", " +
                      props.scart.s_address?.state +
                      ", " +
                      props.scart.s_address?.country
                    : props._LA?.address?.map((la) =>
                        props.scart?.fulfilment?.add_key === la._id
                          ? la.addr_line1 +
                            ", " +
                            la.city +
                            "," +
                            la.state +
                            ", " +
                            la.country
                          : ""
                      )}
                </div>
                <div
                  className={props.scart.c_stat?.p.c === 1 ? "sel__msg" : ""}
                >
                  {props.scart.c_stat?.p.c === 1
                    ? "Select Payment method"
                    : props._LC?.cards?.map((lc) =>
                        props.scart?.fulfilment?.card_key === lc._id
                          ? "pay: VISA - " +
                            lc.cnumber.slice(-4) +
                            ", " +
                            lc?.exp_month +
                            "/" +
                            lc?.exp_year
                          : " "
                      )}
                </div>
              </div>
            </small>
          </div>
        </div>
        <div className="px-2 ">
          <div
            className="accordion-collapse collapse"
            id={"collapseOne_" + props.scart.sid}
            aria-labelledby={"headingOne_" + props.scart.sid}
            data-bs-parent="#accordionCart"
            style={{ background: "#E0E0E0" }}
          >
            <div className="w-100 text-center position-relative">
              <div className="col-12 px-1 py-1 text-center d-flex flex-column">
                {/* --------------------keep boxes--------------------------------- */}
                <div className="expandTriggerAll d-flex col-12">
                  {/* new */}
                  <div
                    className="each-selection-box col-4"
                    style={{ color: "black", fontSize: ".7em" }}
                    id="fulfilmentDiv"
                  >
                    <div className="address-white-box order border">
                      <div className="card-body d-flex flex-column p-1">
                        <div className="col p-0 d-flex justify-content-between">
                          <h5 className="selection-box-heading">
                            Fulfilment:{" "}
                            {props.scart.fulfilment?.type?.replace(/^\w/, (c) =>
                              c.toUpperCase()
                            )}
                          </h5>
                          <i
                            style={{ cursor: "pointer", marginLeft: "2px" }}
                            className={
                              "far fa-edit fulfilment-change_" + props.scart.sid
                            }
                            onClick={(e) => {
                              fulfilmentDivExpend(props.scart);
                            }}
                          ></i>
                          <i
                            className={
                              "fas fa-times d-none fulfilment-done_" +
                              props.scart.sid
                            }
                            onClick={(e) => {
                              removefulfilmentExpend(props.scart.sid);
                            }}
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                        <div
                          className="address-123 d-flex flex-column"
                          style={{ textAlign: "left" }}
                        >
                          <div className="date-time-cost d-flex flex-wrap row g-1">
                            {props.scart.c_stat?.f.c === 1 ||
                            !props.scart.fulfilment?.time_slot?.date ? (
                              <button
                                className="d-flex position-relative justify-content-center sel_slot_btn"
                                onClick={(e) => {
                                  fulfilmentDivExpend(props.scart);
                                }}
                              > 
                                Select time slot
                              </button>
                            ) : (
                              <>
                                <div className="date-time-cost-in ">
                                  <div className=" d-flex flex-column  bg-light p-01">
                                    <span style={{ color: "grey" }}>Date</span>
                                    <span
                                      style={{
                                        fontSize: ".7em",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {props.scart?.fulfilment?.time_slot?.date}
                                    </span>
                                  </div>
                                </div>
                                <div className="date-time-cost-in ">
                                  <div className=" d-flex flex-column bg-light p-01">
                                    <span style={{ color: "grey" }}>Time</span>
                                    <span
                                      style={{
                                        fontSize: ".7em",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {props.scart?.fulfilment?.time_slot?.ft}-
                                      {props.scart?.fulfilment?.time_slot?.et}
                                    </span>
                                  </div>
                                </div>
                                <div className="date-time-cost-in ">
                                  <div className=" d-flex bg-light p-01">
                                    <span style={{ color: "grey" }}>
                                      Cost:{" "}
                                    </span>
                                    <span
                                      syle={{
                                        color: "green",
                                        fontSize: ".8em",
                                      }}
                                    >
                                      &nbsp;
                                      {props.scart?.fulfilment?.time_slot
                                        ?.price === undefined ||
                                      props.scart?.fulfilment?.time_slot
                                        ?.price === 0
                                        ? "Free"
                                        : props.scart?.fulfilment?.time_slot
                                            ?.price}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* new */}
                  <div
                    className="each-selection-box col-4 bg-white pe-1"
                    style={{ color: "black", fontSize: ".7em" }}
                    id="shippingAddressDiv"
                  >
                    <div
                      // address-box
                      className="address-white-box order border"
                    >
                      <div className="card-body d-flex flex-column p-1">
                        <div className="col p-0 d-flex justify-content-between">
                          <h6 className="selection-box-heading">
                            {props.scart?.fulfilment?.type?.replace(
                              /^\w/,
                              (c) => c.toUpperCase()
                            )}{" "}
                            Address
                          </h6>
                          {props.scart.fulfilment?.type !== "pickup" ? (
                            <>
                              <i
                                style={{ cursor: "pointer" }}
                                className={
                                  "far fa-edit addr-change_" + props.scart.sid
                                }
                                onClick={(e) => {
                                  handleAddressDivExpend(props.scart.sid);
                                }}
                              ></i>

                              <i
                                className={
                                  "fas fa-times d-none addr-done_" +
                                  props.scart.sid
                                }
                                onClick={(e) => {
                                  removeAddressDivExpend(props.scart.sid);
                                }}
                                style={{ cursor: "pointer" }}
                              ></i>
                            </>
                          ) : null}
                        </div>
                        {props.scart.fulfilment?.type === "pickup" ? (
                          <>
                            <div
                              className="address-123 d-flex flex-column"
                              style={{ textAlign: "left" }}
                            >
                              <span className="checkoutSmall">
                                {props.scart.s_address?.name}
                              </span>
                              <div
                                className="checkoutSmall"
                                style={{
                                  color: "grey",
                                  margin: "0px",
                                  lineHeight: "16px",
                                }}
                              >
                                {props.scart.s_address?.street} <br />{" "}
                                {props.scart.s_address?.city},{" "}
                                {props.scart.s_address?.state} <br />{" "}
                                {props.scart.s_address?.country} -{" "}
                                {props.scart.s_address?.zipcode}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {props._LA?.address?.length > 0 ? (
                            <>
                            <div
                              className="address-123 d-flex flex-column"
                              style={{ textAlign: "left" }}
                            >
                              <span className="checkoutSmall">{Address?.name}</span>
                              <div
                                className="checkoutSmall"
                                style={{color: "grey", margin: "0px", lineHeight: "16px"}}
                              >
                                {Address?.addr_line1} <br /> {Address?.city},{" "}
                                {Address?.state} <br /> {Address?.country} -{" "}
                                {Address?.zipcode}
                              </div>
                            </div>
                            <div
                              className="DeliveryInscartuctionH2 checkoutSmall"
                              style={{ textAlign: "left" }}
                            >
                              <em>Delivery Instructions </em>
                            </div>
                            <div style={{ textAlign:"left" }}>
                              { Address?.del_inscartuction ? Address?.del_inscartuction : "None" }
                            </div>
                            </> ) : ( 
                            <>
                              <div className="w-100 p-0">
                        <div
                          // address-box
                          className="border"
                          style={{ color: "black", fontSize: ".7em"}}
                        >
                          <div
                            className="d-flex flex-column align-items-center justify-content-center p-0 bg-white"
                            style={{  cursor:"pointer",height:"120px" }}
                            onClick={(e) => {
                              // handleAddressDivExpend(props.scart.sid);
                              props.setClickedIndex("");
                              showAddressPopup();
                              props.checkPAPopup("cart");
                              clearFormData();
                            }}
                          >
                            <i
                              style={{ color: "var(--color_purple)", fontSize: "3em" }}
                              className="mb-3 fas fa-plus-circle"
                            ></i>
                            <h6 style={{ color: "var(--color_purple)", fontSize:"0.9rem" }}>Add New Address</h6>
                          </div>
                        </div>
                      </div>
                            </> 
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* payment fulfilment
  
                  {/* new */}
                  <div
                    className="each-selection-box col-4 bg-white ps-1"
                    style={{ color: "black", fontSize: ".7em" }}
                    id="paymentMethodDiv"
                  >
                    <div className="address-white-box order border">
                      <div className="card-body d-flex flex-column p-1">
                        <div className="col p-0 d-flex justify-content-between">
                          <h5 className="selection-box-heading">Payment Method</h5>
                          <i
                            style={{ cursor: "pointer" }}
                            className={
                              "far fa-edit payment-change_" + props.scart.sid
                            }
                            onClick={(e) => {
                              paymentMethodDivExpend(props.scart.sid);
                            }}
                          ></i>
                          <i
                            className={
                              "fas fa-times d-none payment-done_" +
                              props.scart.sid
                            }
                            onClick={(e) => {
                              removePaymentMethodExpend(props.scart.sid);
                            }}
                            style={{ cursor: "pointer" }}
                          ></i>
                        </div>
                        <div
                          className="address-123 d-flex flex-column "
                          style={{ textAlign: "left" }}
                        >
                          { props._LC?.cards?.length > 0 ? (
                          <>
                          <span className="checkoutSmall">{Card?.cname}</span>
                          <div className=" w-100">
                            <div className="card-exp-box w-100 bg-light mt-1">
                              <div style={{ color: "grey", fontSize: "0.9em" }}>
                                CARD NUMBER
                              </div>
                              <div>****{Card?.cnumber.slice(-4)} </div>
                            </div>
                            <div className="card-exp-box w-100 bg-light mt-1">
                              <div style={{ color: "grey", fontSize: "0.9em" }}>EXPIRES</div>
                              <div>{Card?.exp_month}/{Card?.exp_year}</div>
                            </div>
                          </div>
                          <span className="mt-1 d-flex justify-content-between bg-light">
                            <span style={{ color: "grey" }}>VISA</span>
                            <span>IMG</span>
                          </span>
                          </> 
                          ) : (
                            <>
                              <div className="w-100 p-0">
                                <div
                                  // address-box
                                  className="border"
                                  style={{ color: "black", fontSize: ".7em" }}
                                >
                                  <div
                                    className="d-flex flex-column align-items-center justify-content-center bg-white "
                                    style={{ height: "120px" }}
                                    onClick={(e) => {
                                      // paymentMethodDivExpend(props.scart.sid);
                                      props.setClickedIndex();
                                      showPaymentPopup();
                                      props.checkPAPopup("cart");
                                      clearFormData();
                                    }}
                                  >
                                    <i
                                      style={{ color: "var(--color_purple)", cursor: "pointer", fontSize: "3em" }}
                                      className="mb-2 fas fa-plus-circle"
                                    ></i>
                                    <h6 style={{ color: "var(--color_purple)", fontSize:"0.9rem" }}>Add New Card</h6>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                {/* address div expand */}
                <div className="expandBoxAll">
                  <div
                    className="addressBoxExpand col-12 p-0 d-none"
                    id={"AddressDivExpand_" + props.scart.sid}
                  >
                    <div
                      className="col d-flex flex-wrap"
                      style={{ background: "var(--color_lightpurple)" }}
                    >
                      {props._LA?.address?.map((la) => (
                        <div className="col-6 p-1">
                          <div
                            // address-box
                            className="border"
                            style={{ color: "black", fontSize: ".7em" }}
                          >
                            <div
                              className="card-body d-flex flex-column p-2 text-start bg-white"
                              style={{ minHeight: "145px" }}
                            >
                              <div className="address-123 d-flex flex-column text-dark position-relative">
                                <span className="checkoutSmall">{la.name}</span>
                                <i
                                  className="cart-addr-tick far fa-check-circle"
                                  style={{
                                    color:
                                      props.scart?.fulfilment?.add_key ===
                                      la._id
                                        ? "#007bff"
                                        : "silver",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    saveAddressFulfilment(
                                      e,
                                      la._id,
                                      props.scart?.sid
                                    );
                                  }}
                                ></i>
                                <div
                                  className="checkoutSmall"
                                  style={{ color: "grey", lineHeight: "16px" }}
                                >
                                  {la.addr_line1} <br /> {la.city}, {la.state}{" "}
                                  <br /> {la.country}
                                  {" - "}
                                  {la.zipcode}
                                </div>
                              </div>
                              <div className="DeliveryInscartuctionH2 checkoutSmall">
                                <em>Delivery Instructions</em>
                              </div>
                              <div style={{ textAlign: "left" }}>
                                {la.del_inscartuction} None
                              </div>
                              <u
                                className="mt-1 text-primary"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  props.setClickedIndex(la._id);
                                  showAddressPopup();
                                  props.checkPAPopup("cart");
                                }}
                              >
                                Edit
                              </u>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="col-6 p-1">
                        <div
                          // address-box
                          className="border"
                          style={{ color: "black", fontSize: ".7em" }}
                        >
                          <div
                            className="card-body d-flex flex-column align-items-center justify-content-center p-2 bg-white text-start"
                            style={{ height: "145px" }}
                          >
                            <i
                              style={{ color: "var(--color_purple)", cursor: "pointer", fontSize: "3em" }}
                              className="mb-3 fas fa-plus-circle"
                              onClick={(e) => {
                                props.setClickedIndex("");
                                showAddressPopup();
                                props.checkPAPopup("cart");
                                clearFormData();
                              }}
                            ></i>
                            <h6 style={{ color: "var(--color_purple)" }}>Add New Address</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* fulfilment div expand */}
                  <div
                    className="fulfilmentBoxExpand col-12 p-1 d-none"
                    style={{ border: "2px solid #675D9A" }}
                    id={"fulfilmentDivExpend_" + props.scart?.sid}
                  >
                    <div className="col p-0">
                      <div className="d-flex justify-content-center text-center p-2 pt-2 position-relative">
                        {getFulfilmentsForScart(props.scart?.sid)?.map(
                          (ful) => {
                            return (
                              <button id={props.scart?.sid+ful.toLowerCase()}
                                className={
                                  props.scart?.fulfilment?.type ===
                                  ful.toLowerCase()
                                    ? "s-ful-btn btn-active rounded d-flex ms-2 "
                                    : "s-ful-btn btn-deactive rounded d-flex ms-2"
                                }
                                onClick={(e) => {
                                  onFulfilmentClicked(e, ful, props.scart?.sid, props.scart?.bid);
                                }}
                              >
                                <i
                                  className={__fulIcons[ful] + " mr-1 mr-sm-2"}
                                  style={{ lineHeight: "inherit" }}
                                ></i>&nbsp;{ful}
                              </button>
                            );
                          }
                        )}
                      </div>
                      {props.scart?.sid === props._SS.business?.sid ? (
                        <ScartDate
                          carouselArrow={carouselArrow}
                          scart={props.scart}
                          fulfilment={clickedFulfilment}
                        />
                      ) : 
                      <div style={{color:"black",fontSize:"0.85rem"}}>loading...</div>
                      }
                    </div>
                  </div>
                  {/* payment div expand */}
                  <div
                    className="paymentBoxExpand col-12 p-0 d-none"
                    id={"paymentMethodDivExpend_" + props.scart?.sid}
                  >
                    <div
                      className="col d-flex flex-wrap p-0"
                      style={{ background: "var(--color_lightpurple)" }}
                    >
                      {props._LC?.cards?.map((lc) => (
                        <div className="col-6 p-1">
                          <div
                            // address-box
                            className="border"
                            style={{ color: "black", fontSize: ".7em" }}
                          >
                            <div className="card-body d-flex flex-column p-2 bg-white">
                              <div
                                className="payment-123 d-flex flex-column text-dark position-relative"
                                style={{ textAlign: "left" }}
                              >
                                <span className="checkoutSmall">
                                  {lc.cname}
                                </span>
                                <i
                                  className="cart-addr-tick far fa-check-circle"
                                  style={{
                                    color:
                                      props.scart?.fulfilment?.card_key ===
                                      lc._id
                                        ? "#007bff"
                                        : "silver",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) =>
                                    setCardToFulfilment(
                                      lc._id,
                                      props.scart?.sid
                                    )
                                  }
                                ></i>
                                <div className="mt-2 d-flex justify-content-between">
                                  <div className="card-exp-box d-flex flex-column">
                                    <span style={{ color: "grey" }}>
                                      CARD NUMBER
                                    </span>
                                    <span>****{lc.cnumber.slice(-4)}</span>
                                  </div>
                                  <div className="card-exp-box d-flex flex-column">
                                    <span style={{ color: "grey" }}>
                                      EXPIRES
                                    </span>
                                    <span>
                                      {lc?.exp_month}/{lc.exp_year}
                                    </span>
                                  </div>
                                </div>
                                <span className="mt-2 d-flex justify-content-between">
                                  <span style={{ color: "grey" }}>VISA</span>
                                  <span>IMG</span>
                                </span>
                                <u
                                  className="text-primary"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    props.setClickedIndex(lc._id);
                                    showPaymentPopup();
                                    props.checkPAPopup("cart");
                                  }}
                                >
                                  Edit
                                </u>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="col-6 p-1">
                        <div
                          // address-box
                          className="border"
                          style={{ color: "black", fontSize: ".7em" }}
                        >
                          <div
                            className="card-body d-flex flex-column align-items-center justify-content-center p-2 bg-white text-start"
                            style={{ height: "116px" }}
                          >
                            <i
                              style={{ color: "var(--color_purple)", cursor: "pointer", fontSize: "3em" }}
                              className="mb-2 fas fa-plus-circle"
                              onClick={(e) => {
                                props.setClickedIndex();
                                showPaymentPopup();
                                props.checkPAPopup("cart");
                                clearFormData();
                              }}
                            ></i>
                            <h6 style={{ color: "var(--color_purple)" }}>Add New Card</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    _CART: state.item.CART,
    _GLOBAL: state.item.GLOBAL,
    _auth: state.auth,
    _BZ: state.item.BZ,
    _LA: state.item.LA,
    _LC: state.item.LC,
    _SS: state.item.SS,
  };
};

export default connect(mapStateToProps, {
  callApi,
  checkPAPopup,
  setClickedIndex,
})(Fulfilment);
