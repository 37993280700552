
// functions-handling
export function handleOpenn() {
  document.getElementsByClassName("browsers")[0].style.display = "block";
  document.getElementsByClassName("browsers")[0].style.opacity = "1";
}

export function handleOpen() {
  document.getElementsByClassName("zipcode-list")[0].style.visibility =
    "visible";
  document.getElementsByClassName("zipcode-list")[0].style.opacity = "1";
}
export function handleClose() {
  document.getElementsByClassName("zipcode-list")[0].style.visibility =
    "hidden";
  document.getElementsByClassName("zipcode-list")[0].style.opacity = "0";
}
export function handleOpenSearch() {
  document.getElementById("search-result").style.display = "block";
}
export function handleCloseSearch() {
  if (document.getElementById("search-result")) {
    document.getElementById("search-result").style.display = "none";
  }
}

export function handleCCloses() {
  if (document.getElementsByClassName("zipcode-lists")[0]) {
    document.getElementsByClassName("zipcode-lists")[0].style.visibility =
      "hidden";
    document.getElementsByClassName("zipcode-lists")[0].style.opacity = "0";
  }
}

export function SstoreClose() {
  if (document.getElementById("selectionStore")) {
    document.getElementById("selectionStore").style.display = "none";
  }
}
export function SstoreOpen() {
//  console.log('#########inside SstoreOpen()')
  document.body.style.overflow="hidden"
  if (document.getElementById("selectionStore")) {
  //  console.log('inside SstoreOpen....#######')
    document.getElementById("selectionStore").style.display = "block";
  }
}
// ........bz new popup
export function BZGridMapViewOpen() {
  document.body.style.overflow="hidden"
  if (document.getElementById("BZGridMapView")) {
    document.getElementById("BZGridMapView").style.display = "block";
  }
}
export function BZGridMapViewClose() {
  document.body.style.overflow="hidden"
  if (document.getElementById("BZGridMapView")) {
    document.getElementById("BZGridMapView").style.display = "none";
  }
}
// bz new popup........
function activeMap(){
  // document.querySelector(".map-icon").classList.add("icon-active")
  // document.querySelector(".grid-icon").classList.remove("icon-active")
  document.querySelector(".map-icon").classList.add("d-none")
  document.querySelector(".grid-icon").classList.remove("d-none")
}
function activeGrid(){
  // document.querySelector(".grid-icon").classList.add("icon-active")
  // document.querySelector(".map-icon").classList.remove("icon-active")
  document.querySelector(".grid-icon").classList.add("d-none")
  document.querySelector(".map-icon").classList.remove("d-none")
}
export function BZGridViewOpen(e) {
  if(document.querySelector(".gridView")){
    document.querySelector(".gridView").classList.remove("d-none")
    document.querySelector(".gridView").classList.add("d-flex")
  }
  if(document.querySelector(".mapView")){
document.querySelector(".mapView").classList.remove("d-flex")
document.querySelector(".mapView").classList.add("d-none")
  }
  activeGrid()
}
export function BZMapViewOpen(e) {
  if(document.querySelector(".gridView")){
    document.querySelector(".gridView").classList.remove("d-flex")
    document.querySelector(".gridView").classList.add("d-none")
  }
  if(document.querySelector(".mapView")){
document.querySelector(".mapView").classList.remove("d-none")
document.querySelector(".mapView").classList.add("d-flex")

  }
  activeMap()
} 


export function handleAccountO(e){
  if(document.getElementsByClassName("account-list")[0]){
    if(window.innerWidth<575){
      document.body.style.overflow="hidden"
    }
    document.getElementsByClassName("account-list")[0].style.visibility ="visible";
    document.getElementsByClassName("account-list")[0].style.opacity = "1";
  }
  document.getElementsByClassName("u-icon")[0].style.visibility = "visible";
  document.getElementsByClassName("d-icon")[0].style.visibility = "hidden";
}

export function handleAccountC(e) {
  if(window.innerWidth<575){
    document.body.style.overflow="auto"
  }
  if(document.getElementsByClassName("account-list")[0]){
    document.getElementsByClassName("account-list")[0].style.visibility ="hidden";
    document.getElementsByClassName("account-list")[0].style.opacity = "0";
  }
  if (document.getElementsByClassName("u-icon")[0]) {
    document.getElementsByClassName("u-icon")[0].style.visibility = "hidden";
    document.getElementsByClassName("d-icon")[0].style.visibility = "visible";
  }
}
export function openCart(e) {
  document.getElementsByClassName("main-cart")[0].style.display = "block";
  document.getElementById("cartBoxDiv").style.display = "block";
  document.body.style.overflow = "hidden";
}

export function openDiv(e) {
  e.target.parentElement.parentElement.childNodes[1].style.position =
    "absolute";
  e.target.parentElement.parentElement.childNodes[1].style.right = "6px";
  e.target.parentElement.parentElement.childNodes[1].style.opacity = "1";
  e.target.parentElement.parentElement.childNodes[1].style.zIndex = "4";
}

export function openSearchForm() {
  document.querySelector("#input-s").focus();
  document.querySelector("#search-result").style.display = "block";
  if (document.querySelector("#form-input")) {
    document.querySelector("#form-input").style.display = "block";
    document.querySelector("#form-input").classList.add("inputBackDark2");
  }
  document.querySelector(".clearPopupSearch").classList.remove("d-none");
}
export function handleSearchPopup() {
  if (document.querySelector(".inputBackDark2")) {
    document.querySelector("#form-input").classList.remove("inputBackDark2");
  }
  if (document.querySelector("#form-input")) {
    document.querySelector("#form-input").style.display = "none";
  }

  document.querySelector("#input-s").value = "";
  document.querySelector(".clearPopupSearch").classList.add("d-md-none");
}
export function closeSearchPage() {
  document.body.style.overflowY = "auto";
  document.querySelector("#searchPage").classList.remove("d-block");
  document.querySelector("#searchPage").classList.add("d-none");
}
export function overflow_hidden() {
  document.body.style.overflowY = "hidden";
}
export function overflow_showing() {
  document.body.style.overflowY = "auto";
}

export const handleCategoryDiv = () => {
  if (window.pageYOffset === 0) {
    window.scrollBy(0, 189);
  }
  document.querySelector("#category_nav_li_detail").style.display = "block";

  // overflow_hidden()
};
export const handleHideCategoryDiv = () => {
  document.querySelector("#category_nav_li_detail").style.display = "none";
  overflow_showing();
};

export const showStoresDropdown = (e) => {
  /*
  if (
    e.target.parentElement.classList.contains("collapsed") ||
    e.target.classList.contains("collapsed")
  ) {
    document.getElementById("head-acc1").style.display = "block";
  } else {
    document.getElementById("head-acc1").style.display = "none";
  }

  if (document.getElementById("head-acc2")) {
    document.getElementById("head-acc2").style.display = "block";
  }

  if (document.getElementById("head-acc3")) {
    document.getElementById("head-acc3").style.display = "block";
  }*/
};

export const showAddressDropdown = (e) => {
  if (
    e.target.parentElement.classList.contains("collapsed") ||
    e.target.classList.contains("collapsed")
  ) {
    if (document.getElementById("head-acc3")) {
      document.getElementById("head-acc3").style.display = "block";
    }
  } else {
    if (document.getElementById("head-acc3")) {
      document.getElementById("head-acc3").style.display = "none";
    }
  }
  document.getElementById("head-acc1").style.display = "block";
  if (document.getElementById("head-acc2")) {
    document.getElementById("head-acc2").style.display = "block";
  }
};

export const showSlotsDropdown = (e) => {
  if (
    e.target.parentElement.classList.contains("collapsed") ||
    e.target.classList.contains("collapsed")
  ) {
    if (document.getElementById("head-acc2")) {
      document.getElementById("head-acc2").style.display = "block";
    }
  } else {
    if (document.getElementById("head-acc2")) {
      document.getElementById("head-acc2").style.display = "none";
    }
  }
  document.getElementById("head-acc1").style.display = "block";
  if (document.getElementById("head-acc3")) {
    document.getElementById("head-acc3").style.display = "block";
  }
};

export const showAddressPopup = (e) => {
  // if (document.querySelector("#collapseThree")) {
  //   document.querySelector("#collapseThree").classList.remove("collapsing");
  //   document.querySelector("#collapseThree").classList.add("show");
  // }
  if(document.querySelector('.ADDRESSPOPUP')){
    document.querySelector('.ADDRESSPOPUP').style.display="flex"
  }
  if(document.querySelector('.PAYMENTPOPUP')){
    document.querySelector('.PAYMENTPOPUP').style.display="none"
  }
};


export const showPaymentPopup=()=>{
  // document.getElementById('PaymentPopup').style.display="flex"
  if(document.querySelector('.PAYMENTPOPUP')){
    document.querySelector('.PAYMENTPOPUP').style.display="flex"
  }

}
export const addressPopupClose = (e) => {
  if(document.querySelector(".ADDRESSPOPUP")){
    document.querySelector(".ADDRESSPOPUP").style.display = "none";
  }
};

export const paymentPopupClose = () => {
  if(document.querySelector(".PAYMENTPOPUP")){
    document.querySelector(".PAYMENTPOPUP").style.display = "none";
  }
};

export const clearFormData = () => {
  document.querySelectorAll(".address-popup-input").forEach((input) => {
    input.value = "";
  });

  document.querySelectorAll(".address-popup-input-s").forEach((input) => {
    input.value = "";
  });
};

export const openFilters = (e) => {
  //var filter = document.querySelectorAll(".select-filter-a");
  // filter.forEach((btn)=>{
  //   btn.classList.remove('filter-active')
  //   btn.childNodes[1].classList.remove('fa-chevron-up')
  // })
  document.querySelector("#filters-popup").classList.add("d-block");
  // add active and show
  if (e.currentTarget.classList.contains("fa-chevron-down")) {
    e.currentTarget.parentElement.classList.add("filter-active");
    e.currentTarget.classList.toggle("fa-chevron-up");
    if (e.currentTarget.classList.contains("fa-chevron-up")) {
      document.querySelector("#filters-popup").classList.add("d-block");
    } else {
      e.currentTarget.parentElement.classList.remove("filter-active");
      closeFilters();
    }
  } else if (e.currentTarget.classList.contains("select-filter-a")) {
    e.currentTarget.childNodes[1].classList.toggle("fa-chevron-up");
    if (e.currentTarget.childNodes[1].classList.contains("fa-chevron-up")) {
      e.currentTarget.classList.add("filter-active");
      document.querySelector("#filters-popup").classList.add("d-block");
    } else {
      e.currentTarget.classList.remove("filter-active");
      closeFilters();
    }
  }
};
export const closeFilters = () => {
  document.querySelector("#filters-popup").classList.remove("d-block");
};

export const handleLoginO = () => {
  if (document.querySelector(".login-popup")) {
    document.querySelector(".login-popup").style.display = "flex";
  }
  if (document.querySelector(".login")) {
    document.querySelector(".login").style.display = "block";
  }
  if (document.querySelector(".register")) {
    document.querySelector(".register").style.display = "none";
  }
  if (document.querySelector(".forgot")) {
    document.querySelector(".forgot").style.display = "none";
  }
};
export const handleRegisterO = () => {
  document.querySelector(".login-popup").style.display = "flex";
  if(document.querySelector(".register")){
  document.querySelector(".register").style.display = "block";
  }
  document.querySelector(".login").style.display = "none";
  document.querySelector(".forgot").style.display = "none";
};
export const handleForgotO = () => {
  document.querySelector(".login").style.display = "none";
  if(document.querySelector(".register")){
    document.querySelector(".register").style.display = "none"
  }
  document.querySelector(".forgot").style.display = "block";
};
export const handleForgotC = () => {
  document.querySelector(".login").style.display = "none";
  document.querySelector(".register").style.display = "block";
  document.querySelector(".forgot").style.display = "none";
};
export const handleLoginC = () => {
  if (document.querySelector(".login-popup")) {
    document.querySelector(".login-popup").style.display = "none";
  }
  if (document.querySelector(".login")) {
    document.querySelector(".login").style.display = "none";
  }
  if (document.querySelector(".register")) {
    document.querySelector(".register").style.display = "none";
  }
  if (document.querySelector(".forgot")) {
    document.querySelector(".forgot").style.display = "none";
  }
};

export const handlePopup = () => {
  window.history.back();
};
