import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import "./Info.css";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Info() {
  // const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    let scroll = window.innerWidth;
    let info_mid_div = document.querySelector("#info_mid_div");
    if (scroll > 768) {
      info_mid_div.classList.add("w-50");
    } else {
      info_mid_div.classList.remove("w-50");
    }
  });
  function handlePopup() {
    window.history.back();
  }
  return (
    <div>
      <div>
        <div
          className="item-detail-page fixed-top w-100 h-100 py-md-5 text-dark text-center d-block"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.700)",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          <div
            className="container bg-dark m-auto bg-white position-relative border p-4 "
            id="info_mid_div"
            style={{ borderRadius: "5px" }}
          >
            <Link
              to="/"
              className="position-absolute"
              style={{
                right: "10px",
                top: "5px",
                textDecoration: "none",
                color: "#3E3665",
                cursor: "pointer",
              }}
              onClick={handlePopup}
            >
              <span className="" id="popup-close">
                <i className="fas fa-times fa-2x"></i>
              </span>
            </Link>
            <div className="row flex-column pb-lg-3">
              <div className="info-logo border col-3 rounded-circle m-auto">
                {/* <img src={props._GLOBAL.uris[props._GLOBAL.business.blogo?.uri] + props._GLOBAL.business.blogo?.src}/> */}
              </div>
              <h3>Meijer</h3>
              <h6>Groceries . Bakery . Deli</h6>
            </div>

            <div>
              <div position="static" className="py-3 border-bottom">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  className="col-xl-6 col-lg-8 col-md-6 col-8 m-auto d-flex justify-content-center p-0 pl-md-2"
                >
                  <Tab
                    label="Info"
                    {...a11yProps(0)}
                    style={{ outline: "none" }}
                  />
                  <Tab
                    label="Delivery times"
                    {...a11yProps(1)}
                    style={{ outline: "none" }}
                  />

                  {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                </Tabs>
              </div>
              <TabPanel
                value={value}
                index={0}
                className="row d-flex flex-start"
              >
                <div style={{ textAlign: "left" }} className=" py-lg-4">
                  <h6 className="font-weight-bold">Pricing</h6>
                  <div className="d-flex row">
                    <div className="col-1">
                      <i className="fas fa-dollar-sign"></i>
                    </div>
                    <p className="col-10">
                      <h6 className="font-weight-bold">
                        Prices vary from in-store
                      </h6>
                      <small className="lead" style={{ fontSize: "0.9rem" }}>
                        Prices vary from in-store Item prices are higher than
                        in-store prices in your area. A markup is added to item
                        prices at this retailer to cover the cost of the Rapo
                        service . Prices are based on data collected in store
                        and are subject to delays and errors.
                      </small>
                    </p>
                  </div>
                </div>
                <div style={{ textAlign: "left" }} className="py-lg-4">
                  <h6 className="font-weight-bold" style={{ color: "grey" }}>
                    NEXT DELIVERY TIME
                  </h6>
                  <h2>Today</h2>
                  <p>
                    <span
                      className="m-auto font-weight-bold py-lg-2"
                      style={{ color: "var(--color_purple)" }}
                    >
                      All delivery times{" "}
                    </span>
                  </p>
                </div>

                <div style={{ textAlign: "left" }} className="py-lg-4">
                  <h6 className="font-weight-bold">About</h6>
                  <p className="d-flex">
                    <div className="col-1">
                      <i className="fas fa-info-circle"></i>
                    </div>
                    <small
                      className="lead col-11"
                      style={{ fontSize: "0.9rem" }}
                    >
                      The Kroger Co. is one of the nation’s top grocery
                      retailers. Our mission is to be a leader in the
                      distribution and merchandising of food, pharmacy, health
                      and personal care items, seasonal merchandise{" "}
                      <span style={{ color: "var(--color_purple)" }}>
                        ...more
                      </span>
                    </small>
                  </p>
                  <hr />
                  <small className="lead font-weight-lighter">Delivery</small>
                </div>
                <h6 className="font-weight-bold text-left">Hours</h6>
                <div className="d-flex justify-content-between py-lg-2 timing_div">
                  <small className="lead">Monday</small>
                  <small className="lead">9:00 AM - 9:00 PM</small>
                </div>
                <div className="d-flex justify-content-between py-lg-2">
                  <small className="lead">Tuesday</small>
                  <small className="lead">9:00 AM - 9:00 PM</small>
                </div>
                <div className="d-flex justify-content-between py-lg-2">
                  <small className="lead">Wednesday</small>
                  <small className="lead">9:00 AM - 9:00 PM</small>
                </div>
                <div className="d-flex justify-content-between py-lg-2">
                  <small className="lead">Thursday</small>
                  <small className="lead">9:00 AM - 9:00 PM</small>
                </div>
                <div className="d-flex justify-content-between py-lg-2">
                  <small className="lead">Friday</small>
                  <small className="lead">9:00 AM - 9:00 PM</small>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div style={{ textAlign: "left" }} className=" py-lg-4">
                  <p className="color-light">Today</p>
                  <div className="d-flex justify-content-between py-lg-2">
                    <small className="lead">Fast & Flexible</small>
                    <small className="lead">$3.99</small>
                  </div>
                </div>
                <div style={{ textAlign: "left" }} className=" py-lg-4">
                  <p className="color-light">Today</p>
                  <div className="d-flex justify-content-between py-lg-2">
                    <small className="lead">3pm - 5pm</small>
                    <small className="lead">$3.99</small>
                  </div>
                  <div className="d-flex justify-content-between py-lg-2">
                    <small className="lead">3pm - 5pm</small>
                    <small className="lead">$3.99</small>
                  </div>
                  <div className="d-flex justify-content-between py-lg-2">
                    <small className="lead">3pm - 5pm</small>
                    <small className="lead">$3.99</small>
                  </div>
                  <div className="d-flex justify-content-between py-lg-2">
                    <small className="lead">3pm - 5pm</small>
                    <small className="lead">$3.99</small>
                  </div>
                  <div className="d-flex justify-content-between py-lg-2">
                    <small className="lead">3pm - 5pm</small>
                    <small className="lead">$3.99</small>
                  </div>
                  <p className="color-light pt-lg-4">Wednesday, November 18</p>
                  <div className="d-flex justify-content-between py-lg-2">
                    <small className="lead">3pm - 5pm</small>
                    <small className="lead">$3.99</small>
                  </div>
                  <div className="d-flex justify-content-between py-lg-2">
                    <small className="lead">3pm - 5pm</small>
                    <small className="lead">$3.99</small>
                  </div>
                  <span
                    className="m-auto font-weight-bold py-lg-2"
                    style={{ color: "var(--color_purple)" }}
                  >
                    More Times{" "}
                  </span>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    _GLOBAL: state.item.GLOBAL,
  };
};
export default connect(mapStateToProps)(Info);
