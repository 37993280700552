import React, { useRef, useEffect } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./CP2.css";
import { callApi } from "../../actions/index";
import DNav from "../Navs/nav";
import DSubnav from "../Navs/subnav";
import CPcard from "../CPcard/CPcard";
import LoadingBar from "react-top-loading-bar";

function CP2(props) {
  const ref = useRef(null);
  const barColor = "#f11946";
  // console.log("######## In CP2 rendering....");
  useEffect(() => {
    ref.current?.continuousStart();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (props._CP2 && props._CP2.display_template !== undefined) {
    //console.log(props);
    const categorys = props._CP2.category.map((cat, index) => {
      return (
        <Link
          to={
            props._CS.col_status === 1
              ? `/store/${props._CP2.business.buname}/category/${cat.catpath}`
              : `/category/${cat.catpath}`
          }
          onClick={(i) => {
            props.callApi({
              operation: "/products_by_category",
              caller_ref: "CP2.render1",
              params: {
                bid: props._CP2.business.bid,
                sid: props._CP2.business.sid,
                catid: cat.cid,
                address: props._LOC.address,
                location: props._LOC.location,
                rad: props._LOC.rad,
              },
            });
          }}
          className="popular-sub px-2 d-flex align-items-center flex-wrap position-relative"
        >
          <span>
            <p style={{ color: "var(--color_purple)" }}>{cat.cname} </p>
          </span>
          <span
            className="popular-sub-icon"
            style={{ marginBottom: "0.6rem", marginLeft: "0.5rem" }}
          >
            <i
              className="fas fa-angle-right "
              style={{ color: "var(--color_purple)" }}
            ></i>
          </span>
        </Link>
      );
    });
    const linkpaths = props._CP2.path.slice(0, props._CP2.path.length - 1);

    const linkpath = linkpaths.map((path) => {
      return (
        <span style={{ textDecoration: "none" }}>
          &nbsp;
          <Link
            style={{ color: "var(--color_purple)" }}
            to={
              props._CS.col_status === 1
                ? `/store/${props._CP2.business.buname}/category/${path.catpath}`
                : `/category/${path.catpath}`
            }
            onClick={() => {
              props.callApi({
                operation: "/products_by_category",
                caller_ref: "CP2.render2",
                params: {
                  bid: props._CP2.business.bid,
                  sid: props._CP2.business.sid,
                  catpath: path.catpath,
                  address: props._LOC.address,
                  location: props._LOC.location,
                  rad: props._LOC.rad,
                },
              });
            }}
          >
            {path.ename}
          </Link>
          &nbsp; /
        </span>
      );
    });

    // const response = props._CP2.product.map((subcat, index1) => {
    const response = props._CP2.product.map((subcat, index1) => {
      //console.log(props._CP2.product, 'pppppppppp')
      //console.log(props._CP2.category, 'pppppppppp')
      return (
        <CPcard
          subcat={subcat}
          cat={props._CP2.cid}
          link={
            props._CS.col_status === 1
              ? `/store/${props._CP2.business.buname}/item/${subcat.pid}`
              : `/item/${subcat.pid}`
          }
          img={props._CP2.uris[subcat.pthumb?.uri] + subcat.pthumb?.src}
          bid={props._CP2.business.bid}
          sid={props._CP2.business.sid}
          buname={props._CP2.business.buname}
        />
      );
    });

    return (
      <div>
        <DNav />
        <DSubnav />
        <div className="category-list container-fluid px-md-5 px-3 pt-md-4 pt-5 p-0">
          <div className="upper-link pt-2">
            <Link
              to={
                props._CS?.col_status === 1
                  ? `/store/${props._CP2.business.buname}/shop`
                  : `/shop`
              }
              style={{ textDecoration: "none", color: "var(--color_purple)" }}
            >
              <i
                className="fas fa-store-alt"
                style={{ color: "var(--color_purple)" }}
                onClick={(i) => {
                  props.callApi({
                    operation: "/products_by_category",
                    caller_ref: "CP2.render3",
                    params: {
                      bid: props._CP2.business.bid,
                      sid: props._CP2.business.sid,
                      catid: "root",
                      address: props._LOC.address,
                      location: props._LOC.location,
                      rad: props._LOC.rad,
                    },
                  });
                }}
              ></i>
            </Link>{" "}
            &nbsp;/
            {linkpath} &nbsp;
            <span style={{ textDecoration: "none", fontWeight: "bolder" }}>
              {props._CP2.cname}
            </span>
            {/* <h4 className="text-left mt-3">
              {props._CP2.cname}
            </h4> */}
          </div>

          <h5 className="text-center mt-2" style={{ lineHeight: "1.5em" }}>
            <small style={{ color: "grey" }}>Popular Categories</small> from{" "}
            <br className="d-sm-none d-block" /> {props._CP2.cname}
          </h5>
          <div className="popular-cate d-flex text-center flex-wrap justify-content-center ">
            {categorys}
          </div>

          <div className="popular-btns d-lg-flex d-none p-1 justify-content-between">
            <div className="">
              <button>
                Brands &nbsp;<i className="fas fa-chevron-down"></i>
              </button>
              <button style={{ marginLeft: "20px" }}>
                Nutrition &nbsp;<i className="fas fa-chevron-down"></i>
              </button>
            </div>
            <div>
              <button>
                Sort by
                <span className="green">
                  Popularity &nbsp;<i className="fas fa-chevron-down"></i>
                </span>
              </button>
            </div>
          </div>
          <div
            className="row d-flex flex-wrap mx-1 pb-2 justify-content-start "
            style={{ background: "rgb(224, 224, 224)" }}
          >
            {response}
          </div>
        </div>
      </div>
    );
  } else {
    //return <Loader />;
    return <LoadingBar color={barColor} ref={ref} />
    //return null;
  }
}

const mapStateToProps = (state) => {
  return {
    _CP2: state.item.CP2,
    _CART: state.item.CART,
    _LOC: state.item.LOC,
    _CS: state.item.CS,
  };
};
export default connect(mapStateToProps, {
  callApi,
})(CP2);
