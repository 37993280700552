import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { callApi } from "../../../actions/index";
import { overflow_hidden, handleLoginO } from "../../event_functions";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Categories(props) {
  useEffect(() => {
    // window.scrollTo(0, `${props._SCROLL}`)
    var btnAdd = document.querySelectorAll(".count1");

    const handleClick = (e) => {
      e.preventDefault();
      btnAdd.forEach((btn) => {
        console.log(
          btn.parentElement.parentElement.nextElementSibling,
          "hhhhhh"
        );
        btn.parentElement.parentElement.nextElementSibling.classList.remove(
          "toggle-class"
        );
      });
      e.currentTarget.parentElement.parentElement.nextElementSibling.classList.add(
        "toggle-class"
      );
    };
    btnAdd.forEach((btn) => {
      btn.addEventListener("click", handleClick);
    });
  });

  document.addEventListener(
    "click",
    (evt) => {
      let targetEl = evt.target; // clicked element

      if (targetEl.classList.contains("card-add-qunt")) {
        alert("ok");
      } else {
        var btnAdd = document.querySelectorAll(".card-add-qunt");
        btnAdd.forEach((btn) => {
          btn.classList.remove("toggle-class");
        });
      }
    },
    { passive: true }
  );

  function getQtyFromCart(pid, sid) {
    // console.log('getQtyFromCart.............')
    let mitem = null;
    let store = props._CART.scarts.find((item) => {
      return sid === item.sid;
    });
    if (store) {
      mitem = store.product.find((item) => {
        return pid === item.pid;
      });
    }
    if (mitem && mitem && mitem.qty) {
      return mitem.qty;
    } else {
      return 0;
    }
  }

  function getQtyFromCart2(pid, sid) {
    // console.log('getQtyFromCart.............')
    let mitem = null;
    let store = props._CART.scarts.find((item) => {
      return sid === item.sid;
    });
    if (store) {
      mitem = store.product.find((item) => {
        return pid === item.pid;
      });
    }
    if (mitem && mitem.qty) {
      return <span className="count1">{mitem.qty}</span>;
    } else {
      return <i className="fa fa-plus count0"></i>;
    }
  }

  const getPDData = (_buname, _cid, _pid) => {
    let _s_pid = "";
    if (props._PD && props._PD.product) {
      _s_pid = props._PD.product.filter((item) => {
        //console.log(item.id, _pid);
        return item.id === _pid;
      });
      //console.log(_s_pid.length, _pid);
    }

    //some times we are having old PD data, so above check make sure we have clicked PD data
    // if PD data doesn't exist, then we load PD for that pid
    if (_s_pid.length === 0) {
      //console.log("is it calling api?");
      props.callApi({
        operation: "/products_details",
        caller_ref: "CAROUSEL.getPDData",
        params: {
          bid: props.bid,
          sid: props.sid,
          pid: _pid,
          catid: _cid,
        },
      });
    }
  };
  const { isAuthenticated } = props._auth;
  return (
    <div className="mainCategories px-lg-4 px-md-3 px-0 py-2 rounded ">
      <div>
        <h5 style={{ color: "#757575" }} className=" category_name">
          <div className="w-100 mt-lg-1">
            <h5 id={`category${props.item.cname.replace(/\s/g, "")}`}>
              <Link
                style={{ color: "var(--color_purple)" }}
                to={
                  props._CS?.col_status === 1
                    ? `/store/${props.buname}/category/${props.item.catpath}`
                    : `/category/${props.item.catpath}`
                }
                onClick={(i) => {
                  //console.log('calling api products_by_category $$$$$$$$$$$$$$$$$$',props.bid,props.sid,props.item.cid)
                  //console.log('col_status', props._CS);
                  props.callApi({
                    operation: "/products_by_category",
                    caller_ref: "CAROUSEL.render1",
                    params: {
                      bid: props.bid,
                      sid: props.sid,
                      catid: props.item.cid,
                    },
                  });
                  //console.log("callApi /products_by_category");
                }}
              >
                {props.item.cname}
              </Link>
            </h5>
          </div>
        </h5>
        <div className="w-100 d-flex flex-wrap p-0">
          {props.item.product.map((sitem, index) => (
            <div
              className="col-lg-4 col-sm-6 col-12 pe-md-2 position-relative"
              style={{ overflow: "hidden" }}
              onClick={(e, i) => {
                props.callApi({
                  operation: "/products_details",
                  caller_ref: "CAROUSEL.render2",
                  params: {
                    bid: props.bid,
                    sid: props.sid,
                    pid: `${sitem.pid}`,
                    catid: props.item.cid,
                  },
                });
                e.stopPropagation();
                //console.log("callApi /product_details");
              }}
            >
              <div
                className=" d-flex flex-column p-1 pb-1 px-2 rounded SingleProduct bg-white position-relative mt-2"
                style={{
                  marginTop: "-20px",
                  boxShadow: "0px  0px 3px 1px rgb(240, 240, 240)",
                }}
              >
                <div
                  className="item_name w-100"
                  style={{ color: "var(--color_purple)" }}
                >
                  <h6>
                    <Link
                      to={
                        props._CS?.col_status === 1
                          ? `/store/${props.buname}/${props.sid}/item/${sitem.pid}`
                          : `/${props.sid}/item/${sitem.pid}`
                      }
                      // to={`/store/${props.buname}/item/${sitem.pid}`}

                      onClick={(e, k) => {
                        // console.log(
                        //     "coming here????",
                        //     props.item.cuname,
                        //     props.item.cid,
                        //     sitem.pid
                        //   );

                        getPDData(props.item.cuname, props.item.cid, sitem.pid);
                        e.stopPropagation();
                      }}
                      style={{ color: "var(--color_purple)" }}
                    >
                      {sitem.pname}
                    </Link>
                  </h6>
                </div>
                <div className="card-top-div ms-auto" id="addPlus">
                  <i
                    className="addPlus"
                    onClick={(f, j) => {
                      if (isAuthenticated) {
                        f.stopPropagation();
                        if (!Number(f.target.innerText)) {
                          // props.addItem2Cart(
                          //   props.sid,
                          //   sitem
                          // );
                          props.callApi({
                            operation: "/save_item_to_cart",
                            caller_ref: "CAROUSEL.render2",
                            params: {
                              bid: props.bid,
                              sid: props.sid,
                              pid: `${sitem.pid}`,
                              qty: getQtyFromCart(sitem.pid, props.sid) + 1,
                            },
                          });
                        }
                      } else {
                        handleLoginO();
                      }
                    }}
                  >
                    {getQtyFromCart2(sitem.pid, props.sid, 1)}
                  </i>
                </div>
                <div
                  id="card-add-qunt"
                  className="d-flex justify-content-between align-items-center px-3 card-add-qunt m-auto "
                >
                  {getQtyFromCart(sitem.pid, props.sid) <= 1 ? (
                    <span className="focus">
                      <i
                        className="fa fa-trash item_delete_icon"
                        onClick={(i) => {
                          if (isAuthenticated) {
                            i.stopPropagation();
                            // props.deleteItem4Cart(
                            //   props.sid,
                            //   sitem
                            // );
                            props.callApi({
                              operation: "/save_item_to_cart",
                              caller_ref: "CAROUSEL.render3",
                              params: {
                                bid: props.bid,
                                sid: props.sid,
                                pid: `${sitem.pid}`,
                                qty: getQtyFromCart(sitem.pid, props.sid) - 1,
                              },
                            });
                          }
                        }}
                      ></i>
                    </span>
                  ) : (
                    <span
                      style={{ fontSize: "30px", marginTop: "-0.9em" }}
                      className="focus faMinus"
                      onClick={(i) => {
                        if (isAuthenticated) {
                          i.stopPropagation();
                          // props.removeItem4Cart(
                          //   props.sid,
                          //   sitem
                          // );
                          props.callApi({
                            operation: "/save_item_to_cart",
                            caller_ref: "CAROUSEL.render4",
                            params: {
                              bid: props.bid,
                              sid: props.sid,
                              pid: `${sitem.pid}`,
                              qty: getQtyFromCart(sitem.pid, props.sid) - 1,
                            },
                          });
                        } else {
                          handleLoginO();
                        }
                      }}
                    >
                      _
                    </span>
                  )}

                  <span className="focus" style={{ fontSize: "20px" }}>
                    {getQtyFromCart(sitem.pid, props.sid)}
                  </span>
                  <span
                    className="focus"
                    style={{ fontSize: "30px", cursor: "pointer" }}
                    onClick={(i) => {
                      if (isAuthenticated) {
                        i.stopPropagation();
                        //props.addItem2Cart(props.sid, sitem);
                        props.callApi({
                          operation: "/save_item_to_cart",
                          caller_ref: "CAROUSEL.render1",
                          params: {
                            bid: props.bid,
                            sid: props.sid,
                            pid: `${sitem.pid}`,
                            qty: getQtyFromCart(sitem.pid, props.sid) + 1,
                          },
                        });
                      } else {
                        handleLoginO();
                      }
                    }}
                  >
                    +
                  </span>
                </div>

                <div className="w-100 d-flex ">
                  <div className="pe-1" style={{ width: "60%" }}>
                    <div>
                      <p style={{ fontSize: "0.9rem" }}>
                        Josh Celler Cabernet Sauvignon, 750mL red wine...
                      </p>
                    </div>

                    <h6 className="main_price card-title py-1 px-md-2 px-1 d-flex justify-content-between rounded mt-4">
                      ${sitem.lp}
                      <small className="off_price font-weight-lighter">
                        10% off
                      </small>
                    </h6>
                  </div>
                  <div
                    style={{ width: "40%" }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <Link
                      to={
                        props._CS?.col_status === 1
                          ? `/store/${props.buname}/item/${sitem.pid}`
                          : `/item/${sitem.pid}`
                      }
                      style={{ textDecoration: "none" }}
                      onClick={(i) => overflow_hidden()}
                    >
                      <LazyLoadImage
                        src={props.uris[sitem.pthumb?.uri] + sitem.pthumb?.src}
                        className="img-fluid"
                        style={{ height: "100px" }}
                        loading="lazy"
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  //console.log("CP0 state change")
  return {
    _auth: state.auth,
    _CP0: state.item.CP0,
    _CART: state.item.CART,
    _CS: state.item.CS,
  };
};
export default connect(mapStateToProps, {
  callApi,
})(Categories);
