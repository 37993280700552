import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { callApi } from "../../../actions/index";
import { ChevronRight } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "../Account.css";
function OD(props) {
  useEffect(() => {
    let _oid = window.location.href.split("/")[5];
    props.callApi({
      operation: "/order_details",
      caller_ref: "OD.useEffect",
      params: {
        oid: _oid,
      },
    });
  }, []); // eslint-disable-line

  let missingImg =
    "https://firebasestorage.googleapis.com/v0/b/rapo-prod.appspot.com/o/pimages%2Fmissing-item-image?alt=media&token=26ad9768-4511-4d20-bd35-c499f2717cdd";

  return (
    <div className="col-lg-10 ms-lg-3 col-12">
      <div className="d-flex align-items-center mt-3 px-2">
        {/* <Link to="/">
                        <small>Home</small>
                    </Link>
                    <ChevronRight className='UIChevronRight' /> */}
        <Link to="/account">
          <small>My account</small>
        </Link>
        <ChevronRight className="UIChevronRight" />
        <Link to="/account/orders">
          <small>Your orders</small>
        </Link>
        <ChevronRight className="UIChevronRight" />
        <small>Order Details</small>
      </div>
      <div className="row py-2 pl-2 m-0 px-2">
        <h5>Order details</h5>
      </div>
      <div
        className="row bg-white px-4 py-2 mb-3 rounded m-auto mx-2"
        style={{ boxShadow: "1px 1px 7px 2px rgb(230, 228, 228)" }}
      >
        <div className="row w-100">
          <div className="col-md-3 py-md-0 py-2 d-flex flex-column justify-content-center">
            <span className="p-0">
              <small>Order #{props?._OD?._id}</small>
            </span>
            <span className="p-0">
              <small>{props?._OD?.order_date}</small>
            </span>
          </div>
          <div className="col-lg-4 col-md-5"></div>
          <div className="col-lg-5 col-md-4 d-flex justify-content-between mt-md-0 mt-2">
            <div className="col-3 text-center">
              <h6 className="h6">Items</h6>
              <span className="p-0">
                <small>{props?._OD?.qty}</small>
              </span>
            </div>
            <div className="col-3 text-center">
              <h6 className="h6">Total</h6>
              <span className="p-0">
                <small>${props?._OD?.amount}</small>
              </span>
            </div>
            <div className="col-5 text-center p-0">
              <h6 className="h6">Order Status</h6>
              <span className="p-0">
                <small>Our Picked Up</small>
              </span>
            </div>
          </div>
        </div>
        {props._OD?.sorders?.map((order) => {
          return (
            <div className="col-12 ">
              <div
                className="col-12 position-relative"
                style={{ paddingBottom: "1rem" }}
              >
                <div className="col-xl-4 col-lg-4 col-md-6 d-flex MyAccountHr text-center justify-content-center align-items-center m-auto">
                  {/* <img src={Pickup} className="img-fluid MyAccountHrIcon" alt="" /> &nbsp;  */}
                  {order.fulfilment?.type === "pickup" ? (
                    <i className="fas fa-truck-pickup mr-1 mr-sm-2"></i>
                  ) : order.fulfilment?.type === "delivery" ? (
                    <i className="fas fa-truck-loading mr-1 mr-sm-2"></i>
                  ) : order.fulfilment?.type === "ship" ? (
                    <i className="fas fa-dolly-flatbed mr-1 mr-sm-2"></i>
                  ) : order.fulfilment?.type === "in-Store" ? (
                    <i className="fas fa-store-alt mr-1 mr-sm-2"></i>
                  ) : null}
                  <small style={{ fontWeight: 200, fontSize: "0.7rem" }}>
                    {order.fulfilment?.time_slot
                      ? order.fulfilment?.time_slot.sdate +
                        " " +
                        order.fulfilment?.time_slot.stime +
                        " - " +
                        order.fulfilment?.time_slot.etime
                      : null}
                  </small>
                  &nbsp;
                  <small style={{ fontWeight: 200, fontSize: "0.7rem" }}>
                    {order.s_address.street}{" "}
                    {order.s_address.state ? "," + order.s_address.state : null}
                  </small>
                </div>
                <div className="col-12 border-bottom "></div>
              </div>
              {/* row 1 */}
              <div className="row w-100">
                <div className="col-md-3 mt-md-0 py-md-0 py-1 d-flex">
                  <LazyLoadImage
                    loading="lazy"
                    src={order?.uris[order?.blogo?.uri] + order?.blogo?.src}
                    className="img-fluid MyAccountStoreImg"
                    alt=""
                  />{" "}
                  &nbsp;
                  <div className="mt-md-0 mt-2" style={{ lineHeight: "1rem" }}>
                    <h6
                      className="h6"
                      style={{ lineHeight: "1rem", margin: "0px" }}
                    >
                      {order.bname}
                    </h6>
                    <span className="p-0" style={{ lineHeight: "0.5em" }}>
                      <small>
                        {order.s_address.street}{" "}
                        {order.s_address.state
                          ? "," + order.s_address.state
                          : null}
                      </small>
                    </span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-5 d-flex "></div>
                <div className="col-lg-5 col-md-4 d-flex justify-content-between align-items-center">
                  <div className="col-3 text-center">
                    <span className="p-0">
                      <small>{order.qty}</small>
                    </span>
                  </div>
                  <div className="col-3 text-center">
                    <span className="p-0">
                      <small>${order.amount.toFixed(2)}</small>
                    </span>
                  </div>
                  <div className="col-5 text-center p-0">
                    <span className="p-0">
                      <small>
                        <CheckIcon fontSize="small" /> Picked
                      </small>
                    </span>
                  </div>
                </div>
              </div>
              {/* new */}
              {/*<div className="row w-100 pt-md-3 py-md-0 py-2">
                             <h6 className='h6 ms-md-5 ms-2'>
                                Fruit &amp; Vegetables
                            </h6> 
                        </div>*/}
              <div className="orders_div w-100">
                {order?.product.map((sorder) => (
                  <div className="row w-100 py-1 justify-content-between">
                    <div className="col-md-7 d-flex pl-md-5">
                      <div className="col-1 d-flex align-items-center justify-content-start  p-0">
                        <input type="checkbox" />
                      </div>
                      {/* <img src={Store} className='img-fluid MyAccountItemImgSm'  alt="" />&nbsp;&nbsp;&nbsp; */}
                      <LazyLoadImage
                        loading="lazy"
                        src={
                          sorder?.pthumb
                            ? sorder?.uris[sorder?.pthumb?.uri] +
                              sorder?.pthumb?.src
                            : missingImg
                        }
                        className="img-fluid MyAccountItemImgSm"
                        alt=""
                      />
                      &nbsp;&nbsp;
                      <div className="d-flex flex-column ms-n2 justify-content-center">
                        <span className="p-0" style={{ lineHeight: "1rem" }}>
                          <small>{sorder.pname}</small>
                        </span>

                        {/* <span className='p-0' >
                                                    <small style={{ fontWeight: 400 }}>Allow subs</small>
                                                </span> */}
                      </div>
                    </div>

                    <div className="col-lg-5 col-md-4 py-md-0 py-0 d-flex justify-content-between align-items-center">
                      <div className="col-3 text-center">
                        <span className="p-0">
                          <small>{sorder.qty}</small>
                        </span>
                      </div>
                      <div className="col-3 text-center">
                        <span className="p-0">
                          <small>${sorder.amount.toFixed(2)}</small>
                        </span>
                      </div>
                      <div className="col-5 text-center p-0">
                        <span className="p-0">
                          <small>
                            <CheckIcon fontSize="small" /> Picked
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <hr />
            </div>
          );
        })}
        {/*  */}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  //console.log(state);
  return {
    //auth: state.auth,
    _LA: state.item.LA,
    _OD: state.item.OD,
  };
};

export default connect(mapStateToProps, {
  callApi,
})(OD);
