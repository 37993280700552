import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { callApi, setBZPopupDT } from "../../actions/index";
import { logoutUser } from "../../actions/authentication";
import PropTypes from "prop-types";
import "./nav.css";
import logo1 from "../assets/rapo-market2.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SstoreOpen, BZGridMapViewOpen } from "../event_functions";
import AccountCart from "../AccountCart";
import SS from "../SS/SS";
function Nav(props) {
  //   const onLogout=(e) =>{
  //     e.preventDefault();
  //    props.logoutUser(props.history);
  // }

  // const { isAuthenticated, user } = props._auth;
  //console.log("???????? nav ???????")

  let _CST;
  if (props._GLOBAL?.template_type === "cp0") {
    _CST = props._CP0;
  } else if (props._GLOBAL?.template_type === "cp4") {
    _CST = props._CP4;
  }

  if (
    (_CST === undefined && props._CART.scarts === undefined) ||
    (_CST?.display_template === undefined && props._CART.scarts === undefined)
  ) {
    return null; //<div>????</div>;
  } else {
    return (
      <div>
        {/* <SearchPage/> */}
        {/* <User/> */}
        <nav
          className="navbar d-flex justify-content-between py-2 px-lg-3 px-md-3 fixed-top shopnavbar"
          id="navbar"
        >
          <div className="d-flex" id="logo">
            <i
              className="fas fa-bars mt-md-2 mt-0 ms-1"
              style={{ color: "white", fontSize: "1.2em" }}
            ></i>
            {props._CS?.col_status === 1 ? (
              <Link to="/">
                <LazyLoadImage
                  className="img-fluid logo1"
                  src={logo1}
                  loading="lazy"
                  alt=""
                />
              </Link>
            ) : (
              <LazyLoadImage
                src={
                  props._GLOBAL.uris[props._GLOBAL.business?.blogo?.uri] +
                  props._GLOBAL.business?.blogo?.src
                }
                className="animate_logo2 mx-2 rounded-circle"
                loading="lazy"
                // style={{borderRadius:"50%"}}
                alt=""
              />
            )}
          </div>
          {(props._CS?.col_status === 0 && props._CS?.stores.length > 1) ||
          props._CS?.col_status === 1 ? (
            <Link
              style={{ color: "white" }}
              className=" me-auto d-md-flex d-none"
              onClick={(e) => {
                props.setBZPopupDT("pick-store");
                SstoreOpen();
              }}
            >
              <span className="storeNearReplace">
                {props._LOC?.address
                  ? "Stores @ " +
                    props._LOC?.address +
                    " (" +
                    props._LOC?.rad +
                    " miles) "
                  : "Stores Near Me"}
              </span>
              <i className="fas fa-chevron-down mt-2"></i>
            </Link>
          ) : null}
          <div className="col-4 d-lg-block d-none"></div>
          {/* ----------------account-cart---------------------- */}
          <AccountCart />
        </nav>
        {/* 2 */}
        <nav
          className="navbar navbar_2 py-2 px-md-3 fixed-top shopnavbar d-none"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.363)),url(${
              props._GLOBAL.uris[props._GLOBAL?.business?.bbanner?.uri] +
              props._GLOBAL?.business?.bbanner?.src
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          id="navbar_2"
        >
          <div className="d-flex col-6 col-md-3 ps-2 lh-1">
            <LazyLoadImage
              src={
                props._GLOBAL.uris[props._GLOBAL.business.blogo?.uri] +
                props._GLOBAL.business.blogo?.src
              }
              className="animate_logo2 rounded-circle"
              loading="lazy"
              alt=""
            />{" "}
            &nbsp;
            <div className="specss position-relative white d-flex justify-content-center">
              <Link
                style={{ color: "white", textDecoration: "none" }}
                onClick={(e) => {
                  props.setBZPopupDT("pick-store");
                  SstoreOpen();
                }}
              >
                <h5 style={{ fontWeight: 800 }} className="navStoreName">
                  {props._GLOBAL.business.bname} &nbsp;
                  <i className="fas fa-chevron-down "></i>
                </h5>
              </Link>
              <small className="delivery-text">
                {props._GLOBAL.business.s_address &&
                props._GLOBAL.business.s_address?.city &&
                props._GLOBAL.business.s_address?.state
                  ? props._GLOBAL.business.s_address?.city +
                    "," +
                    props._GLOBAL.business.s_address?.state
                  : props._GLOBAL.business.s_address &&
                    props._GLOBAL.business.s_address?.state
                  ? props._GLOBAL.business.s_address?.state
                  : props._GLOBAL.business.s_address?.street}
              </small>
            </div>
          </div>
          {/* <div className="form-input col-12 col-sm-9 col-lg-6 m-auto d-lg-block" id="form-input">
            <SS/>
            </div> */}
          {/* ----------------right-ul---------------------- */}
          <AccountCart />
        </nav>
        {/* 2 */}
        {/* header */}

        <div
          className="header text-center p-2 py-3"
          style={{
            height: "260px",
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.363)),url(${
              props._GLOBAL.uris[props._GLOBAL.business.bbanner?.uri] +
              props._GLOBAL.business.bbanner?.src
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            className={
              props._CS?.col_status === 1
                ? "mt-xl-0 mt-md-2 pt-4 mt-5 d-flex flex-column align-items-center"
                : "d-none"
            }
          >
            <LazyLoadImage
              src={
                props._GLOBAL.uris[props._GLOBAL.business.blogo?.uri] +
                props._GLOBAL.business.blogo?.src
              }
              className="specs img-fluid animate_log rounded-circle"
              loading="lazy"
              alt=""
            />
            <span>
              <span
                className="store-name1 white fs-3"
                onClick={(e) => {
                  props.setBZPopupDT("pick-store");
                  BZGridMapViewOpen();
                }}
              >
                {/* <p  onClick={(e)=>{props.setBZPopupDT("pick-store");SstoreOpen()}}> */}
                {props._GLOBAL.business.bname}&nbsp;
                {/* <i className="fas fa-chevron-down store-dn-1 mt-3 mt-sm-1"></i> */}
              </span>
            </span>
            <p className="white store-name_detail">
              {
                props._GLOBAL.business.s_address &&
                props._GLOBAL.business.s_address?.city &&
                props._GLOBAL.business.s_address?.state
                  ? props._GLOBAL.business.s_address?.city +
                    "," +
                    props._GLOBAL.business.s_address?.state
                  : props._GLOBAL.business.s_address &&
                    props._GLOBAL.business.s_address?.state
                  ? props._GLOBAL.business.s_address?.state
                  : props._GLOBAL.business.s_address?.street

                // props._GLOBAL.business.s_address &&
                // props._GLOBAL.business.s_address.state
                //   ? props._GLOBAL.business.s_address.state
                //   : props._GLOBAL.business.s_address &&
                //     props._GLOBAL.business.s_address.state
                //   ? props._GLOBAL.business.s_address.city
                //   : props._GLOBAL.business.s_address &&
                //     props._GLOBAL.business.s_address.street
              }{" "}
              |{" "}
              <Link
                to="/store/Info/Id"
                style={{ textDecoration: "none", color: "white" }}
              >
                Store Info
              </Link>
            </p>
          </div>
          <div
            className="form-input col-12 col-sm-9 col-lg-6 m-auto md-mt-n5 d-lg-block m-auto"
            id="form-input"
          >
            <SS />
          </div>
        </div>
      </div>
    );
  }
}

Nav.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    _CP0: state.item.CP0,
    _CP4: state.item.CP4,
    _CART: state.item.CART,
    _GLOBAL: state.item.GLOBAL,
    _SG: state.item.SG,
    _auth: state.auth,
    _LOC: state.item.LOC,
    _CS: state.item.CS,
  };
};
export default connect(mapStateToProps, { callApi, logoutUser, setBZPopupDT })(
  Nav
);
