import React, { useEffect } from "react";
import Scrollspy from "react-scrollspy";

function CategoreisNav(props) {
  useEffect(() => {
    document.addEventListener(
      "scroll",
      function () {
        const animate_div = document.querySelector(".animate-nav");

        const forms = document.querySelector("#form-input");
        const navbar = document.getElementById("navbar");
        const btns = document.querySelectorAll(".white");
        const animate_logo2 = document.querySelector(".animate_logo2");
        const navbar_2 = document.querySelector(".navbar_2");
        const val = window.pageYOffset;
        if (val >= 230) {
          if (document.querySelector(".maincategoriesNav")) {
            document.querySelector(".maincategoriesNav").style.position =
              "fixed";
            document
              .querySelector(".maincategoriesNav")
              .classList.add("animate-navs");
            document.querySelector(".mainCategories").style.marginTop = "10vh";
          }
          if (animate_div) {
            animate_div.style.position = "fixed";
            animate_div.style.top = "72px";
            animate_div.classList.add("animate-navs");
          }
          if (forms) {
            forms.classList.add("fixed-top");
            forms.style.paddingTop = "1vh";
          }
          if (navbar_2) {
            navbar_2.classList.remove("d-none");
          }
          if (navbar) {
            navbar.style.display = "none";
          }

          btns.forEach((btn) => {
            btn.style.color = "var(--color_white)";
          });
        }
        if (val < 230) {
          if (document.querySelector(".maincategoriesNav")) {
            document.querySelector(".maincategoriesNav").style.position =
              "relative";
            document.querySelector(".maincategoriesNav").style.top = "0vh";
            document
              .querySelector(".maincategoriesNav")
              .classList.remove("animate-navs");
            document.querySelector(".mainCategories").style.marginTop = "0vh";
          }
          if (navbar_2) {
            navbar_2.classList.add("d-none");
          }
          // if(navbar_2) {
          //   navbar_2.style.display = "none";
          // }
          if (animate_div) {
            animate_div.style.position = "relative";
            animate_div.style.top = "0px";
            animate_div.classList.remove("animate-navs");
          }
          // animate_d_div.classList.remove('animate-d-navs')
          if (forms) {
            forms.classList.remove("fixed-top");
          }
          if (animate_logo2) {
            animate_logo2.style.border = "none";
          }
          if (navbar) {
            navbar.style.display = "flex";
            navbar.style.background = "transparent";
          }

          btns.forEach((btn) => {
            btn.style.color = "white";
          });
        }
      },
      { passive: true }
    );

    // document.querySelectorAll('.catNavAnchor').forEach(div=>{
    //     div.addEventListener('click',function(e){
    //         document.querySelectorAll('.catNavAnchor').forEach((b)=>{
    //             b.classList.remove('selectedCatAnchor')
    //         })
    //            this.classList.add('selectedCatAnchor')
    //     })
    // })
  });

  return (
    <div className="maincategoriesNav">
      <div>
        <Scrollspy
          className="categoriesNav px-lg-5 px-md-3 px-1"
          items={props.CP4_category.map(
            (item) => `category${item.cname.replace(/\s/g, "")}`
          )}
          currentClassName="selectedCatAnchor"
          scrolledPastClassName={"scrollspyRootEl"}
        >
          {props.CP4_category.map((item, o) => (
            <li>
              <a
                href={`#${`category${item.cname.replace(/\s/g, "")}`}`}
                className="catNavAnchor"
              >
                {item.cname}
              </a>
            </li>
          ))}
        </Scrollspy>
      </div>
    </div>
  );
}
export default CategoreisNav;
