import React from 'react'
import DNav from "../Navs/nav";
import DSubnav from "../Navs/subnav";
import './Account.css'
import AccountSideBar from './AccountSideBar'
function Account() {
    return (
        <div>
        <DNav />
        <DSubnav />
        <AccountSideBar className="col-xl-2"/>
      
        </div>
    )
}

export default Account
