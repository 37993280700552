import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FilterPopup from "../Filters/FilterPopup";
import { callApi } from "../../actions/index";
import "./CP3.css";
import DNav from "../Navs/nav";
import DSubnav from "../Navs/subnav";
import CPcard from "../CPcard/CPcard";
//import FilterBar from "../Filters/FilterBar";
import FilterSelect from "../Filters/FilterSelect";
import LoadingBar from "react-top-loading-bar";

function CP3(props) {
  const ref = useRef(null);
  const barColor = "#f11946";
  //  console.log('######## In CP3 rendering....')
  useEffect(() => {
    ref.current?.continuousStart();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
  if (props._CP3 === undefined || props._CP3.display_template === undefined) {
    //return null;
    //    return <Loader />;
    return <LoadingBar color={barColor} ref={ref} />
  } else {
    const linkpaths = props._CP3.path.slice(0, props._CP3.path.length - 1);
    //console.log(linkpaths, "popppp()");
    //console.log("valued passed from router:", props.test);
    const linkpath = linkpaths.map((path) => {
      return (
        <span style={{ textDecoration: "none" }}>
          &nbsp;
          <Link
            style={{ color: "var(--color_purple)" }}
            to={
              props._CS.col_status === 1
                ? `/store/${props._CP3.business.buname}/category/${path.catpath}`
                : `/category/${path.catpath}`
            }
            onClick={() => {
              props.callApi({
                operation: "/products_by_category",
                caller_ref: "CP3.render1",
                params: {
                  bid: props._CP3.business.bid,
                  sid: props._CP3.business.sid,
                  catpath: path.catpath,
                  address: props._LOC.address,
                  location: props._LOC.location,
                  rad: props._LOC.rad,
                },
              });
            }}
          >
            {path.ename}
          </Link>
          &nbsp;/
        </span>
      );
    });
    const response = props._CP3.product.map((subcat, index1) => {
      return (
        <CPcard
          subcat={subcat}
          cat={props._CP3.cid}
          link={
            props._CS.col_status === 1
              ? `/store/${props._CP3.business.buname}/item/${subcat.pid}`
              : `/item/${subcat.pid}`
          }
          img={props._CP3.uris[subcat.pthumb?.uri] + subcat.pthumb?.src}
          bid={props._CP3.business.bid}
          sid={props._CP3.business.sid}
          buname={props._CP3.business.buname}
        />
      );
    });

    return (
      <div>
        <DNav />
        <DSubnav />
        <div className="sub-category container-fluid px-md-3 px-0 pt-md-3 pt-5">
          <div
            className="row d-flex mx-1 justify-content-start mt-1"
            
          >
            {/* <FilterBar /> */}
            <div className=" col-12 d-flex flex-column p-0 pl-2">
              <FilterSelect />
              <div className="upper-link mt-md-0 mt-5">
                <Link
                  to={
                    props._CS?.col_status === 1
                      ? `/store/${props._CP3.business.buname}/shop`
                      : `/shop`
                  }
                  style={{
                    textDecoration: "none",
                    color: "var(--color_purple)",
                  }}
                >
                  <i
                    className="fas fa-store-alt"
                    onClick={(i) => {
                      props.callApi({
                        operation: "/products_by_category",
                        caller_ref: "CP3.render2",
                        params: {
                          bid: props._CP3.business.bid,
                          sid: props._CP3.business.sid,
                          catid: "root",
                          address: props._LOC.address,
                          location: props._LOC.location,
                          rad: props._LOC.rad,
                        },
                      });
                    }}
                  ></i>
                </Link>{" "}
                &nbsp;/
                {linkpath} &nbsp;
                <span style={{ textDecoration: "none", fontWeight: "bolder" }}>
                  {props._CP3.cname}
                </span>
              </div>
              {/* mobile screen filters */}
              <FilterPopup />
              <div className="d-flex flex-wrap list-cards">{response}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  //console.log(state);
  return {
    _CP3: state.item.CP3,
    _CART: state.item.CART,
    _LOC: state.item.LOC,
    _CS: state.item.CS,
  };
};

export default connect(mapStateToProps, {
  callApi,
})(CP3);
