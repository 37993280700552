import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
//import { createBrowserHistory } from "history";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import { callApi } from "../../actions/index";
import CP1 from "../CP1/CP1";
import CP2 from "../CP2/CP2";
import CP3 from "../CP3/CP3";

function CP(props) {
  let { url } = useRouteMatch();
  // console.log(url);
  //Goes here only on page refresh or initial mount
  useEffect(() => {
    let _bid = "";
    let _sid = "";
    let _buname = "";
    let _catpath = "";

    if (!props._GLOBAL.cp_display_template) {
      //console.log("CP1.componentDidMount CP1 doesn't exist");
      let _global = JSON.parse(localStorage.getItem("global"));
      //console.log(_global);
      if (_global && _global.business.sid) {
        _bid = _global.business.bid;
        _sid = _global.business.sid;
      }
      try {
        _catpath = window.location.href.match(
          new RegExp("/category/([^]+)")
        )[1];
      } catch (error) {
        return null;
      }
      _buname = window.location.href.split("/")[4];

      //      console.log('@@@@@@@@',_buname, _catpath);
      if (props._CP1 && props._CP1.catpath !== _catpath) {
        //console.log('Calling API CP')
        props.callApi({
          operation: "/products_by_category",
          caller_ref: "CP.useEffect",
          params: {
            buname: _buname,
            catpath: _catpath,
            bid: _bid,
            sid: _sid,
          },
        });
      }
    }
  }, []); // eslint-disable-line

  //this is called only when browser back and forward pressed
  const getCPData = () => {
    let _catpath = "";
    let _bid = "";
    let _sid = "";
    let _buname = "";
    let _cid = props._GLOBAL?.cid;

    try {
      _catpath = window.location.href.match(new RegExp("/category/([^]+)"))[1];
      //console.log('###### catpath:',_catpath);
    } catch (error) {
      //console.log('###### catpath error',error);
      return null;
    }
    if (props._GLOBAL && props._GLOBAL.business) {
      //console.log(_catpath);
      _bid = props._GLOBAL.business.bid;
      _sid = props._GLOBAL.business.sid;
      _buname = props._GLOBAL.business.buname;
    } else {
      _buname = window.location.href.split("/")[4];
    }

    props.callApi({
      operation: "/products_by_category",
      caller_ref: "CP.getCPData",
      params: {
        buname: _buname,
        catpath: _catpath,
        cid: _cid,
        bid: _bid,
        sid: _sid,
      },
    });
  };

  let _cp_display_template = "";
  props._GLOBAL && props._GLOBAL.cp_display_template
    ? (_cp_display_template = props._GLOBAL.cp_display_template)
    : (_cp_display_template = JSON.parse(localStorage.getItem("global"))
        ?.cp_display_template);

  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  const setCPPath = () => {
    if (history.location.pathname === props._CP1.cp_display_path) {
      //      console.log('####### setting CPPath....to cp1')
      _cp_display_template = "cp1";
    } else if (history.location.pathname === props._CP2.cp_display_path) {
      //      console.log('####### setting CPPath....to cp2')
      _cp_display_template = "cp2";
    } else if (history.location.pathname === props._CP3.cp_display_path) {
      //      console.log('####### setting CPPath....to cp3')
      _cp_display_template = "cp3";
    }
  };

  useEffect(() => {
    //    console.log('CP.useEffect ??????');
    //console.log('####### CP history.location.pathname:',history.location.pathname);

    return history.listen((location) => {
      if (history.action === "PUSH") {
        //console.log('####### CP PUSH history.location.pathname:',history.location.pathname);
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        //console.log('####### CP POP history.location.pathname:',history.location.pathname);
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          //console.log("####### Forward event happened???");
          getCPData();
          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          //console.log("##### Backward event happened???");
          getCPData();
          // Handle back event
        }
      }
      setCPPath();
    });
  }, [locationKeys, history]); // eslint-disable-line

  //const location = useLocation();
  //const history = createBrowserHistory()

  //setCPPath();

  return (
    <div>
      {/* {
        console.log("what is _cp_display_template", _cp_display_template)
      } */}

      {_cp_display_template === "cp1" ? (
        <CP1 />
      ) : _cp_display_template === "cp2" ? (
        <CP2 />
      ) : _cp_display_template === "cp3" ? (
        <CP3 />
      ) : null}

      <Switch>
        <Route
          path={
            props._CS?.col_status === 1
              ? `${url}/store/:id/category/:id`
              : `/category/:id`
          }
        >
          {/* <CP /> */}
        </Route>
      </Switch>
    </div>
  );
}

const mapStateToProps = (state) => {
  //console.log(state, "CP.mapStateToProps");
  return {
    _CP1: state.item.CP1,
    _CP2: state.item.CP2,
    _CP3: state.item.CP3,
    _GLOBAL: state.item.GLOBAL,
    _CS: state.item.CS,
  };
};
export default connect(mapStateToProps, {
  callApi,
})(CP);
