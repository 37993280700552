
import React, { useState } from "react";
import { connect } from "react-redux";
import { callApi } from "../../actions/index";
import Carousel from "react-elastic-carousel";

const ScartTime = (props) => {
  const [windowWidth, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", function () {
    setWidth(window.innerWidth);
  });
    const [timeSlot, setTimeSlot] = useState({});
    const selectTimeSlot = (e, sl, sid) => {
        setTimeSlot(sl);
        //console.log("setTimeSlot", sl);
        
        var box = document.querySelectorAll(".time-box");
        box.forEach((btn) => {
          btn.classList.remove("time-box-select");
        });
        if (e.currentTarget.classList.contains("time-box")) {
          e.currentTarget.classList.add("time-box-select");
        }
    
        var slottic = document.querySelectorAll(".slot-t-tic");
        slottic.forEach((btn) => {
          btn.classList.remove("slot-tic-active");
        });
        var price = document.querySelectorAll(".price-free");
        price.forEach((txt) => {
          txt.classList.remove("slot-tic-active");
          txt.style.color = "green";
        });
        if (e.currentTarget.childNodes[0].classList.contains("slot-t-tic")) {
          e.currentTarget.childNodes[0].classList.add("slot-tic-active");
          //e.currentTarget.childNodes[2].style.color = "#00FD03";
          e.currentTarget.childNodes[1].querySelector(".price-free").style.color =
            "#00FD03";
        }
        //console.log("props_dateSlot", props.dateSlot)
        if (timeSlot !== "" && props.dateSlot !== "") {
          let sbtn = document.getElementById('time-slots_'+sid);
          sbtn.classList.add("schedule-btn-on");
          sbtn.querySelector(".schedule-btn")
             .classList.add("schedule-active-btn");
        }
      };
      
      const saveTimeSlotFulfilment = (e, _sid) => {
        props.callApi({
          operation: "/reserve_slot",
          caller_ref: "BZPopup.reserveStoreSlot",
          params: {
            //bid: `${props._GLOBAL.business?.bid}`,
            sid: _sid,
            slotkey: timeSlot.slid,
            type: props.fulfilment?.toLowerCase(),
          },
        });
        let sbtn = document.getElementById('time-slots_'+_sid);
        sbtn.classList.remove("schedule-btn-on");
        sbtn.querySelector(".schedule-btn")
        .classList.remove("schedule-active-btn");
        removefulfilmentExpend(_sid);
      }
      const removefulfilmentExpend = (cartid) => {
        document.querySelector("#fulfilmentDivExpend_"+cartid).classList.add("d-none");
        document.querySelector(".fulfilment-change_"+cartid).classList.remove("d-none");
        document.querySelector(".fulfilment-done_"+cartid).classList.add("d-none");
      };
    //console.log(props.dateSlot?.slot_grps, "dddd")
    const TimePicker = props.dateSlot?.slot_grps?.map((sg) => {
    return <div className="TimePicker">
    <span
      className="row mb-1 ms-2"
      style={{
        color: "var(--color_purple)",
        fontSize: ".8em",
      }}
    >
      {sg?.tod}
    </span>
    <Carousel
      renderArrow={props.carouselArrow}
      className="p-0"
      itemsToShow={windowWidth >= 500
          ? 3.5
          : 2.5
      }
    >
{sg?.slots?.map((time)=>{
     return               <button
     className={
       props._scart?.fulfilment?.time_slot?.id === time.slid 
      //  && props._scart?.fulfilment?.type ===
      //    props.clickedFulfilment.toLowerCase()
         ? "time-box-selected d-flex align-items-center flex-column p-0 rounded"
         : "time-box d-flex align-items-center flex-column position-relative p-0 rounded"
     }
     cartslid={props.scart?.fulfilment?.time_slot?.id}
       dayslid={time.slid}
     onClick={(e, i) => {
      selectTimeSlot(e, time, props.scart?.sid);
     }}
   >
     <div className="h-50 w-100">
     <div className='pt-1 sltTime'>
         <span style={{fontSize:"1.7em",fontWeight:"bold",lineHeight:"1.6em"}}>{time.f} - {time.t}</span>
       </div>
     </div>
     {/* 2 */}
     <div className='h-50 w-100 bg-white'> 
     <div className="slot-box pt-1">
      {
        props._scart?.fulfilment?.time_slot?.id === time.slid 
        // && props._scart?.fulfilment?.type ===
        //   props.clickedFulfilment.toLowerCase()
          ?
       <div><span style={{fontSize:'0.7rem',color:'var(--color_purple)'}}>Reserved for you</span></div>
       :
       <div>
         <span style={{fontSize:'0.7rem',lineHeight:"1em"}} className='availablee'>Available: {time.s}</span>
       <span className="slot-t-tic">
       <i
         className={
           props._scart?.fulfilment?.time_slot?.id === time.slid &&
           props._scart?.fulfilment?.type ===
             props.clickedFulfilment.toLowerCase()
             ? "fas fa-check slot-tic-active"
             : "fas fa-check"
         }
       ></i>
     </span>
     </div>
      }
       <div className="price-free">
         {time.price === 0 ? "Free" : time.price}
       </div>
     </div>
     </div>
   </button>

})}
    </Carousel>
    </div>
    }) 

    return(
        <div id={"time-slots_"+props.scart?.sid} 
        className="timeSlots">
            {TimePicker}
            <div className="schedule-box m-auto">
              <button
                type="button"
                className="schedule-btn btn w-25"
                onClick={(e) => {
                  saveTimeSlotFulfilment(
                    e,
                    props.scart?.sid
                  );
                }}
              >
                Schedule
              </button>
            </div>
        </div>
        
    )
}

const mapStateToProps = (state) => {
    return {
      _CART: state.item.CART,
      _GLOBAL: state.item.GLOBAL,
      _auth: state.auth,
      _BZ: state.item.BZ,
      _LA: state.item.LA,
      _LC: state.item.LC,
      _SS: state.item.SS,
    };
  };
  
  export default connect(mapStateToProps, {
    callApi,
  })(ScartTime);
