import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { callApi, getScrollPosition } from "../../actions/index";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { overflow_hidden, handleLoginO } from "../event_functions";

function CPcard(props) {
  useEffect(() => {
    // window.scrollTo(0, `${props._SCROLL}`)
    var btnAdd = document.querySelectorAll(".count1");

    const handleClick = (e) => {
      e.preventDefault();
      btnAdd.forEach((btn) => {
        console.log(
          btn.parentElement.parentElement.nextElementSibling,
          "hhhhhh"
        );
        btn.parentElement.parentElement.nextElementSibling.classList.remove(
          "toggle-class"
        );
      });
      e.currentTarget.parentElement.parentElement.nextElementSibling.classList.add(
        "toggle-class"
      );
    };
    btnAdd.forEach((btn) => {
      btn.addEventListener("click", handleClick);
    });

    document.addEventListener(
      "click",
      (evt) => {
        let targetEl = evt.target; // clicked element

        if (
          targetEl.classList.contains("addPlus") ||
          targetEl.classList.contains("card-add-qunt") ||
          targetEl.classList.contains("focus")
        ) {
          return null;
        } else {
          var btnAdd = document.querySelectorAll(".card-add-qunt");
          btnAdd.forEach((btn) => {
            btn.classList.remove("toggle-class");
          });
        }
      },
      { passive: true }
    );

    let window_width = window.innerWidth;
    let carousel = document.querySelectorAll("#carousel");
    if (window_width > 1440) {
      carousel.forEach((car) => {
        car.classList.add("container");
      });
    } else {
      carousel.forEach((car) => {
        car.classList.remove("container");
      });
    }
  });

  //
  function getQtyFromCart(pid, sid) {
    // console.log('getQtyFromCart.............')
    let mitem = null;
    let store = props._CART.scarts.filter((item) => {
      return sid === item.sid;
    });
    if (store[0]) {
      mitem = store[0].product.filter((item) => {
        return pid === item.pid;
      });
    }
    if (mitem && mitem[0] && mitem[0].qty) {
      return mitem[0].qty;
    } else {
      return 0;
    }
  }

  function getQtyFromCart2(pid, sid) {
    // console.log('getQtyFromCart.............')
    let mitem = null;
    let store = props._CART.scarts.filter((item) => {
      return sid === item.sid;
    });
    if (store[0]) {
      mitem = store[0].product.filter((item) => {
        return pid === item.pid;
      });
    }
    if (mitem && mitem[0] && mitem[0].qty) {
      return <span className="count1">{mitem[0].qty}</span>;
    } else {
      return <i className="fa fa-plus count0"></i>;
    }
  }

  let subcat = props.subcat;
  /*
    const getPDData = (_buname, _sid, _pid) => {
  
      let _s_pid = "";
      if (props._PD && props._PD.product) {
        _s_pid = props._PD.product.filter((item) => {
        //console.log(item.id, _pid);
          return item.id === _pid;
        });
      //console.log(_s_pid.length, _pid);
      }
  
      //some times we are having old PD data, so above check make sure we have clicked PD data
      // if PD data doesn't exist, then we load PD for that pid
      if (_s_pid.length === 0) {
      //console.log("is it calling api?");
        props.callApi({
          operation: "/products_details",
          params: {
            bid: props.bid,
            sid: _sid,
            pid: _pid,
          },
        });
      }
    };

    */

  //const { isAuthenticated, user } = props._auth;
  const { isAuthenticated } = props._auth;
  return (
    <div className="col-6 col-sm-4 col-md-4 col-xl-2  col-lg-3 p-1">
      <div
        className="card_ border border-lighter p-2 text-center mt-md-3 bg-white "
        id="instant_card"
        onClick={(e, i) => {
          props.callApi({
            operation: "/products_details",
            caller_ref: "CPcard.render",
            params: {
              bid: props.bid,
              sid: props.sid,
              pid: `${subcat.pid}`,
              catid: props.cat,
            },
          });
          e.stopPropagation();
        }}
      >
        <div className="card-top-div ms-auto" id="addPlus">
          <i
            className="addPlus"
            onClick={(f, j) => {
              if (isAuthenticated) {
                f.stopPropagation();
                //console.log(f, j);
                if (!Number(f.target.innerText)) {
                  //props.addItem2Cart(props.sid, subcat);
                  props.callApi({
                    operation: "/save_item_to_cart",
                    caller_ref: "CPcard.render3",
                    params: {
                      bid: props.bid,
                      sid: props.sid,
                      pid: `${subcat.pid}`,
                      qty: getQtyFromCart(subcat.pid, props.sid) + 1,
                    },
                  });
                }
              } else {
                handleLoginO();
              }
            }}
          >
            {getQtyFromCart2(subcat.pid, props.sid, 1)}
          </i>
        </div>
        <div
          id="card-add-qunt"
          className="d-flex justify-content-between align-items-center px-3 card-add-qunt focus"
        >
          {getQtyFromCart(subcat.pid, props.sid) <= 1 ? (
            <span className="focus">
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-trash"
                onClick={(i) => {
                  if (isAuthenticated) {
                    i.stopPropagation();
                    //props.deleteItem4Cart(props.sid, subcat);
                    props.callApi({
                      operation: "/save_item_to_cart",
                      caller_ref: "CPcard.render4",
                      params: {
                        bid: props.bid,
                        sid: props.sid,
                        pid: `${subcat.pid}`,
                        qty: getQtyFromCart(subcat.pid, props.sid) - 1,
                      },
                    });
                  }
                }}
              ></i>
            </span>
          ) : (
            <span
              style={{ fontSize: "30px", marginTop: "-0.9em" }}
              className="focus faMinus"
              onClick={(i) => {
                if (isAuthenticated) {
                  i.stopPropagation();
                  //props.removeItem4Cart(props.sid, subcat);

                  props.callApi({
                    operation: "/save_item_to_cart",
                    caller_ref: "CPcard.render1",
                    params: {
                      bid: props.bid,
                      sid: props.sid,
                      pid: `${subcat.pid}`,
                      qty: getQtyFromCart(subcat.pid, props.sid) - 1,
                    },
                  });
                } else {
                  handleLoginO();
                }
              }}
            >
              _
            </span>
          )}
          <span className="focus" style={{ fontSize: "20px" }}>
            {getQtyFromCart(subcat.pid, props.sid)}
          </span>
          <span
            className="focus"
            style={{ fontSize: "30px", cursor: "pointer" }}
            onClick={(i) => {
              if (isAuthenticated) {
                i.stopPropagation();
                //props.addItem2Cart(props.sid, subcat);
                props.callApi({
                  operation: "/save_item_to_cart",
                  caller_ref: "CPcard.render2",
                  params: {
                    bid: props.bid,
                    sid: props.sid,
                    pid: `${subcat.pid}`,
                    qty: getQtyFromCart(subcat.pid, props.sid) + 1,
                  },
                });
              } else {
                handleLoginO();
              }
            }}
          >
            +
          </span>
        </div>
        <Link to={props.link} style={{ textDecoration: "none" }}>
          <LazyLoadImage
            src={props.img}
            onClick={(i) => overflow_hidden()}
            className="card-img img-fluid "
            loading="lazy"
            alt=""
          />

          <div className="text-left mt-2">
            <Link
              to={
                props._CS?.col_status === 1
                  ? `/store/${props.buname}/${props.sid}/item/${subcat.pid}`
                  : `/${props.sid}/item/${subcat.pid}`
              }
              className="item_name_cart"
              onClick={(k) => {
                //console.log("coming here????", k);
                //getPDData(props.subcat.cuname, props.subcat.cid, subcat.pid);
              }}
            >
              <p className="item_name">{subcat.pname}</p>
            </Link>
            <h6 className="main_price card-title py-1 px-md-2 px-1 d-flex justify-content-between rounded">
              ${subcat.lp}
              <small className="off_price font-weight-lighter">10% off</small>
            </h6>
            <p className="mt-n3" style={{ color: "#999999" }}>
              <small>{subcat.size}</small>
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    _auth: state.auth,
    _CART: state.item.CART,
    _SCROLL: state.item.Scroll,
    _CS: state.item.CS,
  };
};
export default connect(mapStateToProps, {
  callApi,
  getScrollPosition,
})(CPcard);
