import React, { useEffect, useState } from "react";
import { addressPopupClose } from "./event_functions";
import {  withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import Checkbox from "@material-ui/core/Checkbox";
import {
  callApi,
} from "../actions/index";
import { TextField } from "@material-ui/core";

// accordian
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
function AddressPopup(props) {


  const classesForAccordion = useStyles();
  const [name_val, setname_val] = useState();
  const [addr1_val, setaddr1_val] = useState();
  const [addr2_val, setaddr2_val] = useState();
  const [city_val, setCity_val] = useState();
  const [state_val, setState_val] = useState();
  const [country, setCountry] = useState();
  const [zipcode_val, setZipcode_val] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [del_instruction, setTextarea] = useState();

  let checkState = true;
  const [checkBox, setcheckBox] = useState(checkState);


  const addAddress = () => {
//    let _length = props._LA.address.length;
    
    props.callApi({
      operation: "/save_address",
      caller_ref: "AddressPopup.addAddress",
      params: {
        name: name_val,
        addr_line1: addr1_val,
        addr_line2: addr2_val,
        city: city_val,
        state: state_val,
        country: zipcode_val,
        zipcode: country,
        phone: phoneNo,
        del_instruction: del_instruction,
        checkedDefaultAddress: checkBox,
      },
    });
  };

  // const clearForm = () => {
  //   clearFormData();
  //   setcheckBox(false);
  // };

  const updateAddress = () => {
    props.callApi({
      operation: "/edit_address",
      caller_ref: "AddressPopup.addAddress",
      params: {
        // bid: props._GLOBAL.business.bid,
        aid: props._CLICKED_INDEX,
        name: name_val,
        addr_line1: addr1_val,
        addr_line2: addr2_val,
        city: city_val,
        state: state_val,
        country: zipcode_val,
        zipcode: country,
        phone: phoneNo,
        del_instruction: del_instruction,
        checkedDefaultAddress: checkBox,
      },
    });
  };

  let editedValue = props._LA.address?.find((addr)=>{
    return addr._id === props._CLICKED_INDEX;
  });

//console.log(editedValue)

  useEffect(() => {
    //console.log("useEffect Clicked Index")
    if (editedValue) {
      setname_val(`${editedValue.name ? editedValue.name : ""}`);
      setaddr1_val(`${editedValue.addr_line1 ? editedValue.addr_line1 : ""}`);
      setaddr2_val(`${editedValue.addr_line2 ? editedValue.addr_line2 : ""}`);
      setCity_val(`${editedValue.city ? editedValue.city : ""}`);
      setState_val(`${editedValue.state ? editedValue.state : ""}`);
      setCountry(`${editedValue.zipcode ? editedValue.zipcode : ""}`);
      setZipcode_val(`${editedValue.country ? editedValue.country : ""}`);
      setPhoneNo(`${editedValue.phone ? editedValue.phone : ""}`);
      setTextarea(
        `${editedValue.del_instruction ? editedValue.del_instruction : "none"}`
      );
      setcheckBox(editedValue.checkedDefaultAddress);
    } else {
      //console.log("clearing form fields")
      setname_val("");
      setaddr1_val("");
      setaddr2_val("");
      setCity_val("");
      setState_val("");
      setCountry("");
      setZipcode_val("");
      setPhoneNo("");
      setTextarea("");
      setcheckBox(false);
    }
  }, [props._CLICKED_INDEX]); // eslint-disable-line

  
  if (props._LA.address?.length === 0) {
    checkState = true;
  } else {
    checkState = false;
  }

  // const checkDefaultAddress = () => {
  //   if (checkBox === false) {
  //     setcheckBox(true);
  //   } else {
  //     setcheckBox(false);
  //   }
  // };
  return (
    <div className={props._PAPopup==="cart"?"EditMiniPopup position-absolute ADDRESSPOPUP":"address-popup_main fixed-top ADDRESSPOPUP"} id={props._PAPopup==="cart"?"add-mini-popup":"address-popup_main"}>
      <div className={props._PAPopup==="cart"?"subMiniPopup bg-white rounded":"address-popup position-relative rounded p-3"}>
        <i
          className="far fa-times-circle position-absolute"
          style={{
            color: "var(--color_purple)",
            top: "5px",
            right: "7px",
            cursor: "pointer",
          }}
          onClick={(e) =>addressPopupClose()}
          ></i>
        <h3
          className={props._PAPopup==="cart"?"new-addr-head py-3 text-center":"new-addr-head py-2 pb-3 text-center"}
          style={{ color: "var(--color_purple)" }}
        >
          {editedValue ? "Edit Address" : "New Address"}
        </h3>

        <div className={props._PAPopup==="cart"?"d-flex flex-column px-md-3 px-2":"d-flex flex-column"}>
          <TextField  
            size="small"
            variant="outlined"
            onChange={(e) => {
              setname_val(e.target.value);
            }}
            value={name_val}
            label="Full name (First and Last name)"
            className={props._PAPopup==="cart"?"outlined-basic w-100 mt-2":"address-popup-input address-popup-input1 p-2 border rounded mt-1 w-100"}
            id="name"
          />
          <TextField
            size="small"
            variant="outlined"
            onChange={(e) => {
              setaddr1_val(e.target.value);
            }}
            value={addr1_val}
            label="Address Line 1 (Street address, P.O box, company name, c/o)"
            className={props._PAPopup==="cart"?"outlined-basic w-100 mt-2":"address-popup-input p-2 border rounded mt-1"}
            id="addr_line1"
          />
          <TextField
            size="small"
            variant="outlined"
            onChange={(e) => {
              setaddr2_val(e.target.value);
            }}
            value={addr2_val}
            label="AddressLine 2 (Suite, apartment or unit number)"
            className={props._PAPopup==="cart"?"outlined-basic w-100 mt-2":"address-popup-input p-2 border rounded mt-1"}
            id="addr_line2"
          />
          <div className="mt-1 d-flex justify-content-between">
            <TextField
              size="small"
              variant="outlined"
              onChange={(e) => {
                setCity_val(e.target.value);
              }}
              value={city_val}
              label="City"
              className={props._PAPopup==="cart"?"outlined-basic w-100 mt-2 ":"address-popup-input-s p-2 border rounded"}
              id="city"
            />
            <TextField
              size="small"
              variant="outlined"
              onChange={(e) => {
                setState_val(e.target.value);
              }}
              value={state_val}
              label="State/Province/Region"
              className={props._PAPopup==="cart"?"outlined-basic w-100 mt-2 ms-1":"address-popup-input-s p-2 border rounded"}
              id="state"
            />
          </div>
          <div className="mt-1 d-flex justify-content-between">
            <TextField
              size="small"
              variant="outlined"
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              value={country}
              label="Zip Code"
              className={props._PAPopup==="cart"?"outlined-basic w-100 mt-2":"address-popup-input-s p-2 border rounded"}
              id="zipcode"
            />
            <TextField
              size="small"
              variant="outlined"
              onChange={(e) => {
                setZipcode_val(e.target.value);
              }}
              value={zipcode_val}
              label="Country"
              className={props._PAPopup==="cart"?"outlined-basic w-100 mt-2 ms-1":"address-popup-input-s p-2 border rounded"}
              id="country"
            />
          </div>
          <TextField
            size="small"
            variant="outlined"
            onChange={(e) => {
              setPhoneNo(e.target.value);
            }}
            value={phoneNo}
            label="Phone number"
            className={props._PAPopup==="cart"?"outlined-basic w-100 mt-2":"address-popup-input p-2 border rounded mt-1"}
            id="phone"
          />
          <div className={classesForAccordion.root}>
            <Accordion style={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="p-0"
                style={{ height: "10px" }}
                id="panel1a-header"
              >
                <Typography className={classesForAccordion.heading} style={{fontSize:props._PAPopup==="cart"?"0.75rem":"1rem"}}>
                  Delivery Instructions
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="p-0 position-relative">
                <Typography>
                  <textarea
                    onChange={(e) => {
                      setTextarea(e.target.value);
                    }}
                    value={del_instruction}
                    label="Delivery Instructions"
                    id="del_instruction"
                    className={props._PAPopup==="cart"?"outlined-basic w-100 mt-2 w-100":"address-popup-input p-2 border rounded mt-1"}
                    rows="4"
                    cols={props._PAPopup==="cart"?"75":"80"}
                  ></textarea>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
       
        <div className="py-3 d-flex justify-content-end">
          <div>
            <button
              className={props._PAPopup==="cart"?"btn-sm bg-white addressBtnPopop":"btn bg-white addressBtnPopop"}
              onClick={(e) => {
                addressPopupClose(e);
                //clearForm();
              }}
              style={{
                border: "1px solid var(--color_purple)",
                color: "var(--color_purple)",
              }}
            >
              Cancel
            </button>
          </div>
          <div className="px-1">
            {editedValue ? (
              <button
                className={props._PAPopup==="cart"?"btn-sm bg-white addressBtnPopop":"btn bg-white addressBtnPopop"}
                onClick={(e) => {
                  updateAddress(e);
                  addressPopupClose(e);
                }}
                style={{ background: "var(--color_purple)" }}
              >
                Update Address
              </button>
            ) : (
              <button
                className={props._PAPopup==="cart"?"btn-sm bg-white addressBtnPopop":"btn bg-white addressBtnPopop"}
                onClick={(e) => {
                  addAddress(e);
                  addressPopupClose(e);
                }}
                style={{ background: "var(--color_purple)" }}
              >
                Add Address
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  //console.log("State change event called in AddressPopup");
  return {
    _GLOBAL: state.item.GLOBAL,
    _CLICKED_INDEX: state.item.CLICKED_INDEX,
    _LA: state.item.LA,
    _PAPopup:state.item.PAPopup
  };
};

export default withRouter(
  connect(mapStateToProps, {
    callApi,
  })(AddressPopup)
);
