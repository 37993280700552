import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import { callApi } from "../../actions/index";
import { connect } from "react-redux";
import "../CAROUSEL/carousel.css";
import { overflow_hidden, handleLoginO } from "../event_functions";
import { LazyLoadImage } from "react-lazy-load-image-component";


function PDCAROUSEL(props) {
  const [windowWidth, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", function () {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    // window.scrollTo(0, `${props._SCROLL}`)
    var btnAdd = document.querySelectorAll(".count1");

    const handleClick = (e) => {
      e.preventDefault();
      btnAdd.forEach((btn) => {
        console.log(
          btn.parentElement.parentElement.nextElementSibling,
          "hhhhhh"
        );
        btn.parentElement.parentElement.nextElementSibling.classList.remove(
          "toggle-class"
        );
      });
      e.currentTarget.parentElement.parentElement.nextElementSibling.classList.add(
        "toggle-class"
      );
    };
    btnAdd.forEach((btn) => {
      btn.addEventListener("click", handleClick);
    });

    document.addEventListener(
      "click",
      (evt) => {
        let targetEl = evt.target; // clicked element

        if (
          targetEl.classList.contains("addPlus") ||
          targetEl.classList.contains("card-add-qunt") ||
          targetEl.classList.contains("focus")
        ) {
          return null;
        } else {
          var btnAdd = document.querySelectorAll(".card-add-qunt");
          btnAdd.forEach((btn) => {
            btn.classList.remove("toggle-class");
          });
        }
      },
      { passive: true }
    );

    let window_width = window.innerWidth;
    let carousel = document.querySelectorAll("#carousel");
    if (window_width > 1440) {
      carousel.forEach((car) => {
        car.classList.add("container");
      });
    } else {
      carousel.forEach((car) => {
        car.classList.remove("container");
      });
    }
  });

  //
  function getQtyFromCart(pid, sid) {
    // console.log('getQtyFromCart.............')
    let mitem = null;
    let store = props._CART.scarts.find((item) => {
      return sid === item.sid;
    });
    if (store) {
      mitem = store.product.find((item) => {
        return pid === item.pid;
      });
    }
    if (mitem && mitem && mitem.qty) {
      return mitem.qty;
    } else {
      return 0;
    }
  }

  function getQtyFromCart2(pid, sid) {
    // console.log('getQtyFromCart.............')
    let mitem = null;
    let store = props._CART.scarts.find((item) => {
      return sid === item.sid;
    });
    if (store) {
      mitem = store.product.find((item) => {
        return pid === item.pid;
      });
    }
    if (mitem && mitem.qty) {
      return <span className="count1">{mitem.qty}</span>;
    } else {
      return <i className="fa fa-plus count0"></i>;
    }
  }

  const getPDData = (_buname, _cid, _pid) => {
    let _s_pid = "";
    if (props._PD && props._PD.product) {
      _s_pid = props._PD.product.filter((item) => {
        //console.log(item.id, _pid);
        return item.id === _pid;
      });
      //console.log(_s_pid.length, _pid);
    }

    //some times we are having old PD data, so above check make sure we have clicked PD data
    // if PD data doesn't exist, then we load PD for that pid
    if (_s_pid.length === 0) {
      //console.log("is it calling api?");
      props.callApi({
        operation: "/products_details",
        caller_ref: "CAROUSEL.getPDData",
        params: {
          bid: props.bid,
          sid: props.sid,
          pid: _pid,
          catid: _cid,
        },
      });
    }
  };

  //const { isAuthenticated, user } = props._auth;
  const { isAuthenticated } = props._auth;
  return (
    <div
      className="container-fluid mt-md-1 mt-0 py-lg-4 py-2 px-md-2 px-0 p-0" style={{background:"white"}}
      id="carousel"
    >
      {/* {
      console.log("CAROUSEL????")
    } */}
      <div className="row w-75">
        <h5 style={{ color: "#757575",textAlign:"left" }} className="ps-lg-4 ps-2 category_name">
          <Link
            style={{ color: "var(--color_purple)" }}
            // to={
            //   props._CS?.col_status === 1
            //     ? `/store/${props.buname}/category/${props.item.catpath}`
            //     : `/category/${props.item.catpath}`
            // }
            onClick={(i) => {
              //console.log('calling api products_by_category $$$$$$$$$$$$$$$$$$',props.bid,props.sid,props.item.cid)
              //console.log('col_status', props._CS);
              props.callApi({
                operation: "/products_by_category",
                caller_ref: "CAROUSEL.render1",
                params: {
                  bid: props.bid,
                  sid: props.sid,
                  catid: props.item.cid,
                },
              });
              //console.log("callApi /products_by_category");
            }}
          >
              YOU MAY ALSO LIKE
            {/* {props.item.cname}? */}
          </Link>
        </h5>
      </div>
      {props.item.product.length > 0 ? (
        <div className="col-12 p-md-0 position-relative overflow-hidden px-0">
          {typeof props.item.product == "object" ? (
            <Carousel
              itemsToShow={
                windowWidth >= 1300
                  ? 6.5
                  : windowWidth < 1300 && windowWidth >= 1000
                  ? 5.5
                  : windowWidth < 999 && windowWidth >= 768
                  ? 4
                  : windowWidth < 767 && windowWidth >= 500
                  ? 3.5
                  : 2.5
              }
              showEmptySlots
              // itemsToScroll={
              //   windowWidth >= 1300
              //     ? 6.5
              //     : windowWidth < 1300 && windowWidth >= 1000
              //     ? 5.5
              //     : windowWidth < 999 && windowWidth >= 768
              //     ? 4
              //     : windowWidth < 767 && windowWidth >= 500
              //     ? 3.5
              //     : 2.5
              // }
              className="react-carosuel"
            >
              {props.item.product.map((sitem, index) => (
                <div
                  // key={index}
                  className="card p-2 text-center gy-2 position-relative" style={{background:"#F0F0F0"}}
                  id="instant_card"
                  onClick={(e, i) => {
                    props.callApi({
                      operation: "/products_details",
                      caller_ref: "CAROUSEL.render2",
                      params: {
                        bid: props.bid,
                        sid: props.sid,
                        pid: `${sitem.pid}`,
                        catid: props.item.cid,
                      },
                    });
                    e.stopPropagation();
                    //console.log("callApi /product_details");
                  }}
                >
                  <div className="card-top-div ms-auto" id="addPlus">
                    <i
                      className="addPlus"
                      onClick={(f, j) => {
                        if (isAuthenticated) {
                          f.stopPropagation();
                          if (!Number(f.target.innerText)) {
                            // props.addItem2Cart(
                            //   props.sid,
                            //   sitem
                            // );
                            props.callApi({
                              operation: "/save_item_to_cart",
                              caller_ref: "CAROUSEL.render2",
                              params: {
                                bid: props.bid,
                                sid: props.sid,
                                pid: `${sitem.pid}`,
                                qty: getQtyFromCart(sitem.pid, props.sid) + 1,
                              },
                            });
                          }
                        } else {
                          handleLoginO();
                        }
                      }}
                    >
                      {getQtyFromCart2(sitem.pid, props.sid, 1)}
                    </i>
                  </div>
                  <div
                    id="card-add-qunt"
                    className="d-flex justify-content-between align-items-center px-3 card-add-qunt m-auto "
                  >
                    {getQtyFromCart(sitem.pid, props.sid) <= 1 ? (
                      <span className="focus">
                        <i
                          className="fa fa-trash item_delete_icon"
                          onClick={(i) => {
                            if (isAuthenticated) {
                              i.stopPropagation();
                              // props.deleteItem4Cart(
                              //   props.sid,
                              //   sitem
                              // );
                              props.callApi({
                                operation: "/save_item_to_cart",
                                caller_ref: "CAROUSEL.render3",
                                params: {
                                  bid: props.bid,
                                  sid: props.sid,
                                  pid: `${sitem.pid}`,
                                  qty: getQtyFromCart(sitem.pid, props.sid) - 1,
                                },
                              });
                            }
                          }}
                        ></i>
                      </span>
                    ) : (
                      <span
                        style={{ fontSize: "30px", marginTop: "-0.9em" }}
                        className="focus faMinus"
                        onClick={(i) => {
                          if (isAuthenticated) {
                            i.stopPropagation();
                            // props.removeItem4Cart(
                            //   props.sid,
                            //   sitem
                            // );
                            props.callApi({
                              operation: "/save_item_to_cart",
                              caller_ref: "CAROUSEL.render4",
                              params: {
                                bid: props.bid,
                                sid: props.sid,
                                pid: `${sitem.pid}`,
                                qty: getQtyFromCart(sitem.pid, props.sid) - 1,
                              },
                            });
                          } else {
                            handleLoginO();
                          }
                        }}
                      >
                        _
                      </span>
                    )}

                    <span className="focus" style={{ fontSize: "20px" }}>
                      {getQtyFromCart(sitem.pid, props.sid)}
                    </span>
                    <span
                      className="focus"
                      style={{ fontSize: "30px", cursor: "pointer" }}
                      onClick={(i) => {
                        if (isAuthenticated) {
                          i.stopPropagation();
                          //props.addItem2Cart(props.sid, sitem);
                          props.callApi({
                            operation: "/save_item_to_cart",
                            caller_ref: "CAROUSEL.render1",
                            params: {
                              bid: props.bid,
                              sid: props.sid,
                              pid: `${sitem.pid}`,
                              qty: getQtyFromCart(sitem.pid, props.sid) + 1,
                            },
                          });
                        } else {
                          handleLoginO();
                        }
                      }}
                    >
                      +
                    </span>
                  </div>
                  <Link
                    to={
                      props._CS?.col_status === 1
                        ? `/store/${props.buname}/item/${sitem.pid}`
                        : `/item/${sitem.pid}`
                    }
                    style={{ textDecoration: "none"}}
                    onClick={(i) => overflow_hidden()}
                    className='card-img-parent'
                  >
                    {/* <LazyLoadImage
                      src={props.uris[sitem.pthumb?.uri] + sitem.pthumb?.src}
                      loading="lazy"
                      className="card-img img-fluid "
                      alt=""
                    /> */}
                    <LazyLoadImage
                      src={props.uris[sitem.pthumb?.uri] + sitem.pthumb?.src}
                      loading="lazy"
                      className="card-img img-fluid m-auto"
                      alt=""
                    />
                  </Link>
                  <div className="text-left mt-2">
                    <Link
                      to={
                        props._CS?.col_status === 1
                          ? `/store/${props.buname}/${props.sid}/item/${sitem.pid}`
                          : `/${props.sid}/item/${sitem.pid}`
                      }
                      // to={`/store/${props.buname}/item/${sitem.pid}`}

                      className="item_name_cart"
                      onClick={(e, k) => {
                        // console.log(
                        //     "coming here????",
                        //     props.item.cuname,
                        //     props.item.cid,
                        //     sitem.pid
                        //   );

                        getPDData(props.item.cuname, props.item.cid, sitem.pid);
                        e.stopPropagation();
                      }}
                    >
                      <p className="item_name">{sitem.pname}</p>
                    </Link>
                    <h6 className="main_price card-title py-1 px-md-2 px-1 d-flex justify-content-between rounded">
                      ${sitem.lp}
                      <small className="off_price font-weight-lighter">
                        10% off
                      </small>
                    </h6>

                    <p className="mt-n3" style={{ color: "#999999" }}>
                      <small>{sitem.size}</small>
                    </p>
                  </div>
                </div>
              ))}
            </Carousel>
          ) : null}
          {/* <div className='opacity-div col-2 position-absolute d-lg-block d-none'></div> */}
        </div>
      ) : null}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    _auth: state.auth,
    _CART: state.item.CART,
    _CS: state.item.CS,
    // _SCROLL:state.item.Scroll
  };
};

export default connect(mapStateToProps, {
  callApi,
  // getScrollPosition
})(PDCAROUSEL);
