import React from "react"
import ReactDom from "react-dom"
import{BrowserRouter} from "react-router-dom"
import App from "./app/app"
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// import {createStore,applyMiddleware} from 'redux';
// import App from './components/App';
// import reduxThunk from 'redux-thunk';
// import logger from 'redux-logger';
import {Provider} from 'react-redux'
import jwt_decode from 'jwt-decode'
import rootReducer from './reducers';
import setAuthToken from './setAuthToken'
import { setCurrentUser, logoutUser } from './actions/authentication';

// const store = createStore(rootReducer,applyMiddleware(reduxThunk))

const store = createStore(
    rootReducer, 
    compose(applyMiddleware(thunk))
    //, window.__REDUX_DEVTOOLS_EXTENSION__&& window.__REDUX_DEVTOOLS_EXTENSION__())
            );


if(localStorage.jwtToken) {
    setAuthToken(localStorage.jwtToken);
    const decoded = jwt_decode(localStorage.jwtToken);
    store.dispatch(setCurrentUser(decoded));
  
    const currentTime = Date.now() / 1000;
    if(decoded.exp < currentTime) {
      store.dispatch(logoutUser());
    }
  }



ReactDom.render(
    <Provider store={store}>
    <BrowserRouter>
    <App/>
    </BrowserRouter>
    </Provider>,
    document.getElementById('root')

)