import React, { useEffect,useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { callApi } from "../../actions/index";
import { useHistory } from "react-router";

// import {Dropdown} from 'react-bootstrap'
import {
  openSearchForm,
  handleCloseSearch,
  handleSearchPopup,
} from "../event_functions";
// import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import Clear from "@material-ui/icons/Clear";
// import Select from 'react-select'
// import { Menu, Typeahead, useItem, withItem} from 'react-bootstrap-typeahead'
// import Autosuggest from 'react-autosuggest'

function SS(props) {
  const history = useHistory();
//  console.log(props._SG,'propsSGSuggest')
const [Input, setInput]=useState('')
  const getSeachSuggestions = (query) => {
    //console.log("callingApi ,call serch api");
    props.callApi({
      operation: "/item_suggest",
      caller_ref: "SS.getSeachSuggestions",
      params: {
        bid: props._GLOBAL.business.bid,
        buname: "",
        sid: props._GLOBAL.business.sid,
        q: query,
      },
    });
  };
  // const [itemNotFound,setItemsNotFound]=useState()

  const getItemSearch = (e,query) => {
    //console.log("callingApi ,call serch item api");
e.preventDefault()
    props.callApi({
      operation: "/item_search",
      caller_ref: "SS.getItemSearch",
      params: {
        bid: props._GLOBAL.business.bid,
        buname: "",
        sid: props._GLOBAL.business.sid,
        q: query,
      },
    });
    handleCloseSearch();
    // let searchResult = document.querySelector("#search-result");
    // if(props._SG.suggest?.length<=0){
    //   setItemsNotFound("none")
    //   searchResult.style.display = "block";
    // }
   if(props._CS?.col_status === 1){
    history.push('/store/:id/search/:id')
   }
   else{
    history.push('/search/:id')
   }
  };

  useEffect(() => {
    let form = document.querySelector("#forms");
    let searchResult = document.querySelector("#search-result");

    document.addEventListener("click", (evt) => {
      let target = evt.target;

      if (target.classList.contains("input-s")) {
        form.style.border = "3px solid var(--color_lightpurple)";
        if (document.querySelector("#d-navbar")) {
          document.querySelector("#d-navbar").classList.add("inputBackDark");
        } else if (document.querySelector("#navbar")) {
          document.querySelector("#navbar").classList.add("inputBackDark");
        }
      } else {
        form.style.border = "3px solid transparent";
        if (document.querySelector(".inputBackDark")) {
          if (document.querySelector("#d-navbar")) {
            document
              .querySelector("#d-navbar")
              .classList.remove("inputBackDark");
          } else if (document.querySelector("#navbar")) {
            document.querySelector("#navbar").classList.remove("inputBackDark");
          }
        } else {
          return null;
        }
      }
    },{passive: true});

    document.addEventListener("click", (evt) => {
      let target = evt.target;
      if (
        target.classList.contains("input-s") ||
        target.classList.contains("searchPopupSubNav")
      ) {
        searchResult.style.display = "block";
      } else {
        searchResult.style.display = "none";
      }
    },{passive: true});
    let inputValue = document.querySelector("#input-s").value;
    let clearBtnOfInput = document.querySelector(".clearPopupSearch");
    if (inputValue !== "") {
      clearBtnOfInput.classList.remove("d-md-none");
    } else {
      clearBtnOfInput.classList.add("d-md-none");
    }
  });
  let seachSuggestions;
  if (props._SG.suggest !== undefined) {
    seachSuggestions = props._SG.suggest.map((sg, i) => {
      return (
        <Link
          className="search-item dropdown-item px-2 position-relative py-0 text-left  "
          to={
            props._CS?.col_status === 1
              ? `/store/${
                  props._GLOBAL.business.buname
                }/search/${encodeURIComponent(sg)}`
              : `/search/${encodeURIComponent(sg)}`
          }
          style={{ textDecoration: "none" }}
        >
            {sg}
            {/* <i className="fas fa-check position-relative"/> */}
        </Link>
      );
    });
  }
  
  return (
    <div>
      <form action="" onSubmit={(e)=>{getItemSearch(e,Input)}} className="forms p-0 position-relative" id="forms">
        <input
          type="text"
          id="input-s"
          className="input-s col-11"
          placeholder={`Search ${props._GLOBAL.business?.bname}`}
          onChange={(e) => {
            getSeachSuggestions(e.target.value);
            setInput(e.target.value)
          }}
          autoComplete="off"
          href="#"
          data-toggle="false"
          aria-autocomplete="both"
        />

        <button className="btn-s col-1 py-2">
          <i className="fas fa-search" onClick={openSearchForm}></i>
        </button>

        <div
          className="dropdown-menu search-result position-absolute w-100 p-0"
          style={{ background: "transparent", border: "none"}}
          id="search-result"
        >
          <div
            className="w-100 bg-white m-auto"
            style={{ boxShadow: "inset 0px 0px 0px 1px #DEE2E6"}}
          >
            {/* {console.log(seachSuggestions,'cocoooooooo')} */}
            {props._SG.suggest?.length > 0 ? seachSuggestions : Input.length > 0 ?<p className='dropdown-item'>Items not found</p>:null}
          </div>
        </div>
        <Clear
          fontSize="small"
          className="clearPopupSearch mr-1 d-md-none mt-1 d-block"
          onClick={handleSearchPopup}
        />
      </form>
    </div>
  );
}
const mapStateToProps = (state) => {
  //console.log(state);
  return {
    _SR: state.item.SR,
    _SG: state.item.SG,
    _GLOBAL: state.item.GLOBAL,
    _CART: state.item.CART,
    _CS: state.item.CS,
  };
};

export default connect(mapStateToProps, {
  callApi,
})(SS);
