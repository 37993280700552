import CategoriesNav from './CategoriesNav/CategoreisNav'
import Categories from './Categories/Categories'
import Nav from "../CP0/nav";
import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { callApi } from "../../actions/index";
import LoadingBar from "react-top-loading-bar";

import "./CP4.css";
//

function CP4(props) {
  const ref = useRef(null);
  const barColor = "#f11946";
  
  useEffect(() => {
    ref.current?.continuousStart();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // window.scrollTo(0, `${props._SCROLL}`)
    var btnAdd = document.querySelectorAll(".count1");

    const handleClick = (e) => {

      e.preventDefault();
      btnAdd.forEach((btn) => {
        console.log(btn.parentElement.parentElement.nextElementSibling, "hhhhhh")
        btn.parentElement.parentElement.nextElementSibling.classList.remove(
          "toggle-class"
        );

      });
      e.currentTarget.parentElement.parentElement.nextElementSibling.classList.add(
        "toggle-class"
      );
    };
    btnAdd.forEach((btn) => {
      btn.addEventListener("click", handleClick);
    });

    document.addEventListener("click", (evt) => {
      let targetEl = evt.target; // clicked element

      if (
        targetEl.classList.contains("addPlus") ||
        targetEl.classList.contains("card-add-qunt") ||
        targetEl.classList.contains("focus")
      ) {
        return null;
      } else {
        var btnAdd = document.querySelectorAll(".card-add-qunt");
        btnAdd.forEach((btn) => {
          btn.classList.remove("toggle-class");
        });
      }
    },{passive: true});
  });

  // function getQtyFromCart(pid, sid) {
  //   // console.log('getQtyFromCart.............')
  //   let mitem = null;
  //   let store = props._CART.scarts.find((item) => {
  //     return sid === item.sid;
  //   });
  //   if (store) {
  //     mitem = store.product.find((item) => {
  //       return pid === item.pid;
  //     });
  //   }
  //   if (mitem && mitem && mitem.qty) {
  //     return mitem.qty;
  //   } else {
  //     return 0;
  //   }
  // }

  // function getQtyFromCart2(pid, sid) {
  //   // console.log('getQtyFromCart.............')
  //   let mitem = null;
  //   let store = props._CART.scarts.find((item) => {
  //     return sid === item.sid;
  //   });
  //   if (store) {
  //     mitem = store.product.find((item) => {
  //       return pid === item.pid;
  //     });
  //   }
  //   if (mitem && mitem.qty) {
  //     return <span className="count1">{mitem.qty}</span>
  //   } else {
  //     return <i className="fa fa-plus count0"></i>;
  //   }
  // }

  // const getPDData = (_buname, _cid, _pid) => {
  //   let _s_pid = "";
  //   if (props._PD && props._PD.product) {
  //     _s_pid = props._PD.product.filter((item) => {
  //       //console.log(item.id, _pid);
  //       return item.id === _pid;
  //     });
  //     //console.log(_s_pid.length, _pid);
  //   }

  //   //some times we are having old PD data, so above check make sure we have clicked PD data
  //   // if PD data doesn't exist, then we load PD for that pid
  //   if (_s_pid.length === 0) {
  //     //console.log("is it calling api?");
  //     props.callApi({
  //       operation: "/products_details",
  //       caller_ref: "CAROUSEL.getPDData",
  //       params: {
  //         bid: props.bid,
  //         sid: props.sid,
  //         pid: _pid,
  //         catid: _cid,
  //       },
  //     });
  //   }
  // };

  // const { isAuthenticated } = props._auth;
  if (props._CP4 === undefined || props._CP4.display_template === undefined) {
    //return null;
    //    return <Loader />;
    return <LoadingBar color={barColor} ref={ref} />
  } else {
    const CP4_category = props._CP4.category.filter((item, i) => {
      return item.product.length > 0;
    });
    const CP4_categoryFilter = CP4_category.map((item, i) => {
      return (
        <Categories
          item={item}
          uris={props._CP4.uris}
          bid={props._CP4.business.bid}
          sid={props._CP4.business.sid}
          buname={props._CP4.business.buname}
          _CS={props._CS.col_status}
        />
      );
    });

    return (
      <div className="p-0">
        <Nav />
        <CategoriesNav CP4_category={CP4_category}/>
        <div className='px-md-2' style={{background:"rgb(224, 224, 224)"}}>
             <div className="container-fluid" id="CP4">
          {CP4_categoryFilter}
        </div>
         </div>
      </div>
    );
  }


}
const mapStateToProps = (state) => {
    //console.log("CP0 state change")
    return {
    _auth: state.auth,
      _CP4: state.item.CP4,
      _CART: state.item.CART,
      _LOC: state.item.LOC,
      _CS: state.item.CS,
    };
  };
  export default connect(mapStateToProps, {
    callApi,
  })(CP4);

