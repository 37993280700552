import React, { useEffect,useState } from "react";
// import { Link } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
// import "./Info.css";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function PdDetail(props) {
  // const classes = useStyles();
  // console.log(props.ptData, props.PD, "ptData #####");
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [tabRender,setTabRender]=useState([])
  useEffect(()=>{
    // console.log("############ 1 ",props.ptData)
    const tabs=props.ptData.filter((tb)=>{
      const tab=tb.fields.filter((f)=>{
       return (props.PD.product[0][f.f_alias])
      })
      // console.log(tab,"tab")
      return tab.length!==0
    })
    // console.log(tabs,'tab')
    setTabRender(tabs)
  },[props.ptData,props.PD])

  // function handlePopup() {
  //   window.history.back(); 
  // }

  return (
  <div>
  {tabRender.length!==0?
        <div
          className={props.popup === false? 
            "container-fluid item-detail-page col-12 text-dark text-center m-auto d-block bg-white py-4 px-1 px-sm-4":
            
          "container item-detail-page col-11 mt-n1 text-dark text-center mx-auto d-block bg-white py-4 px-1 px-sm-2"}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.700)",
            overflow: "scroll",
            overflowX: "hidden",
            borderBottom:"2px solid #F0F0F0"
          }}
        >
          <div className="border-bottom">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              className="col-12 m-auto d-flex justify-content-center p-0 pl-md-2"
            >
              {tabRender?.map((tb, i) => (
                <Tab
                label={tb.f_group}
                {...a11yProps(i)}
                style={{ outline: "none" }}
              />
              ))}
            </Tabs>
          </div>
          {/* {console.log(props.ptData,props.PD?.product[0],'checkTabBtns')} */}
          {tabRender?.map((tb, i) => (
            <TabPanel value={value} index={i} className="row">
              <div className="pt-table d-flex flex-wrap text-center">
                {tb.fields?.map((f) => (
                  (props.PD.product[0][f.f_alias])?<div className="pt-table-box d-flex">
                    <div className="pt-sub-box name col-5">{f.f_name}</div>
                    <div className="pt-sub-box  value col-7">
                      {props.PD?.product[0][f.f_alias]}
                    </div>
                  </div>:null
                ))}
              </div>
            </TabPanel>
          ))}
        </div>
:null}</div>
  );
}

const mapStateToProps = (state) => {
  return {
    _GLOBAL: state.item.GLOBAL,
    _BZ: state.item.BZ,
  };
};
export default connect(mapStateToProps)(PdDetail);
