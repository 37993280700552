import "./UE.css";
// import { LazyLoadImage } from "react-lazy-load-image-component";

function UE(props) {
  return (
    <div>
      {" "}
      <BS5 />
      {/* <Card />{" "} */}
    </div>
  );
}

// function Card(props) {
//   return (
//     <div id="UEcard">
//       <div id="left">
//         <div id="info"> Get a Popeyes Rip'n Veg BigBox on us with</div>
//         <div id="info"> Enjoy through 1/31*</div>
//         <div id="buttons">
//           {" "}
//           <button></button> Order Now{" "}
//         </div>{" "}
//       </div>{" "}
//       <div id="right" col-xxl-1>
//         <LazyLoadImage
//           src="https://d1g1f25tn8m2e6.cloudfront.net/171f9e34-5df1-4720-85a7-8d6a38f8435c.jpg"
//           alt="Logo"
//           loading="lazy"
//         />
//       </div>{" "}
//     </div>
//   );
// }

function BS5(props) {
  return (
    <div className="container-container-fluid">
      <div className="row">1</div>

      <div className="col-sm"></div>

      <div className="container-sm">100% wide until small breakpoint</div>
      <div className="container-md">100% wide until medium breakpoint</div>
      <div className="container-lg">100% wide until large breakpoint</div>
      <div className="container-xl">100% wide until extra large breakpoint</div>
      <div className="container-xxl">
        100% wide until extra extra large breakpoint
      </div>

      <div className="container">
        <div className="row">
          <div className="col-sm border">One of three columns</div>
          <div className="col-sm border">One of three columns</div>
          <div className="col-sm border">One of three columns</div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col">1 of 3</div>
          <div className="col-6">2 of 3 (wider)</div>
          <div className="col">3 of 3</div>
        </div>
        <div className="row">
          <div className="col">1 of 3</div>
          <div className="col-5">2 of 3 (wider)</div>
          <div className="col">3 of 3</div>
        </div>
      </div>
    </div>
  );
}

export default UE;
