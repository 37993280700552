import getDataReducer from './getDataReducer';
import {combineReducers} from 'redux';
import errorReducer from './errorReducer';
import authReducer from './authReducer';

const rootReducers = combineReducers({
	item:getDataReducer,
	errors: errorReducer,
    auth: authReducer
})

export default rootReducers;

