let initialState = {
  isLoading: false,
  error: null,
  Scroll: "",
  BZ: {},
  BZPopupDT: "pick-store",
  SS: {},
  DB: {},
  DBs: [],
  CP0: {},
  CP1: {},
  CP2: {},
  CP3: {},
  CP4: {},
  PDs: [],
  PT: {},
  ZR: [],
  AL: [],
  GL: {},
  SG: [],
  SR: [],
  OS: [],
  BC: {},
  OL: {},
  OD: {},
  CS: {},
  LOC: {},
  CAR: "",
  CLICKED_INDEX: "",
  CLICK: {},
  GLOBAL: {},
  CART: {
    scarts: [],
    summary: {
      qty: 0,
      amount: 0,
    },
  },
  LA: {},
  LC: {},
  RESPONSE: {},
  PAPopup: "account",
  PREF: { debug: false, prod: true },
};

function getGlobalByActionType(state = initialState, action) {
  let _global = JSON.parse(localStorage.getItem("global"));
  if (!_global) {
    //console.log("Global not in local storage in getGlobalWithZip");
    _global = state.GLOBAL;
  }

  switch (action.type) {
    case "fulfilment":
      _global.FULFILMENT = action.payload;
      break;

    case "fulfilment_aid":
      _global.FULFILMENT.aid = action.payload;
      break;

    default:
    //console.log("getGlobalByActionType");
  }

  localStorage.setItem("global", JSON.stringify(_global));
  return _global;
}

function getGlobalWithMetaData(state = initialState, action) {
  let _global = JSON.parse(localStorage.getItem("global"));
  if (_global) {
    _global.url = window.location.href;
    _global.display_template = action.payload.display_template;
    _global.business = action.payload.business;
    _global.uris = action.payload.uris;

    let dt = action.payload.display_template;

    if (
      dt === "cp1" ||
      dt === "cp2" ||
      dt === "cp3" ||
      dt === "cp0" ||
      dt === "cp4"
    ) {
      _global.cp_display_template = action.payload.display_template;
      _global.cp_display_path = action.payload.cp_display_path;
    } else if (state.GLOBAL && state.GLOBAL.cp_display_template) {
      _global.cp_display_template = state.GLOBAL.cp_display_template;
      _global.cp_display_path = state.GLOBAL.cp_display_path;
    }
  } else {
    //console.log("Global not in local storage");
    _global = {
      display_template: "",
      cp_display_template: "",
      cp_display_path: "",
      business: "",
      url: "",
    };

    let dt = action.payload.display_template;
    _global.url = window.location.href;
    _global.display_template = action.payload.display_template;
    _global.business = action.payload.business;
    _global.uris = action.payload.uris;

    if (
      dt === "cp1" ||
      dt === "cp2" ||
      dt === "cp3" ||
      dt === "cp0" ||
      dt === "cp4"
    ) {
      _global.cp_display_template = action.payload.display_template;
      _global.cp_display_path = action.payload.cp_display_path;
    } else if (state.GLOBAL && state.GLOBAL.cp_display_template) {
      _global.cp_display_template = state.GLOBAL.cp_display_template;
    }
  }
  localStorage.setItem("global", JSON.stringify(_global));
  return _global;
}

const getDataReducer = (state = initialState, action) => {
  //console.log("action.type:", action.type, "\naction:", action);
  switch (action.type) {
    case "cs":
      localStorage.setItem("CS", JSON.stringify(action.payload.cs));
      // console.log("@@@@@@",action.payload.cs)
      return {
        ...state,
        CS: action.payload.cs,
      };
    case "bz":
      //console.log("getDataReducer.case:bz");
      //return { ...state, BZ: action.payload, GLOBAL: getGlobalWithMetaData(state, action) };
      //Global is not updated when BZ is loaded because we want to preserve previous Global data for back functionality to work
      //if (action.payload.businesses.length !== 0) {
      //console.log("######## getting BZ data",action.payload?.address);

      if (action.payload?.geodata) {
        let _LOC = {
          address: "",
          location: {},
          rad: 1,
        };
        action.payload.query.params.address = action.payload?.geodata?.address;
        _LOC.address = action.payload?.geodata?.address;
        _LOC.location = action.payload?.geodata?.geometry?.location;
        _LOC.rad = action.payload?.query?.params?.rad;
        localStorage.setItem("LOC", JSON.stringify(_LOC));
        //console.log('@@@@@ 1',_LOC)
        return {
          ...state,
          BZ: action.payload,
          LOC: _LOC,
        };
      } else {
        return {
          ...state,
          BZ: action.payload,
          //GLOBAL: getGlobalWithMetaData(state, action),
        };
      }

    case "ss":
      return {
        ...state,
        SS: action.payload,
        //        GLOBAL: getGlobalByActionType(state, action),
      };

    case "db":
      //console.log("getDataReducer.case:db");
      return {
        ...state,
        DB: action.payload,
        GLOBAL: getGlobalWithMetaData(state, action),
      };
    case "cp0":
      //      console.log("########## sk:",action.payload.query.params.scroll_key,'csk:',action.payload.cat_scroll_key)
      if (action.payload.cat_scroll_key) {
        if (!action.payload.query.params.scroll_key) {
          return {
            ...state,
            CP0: action.payload,
            GLOBAL: getGlobalWithMetaData(state, action),
          };
        } else {
          //append on scroll
          return {
            ...state,
            CP0: {
              ...state.CP0,
              category: state.CP0.category.concat(action.payload.category),
              cat_scroll_key: action.payload.cat_scroll_key,
            },
            GLOBAL: getGlobalWithMetaData(state, action),
          };
        }
      } else {
        return {
          ...state,
          CP0: {
            ...state.CP0,
            cat_scroll_key: undefined,
          },
          GLOBAL: getGlobalWithMetaData(state, action),
        };
      }

    case "cp4":
      //console.log("????????????? getDataReducer.case:cp0");
      return {
        ...state,
        CP4: action.payload,
        GLOBAL: getGlobalWithMetaData(state, action),
      };

    case "cp1":
      //console.log("getDataReducer.case:cp1");
      return {
        ...state,
        CP1: action.payload,
        GLOBAL: getGlobalWithMetaData(state, action),
      };
    case "cp2":
      return {
        ...state,
        CP2: action.payload,
        GLOBAL: getGlobalWithMetaData(state, action),
      };
    case "cp3":
      //console.log("getDataReducer.case:cp3");
      return {
        ...state,
        CP3: action.payload,
        GLOBAL: getGlobalWithMetaData(state, action),
      };
    case "pd":
      //console.log("getDataReducer.case:pd");
      //    //console.log("what is action.payload???", action.payload);
      if (action.payload.source && action.payload.source === "cache") {
        //console.log("not saving PD to PDs", action.payload);
        return {
          ...state,
          //PD: action.payload,
          GLOBAL: getGlobalWithMetaData(state, action),
        };
      } else {
        //console.log("saving PD to PDs", state.PDs, action.payload);
        return {
          ...state,
          //PD: action.payload,
          PDs: [...state.PDs, action.payload],
          GLOBAL: getGlobalWithMetaData(state, action),
        };
      }
    case "pt":
      let _PT = { ...state.PT };
      Object.keys(action.payload.pt_value).forEach((ekey) => {
        _PT[ekey] = action.payload.pt_value[ekey];
      });
      // console.log("#######_PT:",_PT)
      return Object.assign({}, state, {
        PT: _PT,
      });
    case "pts":
      // console.log("#######__ __PT:",action.payload.pts_value)
      return {
        ...state,
        PT: action.payload.pts_value,
      }

    case "bc":
      return {
        ...state,
        BC: action.payload,
      };

    case "global":
      return {
        ...state,
        GLOBAL: action.payload,
      };

    case "GC":
      return {
        ...state,
        CART: action.payload,
      };

    case "ssGC":
      //console.log("saving state in ssGC", action.payload)
      return {
        ...state,
        SS: action.payload,
        CART: action.payload,
      };

    case "olGC":
      console.log("saving state in olGC", action.payload);
      return {
        ...state,
        OL: action.payload.ol,
        CART: action.payload.gc,
      };

    case "OL":
      //console.log("saving state in OL", action.payload)
      return {
        ...state,
        OL: action.payload,
      };

    case "OD":
      //console.log("saving state in OD", action.payload)
      return {
        ...state,
        OD: action.payload,
      };

    case "click":
      ////console.log("click", action.payload);
      return {
        ...state,
        CLICK: action.payload,
      };
    case "SG":
      //console.log(action.payload, "search suggestions");
      return {
        ...state,
        SG: action.payload,
      };
    case "SR":
      //console.log(action.payload, "search item");
      return {
        ...state,
        SR: action.payload,
        GLOBAL: getGlobalWithMetaData(state, action),
      };
    case "zs":
      //console.log(action.payload, "zs result");
      return {
        ...state,
        ZR: action.payload,
      };
    case "AL":
      return {
        ...state,
        AL: action.payload,
      };
    case "GL":
      //console.log("In getDataReducer GL")
      return {
        ...state,
        GL: action.payload,
      };
    case "OS":
      //console.log(action.payload, "OS result");
      return {
        ...state,
        OS: action.payload,
      };
    case "clicked_account_route":
      //console.log("$$$$$$$$$action.payload:", action.payload);
      return {
        ...state,
        CAR: action.payload,
      };

    case "clicked_index":
      return {
        ...state,
        CLICKED_INDEX: action.payload,
      };

    case "fulfilment_aid":
      return {
        ...state,
        GLOBAL: getGlobalByActionType(state, action),
      };
    case "LA":
      /*return {
        ...state,
        ACCOUNT: {...state.ACCOUNT, ADDRESS: action.payload.address} ,
      };*/
      return {
        ...state,
        LA: action.payload,
        RESPONSE: action.payload.response,
      };
    case "LC":
      return {
        ...state,
        LC: action.payload,
        RESPONSE: action.payload.response,
      };
    case "bzpopup-dtype":
      return {
        ...state,
        BZPopupDT: action.payload,
      };
    case "pm-popup":
      return {
        ...state,
        PAPopup: action.payload,
      };
    case "type":
      let _PREF = JSON.parse(localStorage.getItem("PREF"));
      if (action.payload === "sync") {
        if(_PREF === undefined) {
          return state
        }
      } else {
        if(_PREF === undefined || _PREF === null) {
          _PREF = { ...state.PREF };        
        }
        _PREF[action.payload] = !_PREF[action.payload];
        // console.log("_PREF:", action.payload, _PREF, _PREF[action.payload] );
        localStorage.setItem("PREF", JSON.stringify(_PREF));

      }
      return Object.assign({}, state, {
        PREF: _PREF,
      });
    case "reset_data":
      return {
        ...state,
        LA: {},
        LC: {},
        CART: {
          scarts: [],
          summary: {
            qty: 0,
            amount: 0,
          },
        },
      };

    case "set_location":
      //console.log('@@@@@@ Setting location', action.payload)
      localStorage.setItem("LOC", JSON.stringify(action.payload));
      //console.log('@@@@@ 2',action.payload)
      return {
        ...state,
        LOC: action.payload,
      };

    case "sync_location":
      if (
        !state.LOC.address ||
        state.LOC.address === "" ||
        state.LOC.address === undefined
      ) {
        let _LOC = JSON.parse(localStorage.getItem("LOC"));
        //state.LOC is not present or uptodate
        //console.log('####### 1');
        if (_LOC) {
          //check if localStorage has object
          //console.log('####### 2',_LOC);
          return {
            //copy localStorage to state.LOC
            ...state,
            LOC: _LOC,
          };
        } else {
          //state returned with no changes
          //console.log('####### 3');
          return state;
        }
      } else {
        //state.LOC is current than localStorage
        localStorage.setItem("LOC", JSON.stringify(state.LOC));
        //console.log('@@@@@ 3',state.LOC)
        return state;
      }

    default:
      //console.log("default in getDataReducer");
      return state;
  }
};
export default getDataReducer;
