import React, { useState } from "react";
import { connect } from "react-redux";
import { callApi } from "../../actions/index";
const Calculator = (props) => {
  // first cal
  const [enterSqFt, setEnterSqFt] = useState("");
  const SqFt = props.obj.conro; //useState(props.obj.conro)
  const [box, setBox] = useState("0");
  const price = props.obj.lp; //useState(props.obj.lp)
  const [newprice, setnewPrice] = useState(props.obj.lp);

  const calculateSqFt1 = (sqft) => {
    setEnterSqFt(sqft);
    setBox(Math.ceil(Number(sqft) / Number(SqFt)));
    setnewPrice(Math.ceil(Number(sqft) / Number(SqFt)) * Number(price));
  };
  // first cal

  // second cal
  const [enterSqFt2, setEnterSqFt2] = useState("");
  const [box2, setBox2] = useState("0");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [price2, setPrice2] = useState(props.obj.lp);

  const calculateSqFt2 = (e, obj) => {
    const length_ = document.querySelector("#length")?.value;
    const width_ = document.querySelector("#width")?.value;
    setEnterSqFt2(length_ * width_);
    console.log(Math.ceil((length_ * width_) / Number(SqFt)), "NANNAN");
    let calCol = Math.ceil((length_ * width_) / Number(SqFt));
    setBox2(typeof isNaN(calCol) ? "0" : calCol);
    setPrice2(Math.ceil((length_ * width_) / Number(SqFt)) * Number(price));
  };

  const submitCal2 = (e, obj) => {
    setBox(box2);
    setEnterSqFt(enterSqFt2);
    setnewPrice(price2);
    closeCalTow()
  };

  // second cal
  const openCalTow = () => {
    document.querySelector(".calculate2").classList.remove("d-none");
  };
  const closeCalTow = () => {
    document.querySelector(".calculate2").classList.add("d-none");
  };

  const boxQtyIncrease = () => {
    setBox(Number(box) + 1);
    setEnterSqFt((Number(box) + 1) * Number(props.obj.conro));
    setnewPrice((Number(box) + 1) * Number(props.obj.conro) * props.obj.lp);
  };
  const boxQtyDecrease = () => {
    setBox(Number(box) - 1);
    setEnterSqFt((Number(box) - 1) * Number(props.obj.conro));
    setnewPrice((Number(box) - 1) * Number(props.obj.conro) * props.obj.lp);
  };
  // ----------update-qaty and select-qty---------

  const [Qty, UpdateQty] = useState(1);

  function updateQuantity() {
    UpdateQty(box);
    // console.log(box,"Qty")
  }
  const { isAuthenticated } = props._auth;
  return (
    <div className="position-relative">
      <div className="calculate1">
        <h6 className="small" style={{ textAlign: "left", marginTop: "10px" }}>
          HOW MUCH DO YOU NEED?
        </h6>
        <div
          className="py-4 px-2 px-sm-4 d-flex flex-column justify-content-center"
          style={{ width: "100%", height: "15vh", background: "#EEEEEE" }}
        >
          <div className="d-flex align-items-center">
            <button
              onClick={openCalTow}
              className="button bg-white d-flex col-md-7 col-sm-6 col-8 justify-content-between align-items-center px-4"
              style={{ height: "7vh" }}
            >
              <span>
                <i class="fas fa-calculator" style={{ fontSize: "1.7em" }}></i>
              </span>
              <small
                className="cal-btn"
                style={{ color: "#e6003d", padding: "0 10px" }}
              >
                <b>CALCULATE MY SQUARE FOOTAGE</b>
              </small>
            </button>
            <h6 className="col-sm-2"> OR </h6>
            <input
              className="col-md-3  col-sm-4 col-3 text-center"
              type="text"
              placeholder={"Enter " + props.obj.conu}
              onChange={(e) => {
                calculateSqFt1(e.target.value);
              }}
              value={enterSqFt}
              style={{ height: "7vh", fontSize: ".75em" }}
            />
          </div>
          <div className="d-flex justify-content-left align-items-center pt-2">
            <input type="checkbox" name="" id="" />
            &nbsp; &nbsp;
            <small>Add {props.obj.cngcy} fro contingency (recomended) !</small>
          </div>
        </div>
        <h6 className="small" style={{ textAlign: "left", marginTop: "10px" }}>
          QUANTITY OF{" "}
          {props.obj.uts === "box"
            ? "BOXES"
            : props.obj.uts === "piece"
            ? "PIECES"
            : props.obj.uts}
        </h6>
        <div
          className=" d-flex flex-column justify-content-center"
          style={{ width: "100%", height: "10vh" }}
        >
          <div className="d-flex align-items-center">
            <div
              className="button bg-white calculate d-flex col-5 justify-content-between align-items-center border"
              style={{ height: "fitContent" }}
            >
              <button
                className="p-2 px-3"
                style={{ border: "none" }}
                onClick={boxQtyDecrease}
              >
                -
              </button>
              <input
                className="w-75 p-2 text-center"
                style={{ border: "none" }}
                type="text"
                value={box}
              />
              <button
                className="p-2 px-3"
                style={{ border: "none" }}
                onClick={boxQtyIncrease}
              >
                +
              </button>
            </div>
            <div className="col-5 d-flex flex-column">
              <small>
                {props.obj.condsc} :{" "}
                <span>{typeof isNaN(enterSqFt) ? "0" : enterSqFt}</span>
              </small>
              <small>
                <b>
                  <span>Subtotal:</span>
                  <span style={{ color: "#e6003d" }}>
                    ${typeof isNaN(newprice) ? "0" : newprice}
                  </span>
                </b>
              </small>
            </div>
          </div>
          <div className="d-flex justify-content-left align-items-center pt-2">
            <small style={{ textTransform: "capitalize" }}>
              1 {props.obj.uts} ={" "}
              <span>
                {SqFt} {props.obj.conu}
              </span>{" "}
              | <span>${price}</span>
            </small>
          </div>
        </div>
        <button
          onClick={(e, i) => {
            if (isAuthenticated) {
              props.callApi({
                operation: "/save_item_to_cart",
                caller_ref: "PD.render2",
                params: {
                  bid: props.__PD.business.bid,
                  sid: props.__PD.business.sid,
                  pid: `${props.obj.id}`,
                  qty: Qty === undefined ? 1 : Qty,
                },
              });
            }
            updateQuantity();
          }}
          className="w-100 btn ms-lg-0 ms-2 mt-3"
          style={{
            border: "1px solid #3E3665",
            background: "#3E3665",
            borderRadius: "3px",
            color: "var(--color_white)",
          }}
          type="button"
        >
          <h6 className="mt-1 ">
            {/* {getQtyFromCart(obj?.id, __PD.business.sid) === 0
                              ? "Add to cart"
                              : "Update cart"} */}
            Add To Cart
          </h6>
        </button>
      </div>
      {/* calculate2 */}
      <div
        className="calculate2 position-absolute p-3 d-none"
        style={{
          top: "-3.2vh",
          left: "0",
          width: "100%",
          height: "21vh",
          background: "#EEEEEE",
        }}
      >
        <div className="d-flex m-auto align-items-center">
          <span>
            <i class="fas fa-calculator" style={{ fontSize: "1em" }}></i>
          </span>
          <div style={{ color: "#3E3665", fontSize: ".9em" }} className="ms-2">
            <b>PIECES CALCULATOR</b>
          </div>
        </div>
        <div className="px-4">
          <h6 className="small" style={{ textAlign: "left", marginTop: "5px" }}>
            What is your project size in feet?
          </h6>
          <div
            className="d-flex col-10 justify-content-center align-items-center"
            style={{ height: "5vh" }}
          >
            <input
              className="p-1 col-2 text-center"
              type="number"
              value={length}
              id="length"
              onChange={(e) => {
                calculateSqFt2();
                setLength(e.target.value);
              }}
            />
            <span className="col-2">x</span>
            <input
              className="col-2 p-1 text-center"
              type="number"
              value={width}
              id="width"
              onChange={(e) => {
                calculateSqFt2(e, e.target.value);
                setWidth(e.target.value);
              }}
            />
            <span className="col-1">
              <b>=</b>
            </span>
            <span className="col-2" style={{ color: "#e6003d" }}>
              <b>{enterSqFt2}</b>
            </span>{" "}
            |
            <span className="col-2" style={{ color: "#e6003d" }}>
              <b>{box2}</b>
            </span>
          </div>
          {/* afters */}
          <div
            className="d-flex col-10 justify-content-center p-0"
            style={{ fontSize: ".6em", color: "grey" }}
          >
            <span className="Le_after p-0 col-2 text-center">Length</span>
            <span className="col-2"></span>
            <span className="Ri_after col-2 p-0 text-center">Width</span>
            <span className="col-1"></span>
            <span className="Sq_after col-2">{props.obj.conu}</span>
            {/* {console.log(props.obj.uts,'props.obj.uts')} */}
            <span className="Pi_after col-2">
              {props.obj.uts === "box"
                ? "Boxes"
                : props.obj.uts === "piece"
                ? "Pieces"
                : props.obj.uts}
            </span>
          </div>
          <button
            className="text-white px-2 py-1 mt-1"
            style={{
              background: "#e6003d",
              fontSize: ".7em",
              position: "absolute",
              right: "10px",
              border: "none",
            }}
            onClick={submitCal2}
          >
            USE THIS AMMOUNT
          </button>
          <div
            className="position-absolute cal-cross"
            onClick={closeCalTow}
            style={{
              right: "15px",
              top: "10px",
              fontSize: "1.6em",
              cursor: "pointer",
            }}
          >
            x
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    _auth: state.auth,
  };
};
export default connect(mapStateToProps, {
  callApi,
})(Calculator);
