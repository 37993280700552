import React from "react";
import { Link } from "react-router-dom";
import Calculator from './Calculator'
import SimpleForm from './SimpleForm'
import { LazyLoadImage } from "react-lazy-load-image-component";


const RightCard = (props) => {

//   ------------------------------
    const handleVarientClick = (e, v_opt) => {
        if (v_opt.pid === undefined) {
          //console.log("varients-hover no pid");
          return null;
        } else {
          e.preventDefault();
          //console.log(v_opt.pid, __PD);
  
          let __prod1 = props.__PD.product.find((prod) => {
            return v_opt.pid === prod.id;
          });
          props.setObj(__prod1);
          props.setObjs(__prod1);
  
          //console.log(__PD);
          //console.log(e.target, v_opt, "varients-hover");
        }
      };


//   ------------------------------
    const handleHover = (e, v_opt) => {
        props.setItemDetailPage(null);
  
        if (v_opt.pid === undefined) {
          return null;
        } else {
          e.preventDefault();
          //console.log(v_opt.pid, __PD);
  
          let __prod2 = props.__PD.product.find((prod) => {
            return v_opt.pid === prod.id;
          });
          //console.log(__prod2?.imgs);
          const v_imgs = getSelectedVariantImages(__prod2);
          //console.log(v_imgs);
          if (!__prod2?.variantImgsInserted) {
            v_imgs.forEach((vi) => {
              __prod2.imgs.push(vi);
            });
            if (__prod2) {
              __prod2.variantImgsInserted = true;
            }
          }
          props.setObj(__prod2);
          //console.log(__PD);
          //console.log(e.target, v_opt, "varients-hover");
        }
      };
//   ------------------------------
      const handleOut = (e) => {
        e.preventDefault();
        // props.setObj(_objs);
        //console.log("varients-hover-out");
      };
//   -----------------------------

const getSelectedVariantImages = (prod) => {
    let v_imgs = [];
    //console.log(__PD?.product[0], prod);
    if (prod?.vars) {
      prod.vars.forEach((v) => {
        v.opts.forEach((o) => {
          if (o.dsp === 2 && o.imgs.length > 0) {
            o.imgs.forEach((i) => {
              v_imgs.push(i);
            });
          }
        });
      });
    }
    return v_imgs;
  };
//   ------------------------

const getVariantOptionProduct = (pid) => {
    let vo_p = null;
    vo_p = props.__PD.product.find((pr) => {
      return pr.id === pid;
    });
    //console.log(pid, vo_p)
    return vo_p;
  };

        // --------------------varients rendering start

        let _var = null;
        if (props.obj && props.obj?.vars) {
          _var = props.obj?.vars.map((v, i) => {
            let select;
            let opt = v.opts.map((o, i) => {
              return (
                <Link
                  style={{ color: "var(--color_purple)" }}
                  to={
                    props._CS.col_status === 1
                      ? `/store/${props._PD?.business.buname}/item/${o.pid}`
                      : `/item/${o.pid}`
                  }
                >
                  <div
                    onClick={(e, i) => {
                      handleVarientClick(e, o);
                    }}
                    onMouseOver={(e) => handleHover(e, o)}
                    onMouseOut={(e) => handleOut(e)}
                    className={
                      o.dsp === 2
                        ? "boxes selected mr-0 mt-2 onHover_f"
                        : o.dsp === 0
                        ? "boxes not-available mr-0 mt-2 onHover_f"
                        : o.dsp === 1
                        ? "boxes available mr-0 mt-2 onHover_f"
                        : "hide"
                    }
                  >
                    {v.include?.length === 0 ? (
                      <span className="onHover_f ">{o.opt}</span>
                    ) : (
                      (() => {
                        let v_p = getVariantOptionProduct(o.pid);
                        return (
                          <span className="onHover_f d-flex p-0 ">
                            {v.include.includes("image") === true ? (
                              <LazyLoadImage
                                className="img-fluid"
                                loading="lazy"
                                src={
                                  props.__PD.uris[v_p?.imgs[0]?.uri] + v_p?.imgs[0]?.src
                                }
                                alt=""
                              />
                            ) : null}
                            <div className="var-price d-flex flex-column ms-2 mt-2 ">
                              <small style={{ lineHeight: ".7em" }}> {o.opt}</small>
                              {v.include.includes("price") === true ? (
                                <small
                                  style={{ fontSize: ".7em" }}
                                  className="d-flex"
                                >
                                  $&nbsp;{v_p?.lp}
                                </small>
                              ) : null}
                            </div>
                          </span>
                        );
                      })()
                    )}
                  </div>
                </Link>
              );
            });
            select = v.opts.find((_o, i) => {
              return _o.dsp === 2;
            });
            return (
              <div>
                <div style={{ height: "15px" }} className="d-flex">
                  <span className="varients-heading">
                    <h6>{v.name}:</h6>
                  </span>{" "}
                  &nbsp;<h6>{select?.opt}</h6>
                </div>
                <div className="d-flex flex-wrap">{opt}</div>
              </div>
            );
          });
        }
        
    return (
                    
                       <div
                       className={
                         props.popup === false
                           ? "col-lg-6 col-md-7 px-sm-3 text-left pr-4 order-lg-3 order-2 px-lg-0 px-md-3"
                           : "col-lg-6 col-md-7 px-sm-3 text-left pr-4 order-lg-3 order-2 px-lg-0 px-md-3"
                       }
                     >
                       <h3 className="title-s d-flex">{props.obj?.title}</h3>
                     <div className="small" style={{color:"#C2C2C2",textAlign:"left"}}>
                       {props.obj.siz? `Size: ${props.obj.siz}`:"null"}
                       {props.obj.sku? `| SKU: ${props.obj.sku}`:"null"}
                       </div>
                       <div className="d-flex m-auto">
                         <h4 style={{ color: "#3E3665" }}>
                           $ {props.obj.lp}
                           <small style={{ fontSize: "0.8rem" }}>/{props.obj.conu}</small>
                         </h4>
                       </div>
                    <div className="varients ps-3 pb-2">
                      {_var !== null ? _var : null}
                    </div>
                       <div className="small" style={{textAlign:"left",fontSize:".8em",marginTop:"-10px"}}>Covers 11.11 sq.ft.</div>         
                          
                           {/* forms type */}
                          {props.obj.calty === "Unit calculator"?
                        <Calculator __PD={props.__PD} obj={props.obj}/>:
                          props.obj.calty === "None"?
                          <SimpleForm  __PD={props.__PD} obj={props.obj}/>:
                          <SimpleForm  __PD={props.__PD} obj={props.obj}/>
                      }
                          {/* forms type */}
                         
                       </div>
    )
}

export default RightCard
