import React, { useRef, useEffect } from "react";
import FilterBar from "../Filters/FilterBar";
import FilterPopup from "../Filters/FilterPopup";

import { connect } from "react-redux";
import { callApi } from "../../actions/index";
import DNav from "../Navs/nav";
import DSubnav from "../Navs/subnav";
import CPcard from "../CPcard/CPcard";
import FilterSelect from "../Filters/FilterSelect";
import LoadingBar from "react-top-loading-bar";

function SR(props) {
  const ref = useRef(null);
  const barColor = "#f11946";
  useEffect(() => {
    if (props._SR.query !== undefined) {
      if(props._SR.query.params.q!==":id"){
        document.querySelector("#input-s").value = props._SR.query.params.q;
      }
    }
  }, [props._SR]);

  useEffect(() => {
    ref.current?.continuousStart();
    let _bid = props._GLOBAL.business?.bid;
    let _sid = "";
    let _buname = window.location.href.split("/")[4];
    //let _query = window.location.href.split("/")[6]
    let _query = decodeURIComponent(window.location.href.split("/")[6]);
    //console.log("SR useEffect called");

    let _global = JSON.parse(localStorage.getItem("global"));
    if (_global && _global.business?.sid) {
      _bid = _global.business.bid;
      _sid = _global.business.sid;
    }
    props.callApi({
      operation: "/item_search",
      caller_ref: "SR.useEffect",
      params: {
        bid: _bid,
        buname: _buname,
        sid: _sid,
        q: _query,
      },
    });
  }, []); // eslint-disable-line

  if (props._SR === undefined || props._SR.display_template === undefined) {
    // return null;
    return <LoadingBar color={barColor} ref={ref} />
    //    return <Loader />;
  } else {
    const response = props._SR.products.map((subcat, index1) => {
      return (
        <CPcard
          subcat={subcat}
          cat={props._SR.cid}
          link={
            props._CS?.col_status === 1
              ? `/store/${props._GLOBAL.business?.buname}/item/${subcat.pid}`
              : `/item/${subcat.pid}`
          }
          img={props._SR.uris[subcat.pthumb?.uri] + subcat.pthumb?.src}
          bid={props._SR.business.bid}
          sid={props._SR.business.sid}
          buname={props._SR.business.buname}
        />
      );
    });

    return (
      <div>
        <DNav />
        <DSubnav />
        <div className="sub-category container-fluid px-md-3 px-0 pt-md-3 pt-5">
          <div
            className="row d-flex mx-1 justify-content-start mt-1"
          >
            <FilterBar />
            <div className="col-md-10 col-lg-10 col-12 col-xl-10 d-flex flex-column p-0 pl-2">
              <FilterSelect />
              <span
                className="d-block mt-md-0 mt-5"
                style={{ fontSize: ".9em" }}
              >
                {props._SR.products.length - 1===-1?<p style={{fontSize:"2em",textAlign:"center",marginTop:"20px"}}>No result found</p>:
                <span>{props._SR.products.length - 1} results for <b>{props._SR.query.params.q}</b></span>}
                </span>
              {/* mobile screen filters */}
              <FilterPopup />
              <div className="d-flex flex-wrap list-cards">{response}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  //console.log(state);
  return {
    _SR: state.item.SR,
    _GLOBAL: state.item.GLOBAL,
    _CART: state.item.CART,
    _CS: state.item.CS,
  };
};

export default connect(mapStateToProps, {
  callApi,
})(SR);
