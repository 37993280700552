import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setZipcode } from "../../actions/index";
import { callApi, setBZPopupDT } from "../../actions/index";
// import BC from '../../BC/BC'
import { openSearchForm, SstoreOpen, handleLoginO } from "../event_functions";
import SearchPage from "../Searchpage/searchPage";

function Subnav(props) {
  document.addEventListener("click", (evt) => {
    let targetEl = evt.target; // clicked element

    if (
      targetEl.classList.contains("zipcode") ||
      targetEl.classList.contains("zipcodeForm") ||
      targetEl.classList.contains("zipInput")
    ) {
      return null;
    } else {
      // handleCloses();
    }
  },{passive: true});

  const [_Business, setBusiness] = useState();
  //const { isAuthenticated, user } = props._auth;
  const { isAuthenticated } = props._auth;

  useEffect(() => {
    const _matched_scart = props._CART.scarts.find((scart) => {
      return scart.sid === props._GLOBAL.business.sid;
    });
    setBusiness(_matched_scart);
  }, [props._CART.scarts, props._GLOBAL.business.sid]);
  //console.log(_Business, "kkkkk");
  if (
    props._GLOBAL !== undefined &&
    props._GLOBAL !== null &&
    props._GLOBAL.business !== undefined
  ) {
    //console.log(props._GLOBAL, "kk");
    return (
      <div>
        <SearchPage />
        <nav
          className="animate-nav d-flex justify-content-between navbar  pt-md-0 pt-2"
          id="subnavbar"
          style={{ zIndex: "3" }}
        >
          <ul className="left-ull d-flex list-unstyled col-lg-4 col-md-5 col-sm-7 px-sm-0 px-3  mx-sm-auto col-12 justify-content-between px-sm-3  px-2">
            <Link
              to={
                props._CS?.col_status === 1
                  ? `/store/${props._GLOBAL.business.buname}/shop`
                  : `/shop`
              }
              style={{ textDecoration: "none" }}
            >
              <li
                className={
                  props._GLOBAL.display_template === "cp0"
                    ? "active_ d-flex flex-column text-center"
                    : "deactive d-flex flex-column text-center"
                }
                onClick={(i) => {
                  props.callApi({
                    operation: "/products_by_category",
                    caller_ref: "CP0.subnav.subnav.render1",
                    params: {
                      bid: props._GLOBAL.business.bid,
                      sid: props._GLOBAL.business.sid,
                      catid: "root",
                    },
                  });
                }}
              >
                <i className="fas fa-store-alt d-md-none d-block"></i>Shop
              </li>
            </Link>
            <Link
              to={
                props._CS?.col_status === 1
                  ? `/store/${props._GLOBAL.business.buname}/departments`
                  : `/departments`
              }
              style={{ textDecoration: "none" }}
            >
              <li
                className="deactive d-flex flex-column text-center"
                // onMouseOver={(e)=>{handleCategoryDiv()}}
                onClick={(i) => {
                  props.callApi({
                    operation: "/departments_of_business",
                    caller_ref: "subnav.render2",
                    params: {
                      bid: props._GLOBAL.business.bid,
                      sid: props._GLOBAL.business.sid,
                      catid: "root",
                    },
                  });
                }}
              >
                <i className="fab fa-windows d-md-none d-block"></i>
                Departments
              </li>
            </Link>
            <li className="d-flex flex-column text-center">
              <i className="fas fa-tags d-md-none d-block"></i>Savings
            </li>
            <li className="d-xl-flex flex-column text-center d-none">
              Get $50
            </li>
            <li
              className="d-lg-none flex-column text-center d-flex searchPopupSubNav"
              onClick={openSearchForm}
            >
              <i className="fas fa-search d-md-none d-block"></i>Search
            </li>
          </ul>
          {/* -----------------right-ull-------------------- */}
          <ul
            className="right-ull list-unstyled col-md-6 col-sm-9 mx-sm-auto col-12 d-flex flex-column justify-content-center p-0"
            onClick={
              isAuthenticated
                ? (e) => {
                    // console.log('############# launching BZPopup')
                    props.setBZPopupDT("pick-fulfilment");
                    SstoreOpen();
                  }
                : handleLoginO
            }
          >
            {_Business?.fulfilment?.type && _Business?.fulfilment?.time_slot ? (
              <li
                className="fulfilment-rightull-upper"
                style={{
                  fontWeight: "400",
                  fontSize: ".7rem",
                }}
              >
                {_Business?.fulfilment?.type === "pickup" ? (
                  <span>
                    <i className="fas fa-truck-pickup"></i>&nbsp;Curbside Pickup
                  </span>
                ) : _Business?.fulfilment?.type === "delivery" ? (
                  <span>
                    <i className="fas fa-truck-loading"></i>&nbsp; Delivery
                  </span>
                ) : _Business?.fulfilment?.type === "ship" ? (
                  <span>
                    <i className="fas fa-dolly-flatbed"></i>&nbsp; Ship
                  </span>
                ) : _Business?.fulfilment?.type === "in-store" ? (
                  <span>
                    <i className="fas fa-store-alt"></i>&nbsp; In-Store
                  </span>
                ) : null}{" "}
                &nbsp;
              </li>
            ) : (
              ""
            )}

            {_Business?.fulfilment?.time_slot ? (
              <li className="d-flex flex-column">
                <span
                  id="reserved-slot"
                  style={{
                    fontWeight: "lighter",
                    fontSize: ".7rem",
                  }}
                >
                  {_Business?.fulfilment?.time_slot?.date},
                  {_Business?.fulfilment?.time_slot?.ft} -
                  {_Business?.fulfilment?.time_slot?.et}
                  &nbsp; <i className="fas fa-chevron-down zipcode"></i>
                </span>
                <small
                  style={{
                    fontWeight: "400",
                    fontSize: ".7rem",
                    lineHeight: "1.2em",
                  }}
                >
                  {" "}
                  @ {props._GLOBAL.business?.buname?.toUpperCase()}{" "}
                  {props._GLOBAL.business?.s_address?.street}&nbsp;
                </small>
              </li>
            ) : (
              <li className="d-flex flex-column">
                <span>
                  <button id="reserver-slot">
                    Reserve a Time Slot&nbsp;{" "}
                    <i className="fas fa-chevron-down zipcode"></i>
                  </button>
                </span>
              </li>
            )}
          </ul>
          {/* -----------------right-ull-------------------- */}
        </nav>
        {/* <BC/> */}
      </div>
    );
  } else {
    return null;
  }
} //render

const mapStateToProps = (state) => {
  //console.log("Global- mapStateToProps: CP0/subnav/subnav", state.item);
  return {
    _CART: state.item.CART,
    _GLOBAL: state.item.GLOBAL,
    _ZR: state.item.ZR,
    _auth: state.auth,
    _CS: state.item.CS,
  };
};
export default connect(mapStateToProps, { setZipcode, callApi, setBZPopupDT })(
  Subnav
);
