import React from 'react'
import { connect } from "react-redux";
import {closeSearchPage} from '../event_functions'


function SearchPage(props) {
    return (
     
            <div className="container-fluid searchPage d-none" style={{background:"rgba(0, 0, 0, 0.2)"}} id="searchPage">
                <div className="row py-2 align-items-center">
                    <svg onClick={closeSearchPage} width="20px" height="20px" viewBox="0 0 24 24" aria-hidden="true" fill="white"><path d="M6 11.983c-.01.27.089.543.291.745l9 8.979a.999.999 0 0 0 1.415-.002c.39-.39.384-1.03 0-1.413L8.41 12.016l8.3-8.306a1.008 1.008 0 0 0 .001-1.416.998.998 0 0 0-1.415 0L6.29 11.308c-.185.185-.282.429-.29.676z"></path></svg>
                    <div className="col-11 p-0 d-lg-none d-block">
                      {/* <SS/> */}
                    </div>
                </div>
            </div>
   
    )
}


const mapStateToProps = (state) => {
    return {
      _GLOBAL: state.item.GLOBAL,
    };
  };
  export default connect(mapStateToProps, null)(SearchPage);
