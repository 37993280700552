import React from "react";
import { Link } from "react-router-dom";
import { callApi, setBZPopupDT } from "../../actions/index";
import { connect } from "react-redux";

import { logoutUser } from "../../actions/authentication";
import PropTypes from "prop-types";
import { SstoreOpen } from "../event_functions";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./nav.css";
import SS from "../SS/SS";
import AccountCart from "../AccountCart";
function Nav(props) {
  if (
    props._GLOBAL !== undefined &&
    props._GLOBAL !== null &&
    props._GLOBAL.business !== undefined
  ) {
    //   const onLogout=(e) =>{
    //     e.preventDefault();
    //    props.logoutUser(props.history);
    // }

    //    const { isAuthenticated, user } = props.auth;
    return (
      <div>
        <nav
          className="navbar py-2 px-1 px-md-2 fixed-top"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.363)),url(${
              props._GLOBAL.uris[props._GLOBAL.business.bbanner?.uri] +
              props._GLOBAL.business.bbanner?.src
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          id="d-navbar"
        >
          <div className="d-flex col-5 col-md-2">
            <Link
              style={{ color: "white" }}
              to={
                props._CS?.col_status === 1
                  ? `/store/${props._GLOBAL.business.buname}/shop`
                  : `/shop`
              }
            >
              <LazyLoadImage
                src={
                  props._GLOBAL.uris[props._GLOBAL.business.blogo?.uri] +
                  props._GLOBAL.business.blogo?.src
                }
                className="animate_logo2 rounded-circle"
                loading="lazy"
                alt=""
              />
            </Link>{" "}
            &nbsp;
            <div className="specss position-relative white d-flex justify-content-center">
              <Link
                style={{ color: "white" }}
                onClick={(e) => {
                  props.setBZPopupDT("pick-store");
                  SstoreOpen();
                }}
              >
                <h5 style={{ fontWeight: 800 }} className="navStoreName">
                  {props._GLOBAL.business.bname} &nbsp;
                  <i className="fas fa-chevron-down "></i>
                </h5>
              </Link>
              <small className="delivery-text">
                {props._GLOBAL.business.s_address &&
                props._GLOBAL.business.s_address?.city &&
                props._GLOBAL.business.s_address?.state
                  ? props._GLOBAL.business.s_address?.city +
                    "," +
                    props._GLOBAL.business.s_address?.state
                  : props._GLOBAL.business.s_address &&
                    props._GLOBAL.business.s_address?.state
                  ? props._GLOBAL.business.s_address?.state
                  : props._GLOBAL.business.s_address?.street}
              </small>
            </div>
          </div>
          <div
            className="form-input col-12 col-sm-9 col-lg-6 m-auto d-lg-block"
            id="form-input"
          >
            <SS />
          </div>
          <AccountCart />
        </nav>
      </div>
    );
  } else {
    return null;
  }
}
Nav.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    _GLOBAL: state.item.GLOBAL,
    _CART: state.item.CART,
    // auth: state.auth
    _CS: state.item.CS,
  };
};

export default connect(mapStateToProps, { callApi, logoutUser, setBZPopupDT })(
  Nav
);
