import React, { useEffect, useRef } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import { connect } from "react-redux";
import { callApi, syncLocation, setType } from "../actions/index";
import loadable from "@loadable/component";
import AddressPopup from "./AddressPopup";
import PaymentPopup from "./PaymentPopup";
import Loader from "./Loader/loader"; // eslint-disable-line
import LoadingBar from "react-top-loading-bar";
//set_display_template

// store components
import BZ from "./BZ/BZ"; // eslint-disable-line
// shop components

import CP0 from "./CP0/CP0";

import Info from "./Info/Info";
//  departments components
import DB from "./DB/DB";
import UE from "./Templates/UE";

//  departments/produce components
import CP from "./CP/CP";
import CP1 from "./CP1/CP1";
import CP2 from "./CP2/CP2";
import CP3 from "./CP3/CP3";
import CP4 from "./CP4/CP4";
// single item detail
//import PD from "./PD/PD";
import PD from "./PD/PD2";
import "./app.css";
import SR from "./SR/SR";

import OL from "./Account/Order/OL";
import OD from "./Account/Order/OD";
import Account from "./Account/Account";

import Payment from "./Account/Payment/Payment";
import Address from "./Account/Address/Address";

import Cart from "./CART/CART";

// import Nav from './nav/nav';
// import Subnav from './subnav/subnav'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
//import { REPL_MODE_STRICT } from "node:repl";
//import BZ2 from "./BZ2/BZ2";
//import SetUp from "./setup/setUp";
const BZ2 = loadable(() => import("./BZ2/BZ2"));
//const SetUp = loadable(() => import("./setup/setUp"));

// import "jquery/dist/jquery.min.js";

//const BZPopup = lazy(() => import('./BZ/BZPopup'));
//const Login = lazy(() => import('./Login'));
//const Register = lazy(() => import('./Login'));
//const Forgot = lazy(() => import('./Forgot'));

const BZPopup = loadable(() => import("./BZ/BZPopup"));
const Login = loadable(() => import("./Login"));
const Register = loadable(() => import("./Register"));
const Forgot = loadable(() => import("./Forgot"));

//import BZPopup from "./BZ/BZPopup";
// import Login from "./components/Login";
// import Register from "./components/Register";
// import Forgot from "./components/Forgot";

function App(props) {
  const ref = useRef(null);
  const barColor = "#f11946";
  
  let _cp_display_template;

  const location = useLocation();
  const history = createBrowserHistory(); // eslint-disable-line no-unused-vars



  // const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (window.location.href.indexOf("prod") > -1) {
      props.setType("prod");
    } else if (window.location.href.indexOf("debug") > -1) {
      props.setType("debug");
    } else {
      props.setType("sync");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // (() => {
  //   var _log = console.log;
  //   console.log = (message) => {
  //     //myCustomHandlingFunction(message);
  //     //
  //     if(!props._PREF["debug"]) {
  //       _log.apply(console,arguments);
  //     }
  //   };
  // })();

  // useEffect(() => {
  //   (() => {
  //     var _log = console.log;
  //     console.log = (message) => {
  //       //myCustomHandlingFunction(message);
  //       //
  //       if(props._PREF["debug"]) {
  //         _log.apply(console,arguments);
  //       }
  //     };
  //   })();
  // }, [props._PREF])
  //const [log_enabled,set_log_enabled] = useState(console.log)

  // let log_enabled = console.log;
  // useEffect(() => {
  //   console.log("What is debug???",props._PREF["debug"])
  //   if(props._PREF["debug"]){
  //     console.log = log_enabled;
  //   } else {
  //     console.log = function(){};
  //   }
  // }, [props._PREF])

  // localStorage.setItem('mode', true);
  // localStorage.setItem('mode', false);

  // let _lmode = JSON.parse(localStorage.getItem("mode"))
  // let _mode =  _lmode ? _lmode : false
  // props.setType("mode",_mode)
  // console.log("mode:",_mode);

  useEffect(() => {
    //setProgress(progress + 20)
    props.syncLocation();
    //console.log('#######',props._LOC);
    let _LOC;
    if (props._LOC?.address) {
      _LOC = props._LOC;
      //console.log('@@@@@ redux LOC',_LOC)
    } else {
      _LOC = JSON.parse(localStorage.getItem("LOC"));
      //console.log('@@@@@ localStorage LOC',_LOC)
    }
    let _address = _LOC?.address;
    let _location = _LOC?.location;
    let _rad = _LOC?.rad;

    //this is used in col_status = 0 scenario, where if cat path is present then
    //we will show appropriate template
    // console.log(
    //   "#### history.location.path:",
    //   history.location.pathname,
    //   "location.pathname:",
    //   location.pathname,
    //   "props._CS.col_status:",
    //   props._CS.col_status, _address,_location, _rad
    // );

    let _catpath, _pid, _sid, _operation, _catid;
    let _buname = window.location.href.split("/")[4];
    // console.log("buname??????", _buname);
    if (
      location.pathname === "/" ||
      location.pathname === "/shop" ||
      location.pathname.includes("/shop")
    ) {
      _operation = "/products_by_category";
      _catid = "root";
    } else if (
      location.pathname === "/departments" ||
      location.pathname.includes("/departments")
    ) {
      _operation = "/departments_of_business";
    } else {
      try {
        //console.log('performing regex',window.location.href.split('/').length())
        _catpath = window.location.href.match(
          new RegExp("/category/([^]+)")
        )[1];
        _operation = "/products_by_category";
        _catid = "";
      } catch (error) {
        //console.log('getting regex error',error)
        //return null;
      }
      if (_catpath === undefined) {
        try {
          _pid = window.location.href.match(new RegExp("/item/([^]+)"))[1];
          _operation = "/products_details";
          // console.log("what is col//-status?????????", props._CS.col_status);
          if (props._CS.col_status === 0) {
            _sid = window.location.href.split("/")[3];
            if (_sid === "item") {
              _sid = "";
            }
            if (_buname === "item") {
              _buname = "";
            }
          } else {
            // console.log("why is it going to else???????");
            _buname = window.location.href.split("/")[4];
            _sid = window.location.href.split("/")[5];
            if (_sid === "item") {
              _sid = "";
            }
          }
          //console.log('PD pid:',_pid,'sid:',_sid)
        } catch (error) {
          //console.log('getting regex error',error)
          //return null;
        }
      }
    }
    if (props._CS.col_status !== undefined) {
      //setProgress(progress + 60)
      props.callApi({
        operation: _operation,
        caller_ref: "App.jsx.useEffect",
        params: {
          catpath: _catpath,
          catid: _catid,
          pid: _pid,
          sid: _sid,
          buname: _buname,
          address: _address,
          location: _location,
          rad: _rad,
        },
      });
      //setProgress(progress + 90)
      props.callApi({
        operation: "/businesses_by_address",
        caller_ref: "app.getBusinessesByAddress",
        params: {
          buname: _buname,
          address: _address,
          location: _location,
          rad: _rad,
          unit: "miles",
          // catpath: _catpath,
        },
      });
    }
  }, [props._CS]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    ref.current?.continuousStart();
    //setProgress(progress + 10)
    props.callApi({
      operation: "/collaboration_status",
      caller_ref: "app.useEffect",
      params: {},
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // title change
  useEffect(() => {
    if (props._GLOBAL.business?.buname) {
      document.title = props._GLOBAL.business?.buname;
    } else {
      document.title = "Rapo Market StoreFront";
    }
    // document.getElementById("favicon").href = " https://s.ytimg.com/yts/img/favicon-vfl8qSV2F.ico";
  }, [props._GLOBAL.business?.buname]);

  useEffect(() => {
    // console.log(
    //   "###### PT for PD props._BZ.PTs is changed???",
    //   props._BZ.business?.PTs
    // );
    // props._BZ.business?.PTs.forEach(
    //   (pt_id,index) => {
    //     // console.log(props._BZ.business.bid,pt_id,"--------")
    //       props.callApi({
    //         operation: "/get_PT",
    //         caller_ref: "PDDetail.useEffect when BZ changes",
    //         params: {
    //           bid:props._BZ.business.bid,
    //           ptid: pt_id,
    //         },
    //       });
    //   },
    // );

    // console.log(props._BZ.business.bid,pt_id,"--------")
    if(props._BZ.business?.bid) {
      props.callApi({
        operation: "/get_PTs",
        caller_ref: "PDDetail.useEffect when BZ changes",
        params: {
          bid: props._BZ.business?.bid,
        },
      });
    }
  }, [props._BZ.business?.PTs]); // eslint-disable-line react-hooks/exhaustive-deps

  _cp_display_template = props._GLOBAL.cp_display_template;

  props._GLOBAL && props._GLOBAL.display_template
    ? (_cp_display_template = props._GLOBAL.cp_display_template)
    : (_cp_display_template = JSON.parse(localStorage.getItem("global"))
        ?.cp_display_template);

  return (
    <div>
      {/* {console.log(
        "#### history.location.path:",
        history.location.pathname,
        "location.pathname:",
        location.pathname,
        "_cp_display_template:",
        _cp_display_template,
        "props._CS:",
        props._CS
      )} */}
      <BrowserRouter>
        {/* {history.location.pathname} */}
        <Cart />
        <Switch>
          {/* <Route exact path="/setup" component={SetUp} /> */}
          <Route path="/" exact>
            {/* <BZ/> */}
            {/* {props._BZ && props._BZ.businesses && props._BZ.businesses?.length !== 0 ? (*/}
            {/*) : (  */}
            {props._CS?.col_status === 1 ? (
              <BZ2 popup={false} />
            ) : props._CS?.col_status === 0 &&
              props._CS?.template_type === "cp0" ? (
              <CP0 />
            ) : props._CS?.col_status === 0 &&
              props._CS?.template_type === "cp4" ? (
              <CP4 />
            ) : (
              // <Loader />
              //         <LoadingBar color={barColor} progress={progress}
              // onLoaderFinished={() => setProgress(0)} />
              <LoadingBar color={barColor} ref={ref} />
              // <LoadingBar color="#f11946" ref={ref} shadow={true}  />
            )}
            {/* )} */}
          </Route>
          <Route path="/cp4">
            <CP4 />
          </Route>

          <Route path="/ue">
            <UE />
          </Route>
          <Route path="/account">
            <div
              className="container-fluid d-flex p-0"
              style={{ marginTop: "120px" }}
            >
              <Account />
              <Route exact path="/account/payments" component={Payment} />
              <Route exact path="/account/orders" component={OL} />
              <Route exact path="/account/addresses" component={Address} />
              <Route exact path="/account/orderdetails/:id" component={OD} />
            </div>
          </Route>
          <Route
            style={{ overflow: "hidden" }}
            path={
              props._CS.col_status === 1 ? "/store/:business/shop" : "/shop"
            }
          >
            {props._GLOBAL.business?.template_type === "cp0" ? (
              <CP0 />
            ) : props._GLOBAL.business?.template_type === "cp4" ? (
              <CP4 />
            ) : null}
          </Route>

          <Route
            path={
              props._CS?.col_status === 1
                ? "/store/:business/departments"
                : "/departments"
            }
          >
            <DB />
          </Route>

          <Route
            path={
              props._CS?.col_status === 1
                ? "/store/:id/category/:id/"
                : "/category/:id"
            }
          >
            <CP />
          </Route>

          <Route
            path={
              props._CS?.col_status === 1
                ? "/store/:id/:id/item/:id"
                : "/:id/item/:id"
            }
            exact
          >
            <PD popup={false} />
          </Route>

          <Route
            exact
            path={
              props._CS?.col_status === 1
                ? "/store/:id/search/:id"
                : "/search/:id"
            }
          >
            <SR />
          </Route>
          <Route
            exact
            path={
              props._CS?.col_status === 1 ? "/store/:id/item/:id" : "/item/:id"
            }
          >
            {_cp_display_template === "cp0" &&
            props._GLOBAL.business?.template_type === "cp0" ? (
              <CP0 />
            ) : _cp_display_template === "cp1" ? (
              <CP1 />
            ) : _cp_display_template === "cp2" ? (
              <CP2 />
            ) : _cp_display_template === "cp3" ? (
              <CP3 />
            ) : _cp_display_template === "cp4" &&
              props._GLOBAL.business?.template_type === "cp4" ? (
              <CP4 />
            ) : null}

            {_cp_display_template ? (
              <PD popup={true} />
            ) : (
              <PD redirect={true} popup={false} />
            )}
          </Route>
          <Route exact path="/store/Info/:id">
            {_cp_display_template === "cp0" &&
            props._CS?.template_type === "cp0" ? (
              <CP0 />
            ) : _cp_display_template === "cp1" ? (
              <CP1 />
            ) : _cp_display_template === "cp2" ? (
              <CP2 />
            ) : _cp_display_template === "cp3" ? (
              <CP3 />
            ) : _cp_display_template === "cp4" &&
              props._CS?.template_type === "cp4" ? (
              <CP4 />
            ) : null}
            {_cp_display_template ? <Info /> : <Info redirect={true} />}
          </Route>
        </Switch>
      </BrowserRouter>
      <BZPopup _global={props._GLOBAL} />
      <div className="login-popup">
        <Register />
        <Login />
        <Forgot />
      </div>
      <AddressPopup />
      <PaymentPopup />
    </div>
  );
}

const mapStateToProps = (state) => {
  //console.log(state.item.LOC)
  return {
    _GLOBAL: state.item.GLOBAL,
    _BZ: state.item.BZ,
    _LOC: state.item.LOC,
    _CS: state.item.CS,
    _PT: state.item.PT,
    _PREF: state.item.PREF,
  };
};

//set_display_template,
export default connect(mapStateToProps, {
  callApi,
  syncLocation,
  setType,
})(App);
