import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setZipcode, callApi } from "../../actions/index";
import { logoutUser } from "../../actions/authentication";
import PropTypes from "prop-types";
import "./nav.css";
import logo2 from "../assets/rapo-market.png";
import { LazyLoadImage } from "react-lazy-load-image-component"; 
import {
  // handleClose,
  // handleOpen,
  // handleOpenn,
  handleAccountC,
  // openSearchForm,
  // overflow_hidden,
} from "../event_functions";
import AccountCart from "../AccountCart";
function Nav(props) {
  document.addEventListener(
    "click",
    (evt) => {
      let targetEl = evt.target; // clicked element

      if (
        targetEl.classList.contains("zipcode") ||
        targetEl.classList.contains("zipcodeForm") ||
        targetEl.classList.contains("zipInput")
      ) {
        return null;
      } else {
        // handleCloses();
      }
    },
    { passive: true }
  );

  document.addEventListener(
    "click",
    (evt) => {
      let targetEl = evt.target; // clicked element

      if (
        targetEl.classList.contains("account-list") ||
        targetEl.classList.contains("a-list") ||
        targetEl.classList.contains("account-div") ||
        targetEl.classList.contains("d-icon")
      ) {
        return null;
      } else {
        handleAccountC();
      }
    },
    { passive: true }
  );

  useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        let targetEl = e.target;
        if (targetEl.classList.contains("handleAccountOpenDiv")) {
          if (document.getElementsByClassName("account-list")[0]) {
            document.getElementsByClassName(
              "account-list"
            )[0].style.visibility = "visible";
            document.getElementsByClassName("account-list")[0].style.opacity =
              "1";
            document.getElementsByClassName("u-icon")[0].style.visibility =
              "visible";
            document.getElementsByClassName("d-icon")[0].style.visibility =
              "hidden";
          }
        } else {
          if (document.getElementsByClassName("account-list")[0]) {
            document.getElementsByClassName(
              "account-list"
            )[0].style.visibility = "hidden";
            document.getElementsByClassName("account-list")[0].style.opacity =
              "0";
          }
          if (document.getElementsByClassName("u-icon")[0]) {
            document.getElementsByClassName("u-icon")[0].style.visibility =
              "hidden";
            document.getElementsByClassName("d-icon")[0].style.visibility =
              "visible";
          }
        }
      },
      { passive: true }
    );
  });

  //  const { isAuthenticated, user } = props._auth;

  return (
    <div>
      {/* <SearchPage/> */}

      <nav className="navbar fixed-top px-2" id="s-navbar">
        <div className="" id="logo">
          <LazyLoadImage
            className=" img-fluid logo1 logo1-bz"
            src={logo2}
            loading="lazy"
            alt=""
          />
        </div>

        {/* ----------------right-ul---------------------- */}
        <AccountCart />
      </nav>
    </div>
  );
}
Nav.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    state: state.item.stores,
    _CART: state.item.CART,
    _GLOBAL: state.item.GLOBAL,
    _ZR: state.item.ZR,
    //_auth: state.auth
  };
};
export default connect(mapStateToProps, { setZipcode, callApi, logoutUser })(
  Nav
);
