import React, { useState} from "react";
import { connect } from "react-redux";
import { callApi } from "../../actions/index";

const SimpleForm = (props) => {
    const [Qty, UpdateQty] = useState(1);

    function getQtyFromCart(pid, sid) {
        let mitem = null;
        let store = props._CART.scarts.filter((item) => {
          return sid === item.sid;
        });
        if (store[0]) {
          mitem = store[0].product.filter((item) => {
            return pid === item.pid;
          });
        }
        if (mitem && mitem[0] && mitem[0].qty) {
          // console.log(mitem[0].qty);
          return mitem[0].qty;
        } else {
          //    //console.log("returning 0?");
          return 0;
        }
      }

        // ----------update-qaty and select-qty---------



  function showForm2(qty) {
    var form1 = document.getElementsByClassName("qty-form-one")[0];
    var form2 = document.getElementsByClassName("qty-form-two")[0];
    form2.style.display = "flex";
    form1.style.display = "none";
    //console.log(qty, "new-qty");
    UpdateQty(qty);
  }
  function showForm1(qty) {
    var form1 = document.getElementsByClassName("qty-form-one")[0];
    var form2 = document.getElementsByClassName("qty-form-two")[0];
    form1.style.display = "flex";
    form2.style.display = "none";
    UpdateQty(qty);
  }
  const { isAuthenticated} = props._auth;
    return (
 
            <div>
                    <div className="">
                    <h6 className="small" style={{textAlign:"left",marginTop:"10px"}}>QUANTITY</h6>
                    <div
                      className="qty-form-two "
                      style={{
                        display: `${
                          getQtyFromCart(props.obj?.id, props.__PD.business.sid) === 0
                            ? "flex"
                            : "none"
                        }`,
                      }}
                    >
                      <select
                        id="select_c"
                        name="select_c"
                        onChange={(e) => {
                          UpdateQty(e.target.value);
                        }}
                        className="px-3 "
                        value={Qty}
                      >
                        <option value="1" selected>
                          1
                        </option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                      {/* 2 */}
                      <div
                        className="w-50 ms-md-4 "
                        role="group"
                        aria-label="Basic example"
                      >
                        <button
                          onClick={(e, i) => {
                            if (isAuthenticated) {

                              props.callApi({
                                operation: "/save_item_to_cart",
                                caller_ref: "PD.render2",
                                params: {
                                  bid: props.__PD.business.bid,
                                  sid: props.__PD.business.sid,
                                  pid: `${props.obj.id}`,
                                  qty: Qty === undefined ? 1 : Qty,
                                },
                              });
                            }

                            showForm1();
                          }}
                          className="w-100 btn ms-lg-0 ms-2"
                          style={{
                            border: "1px solid #3E3665",
                            background: "#3E3665",
                            borderRadius: "3px",
                            color: "var(--color_white)",
                          }}
                          type="button"
                        >
                          <h6 className="mt-1 ">
                            {getQtyFromCart(props.obj?.id, props.__PD.business.sid) === 0
                              ? "Add to cart"
                              : "Update cart"}
                          </h6>
                        </button>
                      </div>
                    </div>
                    <div
                      className="qty-form-one justify-content-between"
                      style={{
                        display: `${
                          getQtyFromCart(props.obj?.id, props.__PD.business.sid) > 0
                            ? "flex"
                            : "none"
                        }`,
                        height: "5.85vh",
                      }}
                    >
                      <select
                        id="select_c"
                        name="select_c"
                        value={getQtyFromCart(props.obj?.id, props.__PD.business.sid)}
                        style={{
                          background: "#3E3665",
                          color: "var(--color_white)",
                          padding: "10px",
                          borderRadius: "5px",
                          border: "none",
                        }}
                        onChange={(e) => {
                          showForm2(e.target.value);
                        }}
                        className="px-3 w-75"
                      >
                        <option
                          selected
                          value={getQtyFromCart(props.obj?.id, props.__PD.business.sid)}
                        >
                          {getQtyFromCart(props.obj?.id, props.__PD.business.sid)} in Cart
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                    </div>
                     {/* ----------forms type 1-------------  */}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
      _auth: state.auth,
      _CART: state.item.CART,
    };
  };
  export default connect(mapStateToProps, {
    callApi
  })(SimpleForm);


