
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { callApi } from "../../actions/index";
import Carousel from "react-elastic-carousel";
import ScartTime from "./ScartTime";

function ScartDate(props){
  const [windowWidth, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", function () {
    setWidth(window.innerWidth);
  });
    const [dateSlot, setDateSlot] = useState();
//    const [timeSlot, setTimeSlot] = useState({});

    const selectDateSlot = (e, day) => {

        setDateSlot(day);
        var box = document.querySelectorAll(".date-box");
        box.forEach((btn) => {
          btn.classList.remove("date-box-select");
        });
        if (e.currentTarget.classList.contains("date-box")) {
          e.currentTarget.classList.add("date-box-select");
        }
        var slottic = document.querySelectorAll(".slot-d-tic");
        slottic.forEach((btn) => {
          btn.classList.remove("slot-tic-active");
        });
        if (e.currentTarget.childNodes[0].classList.contains("slot-d-tic")) {
          //console.log("is tic is being set?");
          e.currentTarget.childNodes[0].classList.add("slot-tic-active");
        }
    };
    useEffect(() => {
      const _day = props._SS.schedule?.days.find((day) => {
        if(props.scart?.fulfilment?.time_slot?.date){
          // console.log(day.did,props.scart?.fulfilment?.time_slot?.date)
          return day.did === props.scart?.fulfilment?.time_slot?.date;
        } else {
          return null;
        }
      });
      if (_day && _day !== undefined) {
        //console.log(_day, "setting dateSlot 111");
        setDateSlot(_day);
      }
    },[props._SS.schedule?.days, props.scart?.fulfilment?.time_slot?.date]); //check if we need to keep bounds here?????

    return (
        <div className="Ful_Cart">
          <span
      className="row mb-1 ms-2"
      style={{
        color: "var(--color_purple)",
        fontSize: ".8em",
      }}
    >
      Date
    </span>
    <Carousel
      renderArrow={props.carouselArrow}
      className="p-0"
      itemsToShow={windowWidth >= 500
       ? 3.2
       : 2.5
   }
    >
      {props._SS?.schedule?.days.map((day) => {
        return (
          // <button
          //   className={
          //     props.scart?.fulfilment?.time_slot?.date === day.did
          //       ? "date-box-selected d-flex align-items-center flex-column"
          //       : "date-box date-box-c d-flex align-items-center flex-column"
          //   }
          //   onClick={(e, i) => {
          //       selectDateSlot(e, day);
          //     }}>
          //   <div
          //     className={
          //       props.scart?.fulfilment?.time_slot?.date ===
          //       day?.did
          //         ? "slot-t-tic-active slot-d-tic slot-d-tic-c"
          //         : "slot-d-tic slot-d-tic-c"
          //     }
          //   >
          //     <i className="fas fa-check"></i>
          //   </div>
          //   <div className="lh-16">{day.did}</div>
          // </button>
          <button
                      className={
                        props._scart?.fulfilment?.time_slot?.date === day.did &&
                        props._scart?.fulfilment?.type ===
                          props.clickedFulfilment.toLowerCase()
                          ? "date-box-selected d-flex align-items-center flex-column"
                          : "date-box d-flex align-items-center flex-column"
                      }
                      onClick={(e, i) => {
                              selectDateSlot(e, day);
                            }}
                    >
                      <div className="dt_upper">
                        <div style={{fontSize:".9em"}}>2020</div>
                        <div style={{fontSize:"1em",fontWeight:"bold",lineHeight:"1em"}}>{day?.day}</div>
                        </div>
                      {/* <p>{(new Date(day.date)).toDateString()}</p>                       */}
                      <div className="dt_lower">
                        <div style={{fontSize:"1.9em", fontWeight:"bolder", color:"black"}}> {day.date}</div>
                        <div style={{fontSize:"1em",fontWeight:"bold"}}>{day.month}</div> 
                        </div>
                    </button>
        );
      })}
    </Carousel >
    <ScartTime scart={props.scart} carouselArrow={props.carouselArrow} dateSlot={dateSlot} fulfilment={props.fulfilment} />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
      _CART: state.item.CART,
      _GLOBAL: state.item.GLOBAL,
      _auth: state.auth,
      _BZ: state.item.BZ,
      _LA: state.item.LA,
      _LC: state.item.LC,
      _SS: state.item.SS,
    };
  };
  
  export default withRouter(
  connect(mapStateToProps, {
    callApi,
  })(ScartDate)
  );