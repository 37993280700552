import React, { useEffect } from "react";
import { ChevronRight } from "@material-ui/icons";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Store from "../../card.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  callApi,
  setZipcode,
  updateFulfilmentAid,
  setClickedIndex,
  checkPAPopup,
} from "../../../actions/index";
import { showPaymentPopup, clearFormData } from "../../event_functions";
function Payment(props) {
  const deleteCardByCid = (_cid) => {
    props.callApi({
      operation: "/delete_card",
      caller_ref: "Payment.deletePaymentByAid",
      params: {
        // bid: props._GLOBAL.business?.bid,
        cid: _cid,
      },
    });
  };

  const setDefaultCardByCid = (_cid) => {
    const _sel_card = props._LC.cards.find((card) => {
      return card._id === _cid;
    });

    props.callApi({
      operation: "/edit_card",
      caller_ref: "Payment.setDefaultCardByCid",
      params: {
        cid: _cid,
        // bid: props._GLOBAL.business.bid,
        cname: _sel_card.cname,
        cnumber: _sel_card.cnumber,
        exp_month: _sel_card.exp_month,
        exp_year: _sel_card.exp_year,
        ctype: _sel_card.ctype,
        address_key: _sel_card.address_key,
        isDefault: true,
      },
    });
  };

  useEffect(() => {
    //console.log("Calling Cards from backend....",props._GLOBAL.business);
    //load only if addresses are not loaded before for first time after refresh
    if (!props._LC?.cards && props._GLOBAL.business?.bid) {
      props.callApi({
        operation: "/list_card",
        caller_ref: "Payment.useEffect",
        params: {
          // bid: props._GLOBAL.business?.bid,
        },
      });
    }
    //props.syncAccount();
  }, [props._GLOBAL]); // eslint-disable-line

  // const [editedData, seteditedData] = useState("");
  // const [CheckState, setCheckState] = useState("");

  //console.log("### Address #########", props._LC);
  return (
    <div className="col-lg-10 ms-xl-5 ms-lg-3 px-1 orders_main">
      <div className="d-flex align-items-center mt-3">
        {/* <Link to="/">
                  <small>Home</small>
              </Link>
              <ChevronRight className='UIChevronRight' /> */}
        <Link to="/account">
          <small>My account</small>
        </Link>
        <ChevronRight className="UIChevronRight" />
        <small>Payment</small>
      </div>
      <div className="row py-2">
        <h5>Your Payment</h5>
      </div>
      <div className="col-12 d-flex flex-wrap py-3">
        {props._LC.cards?.map((obj, i) => (
          <div
            // key={i}
            className="each-addr-box col-xl-3 col-lg-4 col-md-5 col-sm-6 p-1 "
            onClick={(e, i) => {
              // getSelectedAddress(e, obj);
            }}
          >
            <LazyLoadImage
              className="credit-card"
              src={Store}
              alt=""
              loading="lazy"
            />
            <div
              className="payment-white-box border rounded-1 p-2"
              //   onClick={(e, i) => {
              //     selectDeliveryLocation(e);
              //   }}
            >
              <div className="card-body d-flex flex-column p-2">
                <h6 className="">{obj.cname}</h6>
                <div
                  className="d-flex align-items-center"
                  style={{ marginTop: "20px" }}
                >
                  <div className="m-0" style={{ width: "calc(100% - 65px)" }}>
                    <div
                      style={{
                        color: "#999999",
                        fontSize: "0.95em",
                        fontWeight: 500,
                      }}
                    >
                      CARD NUMBER
                    </div>
                    <h6 style={{ lineHeight: "26px" }}>{obj.cnumber}</h6>
                  </div>
                  <div className="m-0" style={{ width: "65px" }}>
                    <div
                      style={{
                        color: "grey",
                        fontSize: "0.95em",
                        fontWeight: 500,
                      }}
                    >
                      EXPIRES
                    </div>
                    <h6 style={{ lineHeight: "26px" }}>
                      {obj.exp_month}/{obj.exp_year}
                    </h6>
                  </div>
                </div>
                <div className="col d-flex flex-wrap justify-content-between px-1 mt-3">
                  <div className="d-flex">
                    <small
                      className="text-primary editFormBtn"
                      style={{ cursor: "pointer", fontSize: "0.85rem" }}
                      onClick={(e) => {
                        props.setClickedIndex(obj._id);
                        showPaymentPopup(e);
                        props.checkPAPopup("account");
                      }}
                    >
                      EDIT
                    </small>{" "}
                    &nbsp;<span className="mt-n1">|</span>&nbsp;
                    <small
                      className="text-danger "
                      style={{ cursor: "pointer", fontSize: "0.85rem" }}
                      onClick={() => {
                        deleteCardByCid(obj._id);
                      }}
                    >
                      REMOVE
                    </small>
                  </div>
                  {obj.isDefault === true ? (
                    <small>
                      <i className="fas fa-check"></i>&nbsp;DEFAULT
                    </small>
                  ) : (
                    <small
                      className="default-address text-primary"
                      style={{ cursor: "pointer", fontSize: "0.85rem" }}
                      onClick={(e, i) => {
                        setDefaultCardByCid(obj._id);
                      }}
                    >
                      MAKE DEFAULT
                    </small>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="each-addr-box col-xl-3 col-lg-4 col-md-4 col-sm-6 px-1">
          <div className="add-pay-box d-flex flex-column justify-content-center align-items-center mt-1">
            <i
              style={{
                color: "var(--color_purple)",
                cursor: "pointer",
                fontSize: "3em",
              }}
              className="mb-2 fas fa-plus-circle"
              onClick={(e) => {
                props.setClickedIndex();
                showPaymentPopup(e);
                clearFormData();
              }}
            ></i>
            <h6>Add New Card</h6>
          </div>
        </div>
        {/* <AddressPopup
                      checkState={CheckState}
                      editedData={editedData === "" ? null : editedData}
                    /> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  //console.log(state, "vvv");
  return {
    _BZ: state.item.BZ,
    _GLOBAL: state.item.GLOBAL,
    _ZR: state.item.ZR,
    _SS: state.item.SS,
    _CART: state.item.CART,
    _LC: state.item.LC,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    callApi,
    setZipcode,
    updateFulfilmentAid,
    setClickedIndex,
    checkPAPopup,
  })(Payment)
);
