import React from 'react'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { callApi } from "../../actions/index";

const TextNavPd = (props) => {

    const linkpaths = props.__PD.path.slice(0, props.__PD.path.length - 1);
    const linkname = props.__PD.path[props.__PD.path.length - 1].ename;

    //console.log(linkpaths);
    //console.log(props._PDs, "mmmmm");
    const linkpath = linkpaths.map((path, ind) => {
      //console.log(document.querySelector("#item-detail-page"), "kkkk");
      return (
        <span key={ind} style={{ textDecoration: "none" }}>
          &nbsp;
          <Link
            style={{ color: "var(--color_purple)" }}
            to={
              props._CS.col_status === 1
                ? `/store/${props.__PD.business.buname}/category/${path.catpath}`
                : `/category/${path.catpath}`
            }
            onClick={
              () => {
                props.callApi({
                  operation: "/products_by_category",
                  caller_ref: "PD.render1",
                  params: {
                    bid: props.__PD.business.bid,
                    sid: props.__PD.business.sid,
                    catid: path.eid,
                  },
                });
              }
              //  overflow_showing()
            }
          >
            <span style={{ color: "var(--color_purple)" }}>{path.ename}</span>
          </Link>
          &nbsp;{" "}
          <i
            className="fas fa-chevron-right"
            style={{ fontSize: "0.7rem" }}
          ></i>
        </span>
      );
    });
    return (
        <div className="row link-path mt-lg-n2 mt-md-n2 mt-0">
        <div className="m-right d-flex pb-2 mt-3 mt-md-0">
          <span style={{ textDecoration: "none" }}>
            &nbsp;
            <Link
              style={{ color: "var(--color_purple)" }}
              to={
                props._CS?.col_status === 1
                  ? `/store/${props.__PD?.business.buname}/shop`
                  : `/shop`
              }
            >
              <i
                className="fas fa-store-alt"
                onClick={
                  (i) => {
                    props.callApi({
                      operation: "/products_by_category",
                      caller_ref: "PD.render1",
                      params: {
                        bid: props.__PD.business.bid,
                        sid: props.__PD.business.sid,
                        catid: "root",
                      },
                    });
                  }
                  //, overflow_showing
                }
              ></i>
            </Link>
            &nbsp;{" "}
            <i
              className="fas fa-chevron-right"
              style={{ fontSize: "0.7rem" }}
            ></i>
          </span>
          {linkpath}
          &nbsp;{" "}
          <span style={{ color: "var(--color_purple)" }}>
            {linkname}
          </span>
        </div>
      </div>
    )
}

const mapStateToProps = (state) => {
};
export default connect(mapStateToProps, {callApi})(TextNavPd);
