import React, { useEffect, useState } from "react";
import { paymentPopupClose } from "./event_functions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import Checkbox from "@material-ui/core/Checkbox";
import { callApi } from "../actions/index";
import { TextField } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";

// accordian
// import { makeStyles } from "@material-ui/core/styles";
// import Accordion from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
// import Typography from "@material-ui/core/Typography";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import { BlockOutlined } from "@material-ui/icons";
import Store from "./card.png";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: "100%",
//   },
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     fontWeight: theme.typography.fontWeightRegular,
//   },
// }));

function PaymentPopup(props) {
  //  const classesForAccordion = useStyles();

  const [cardname_val, setCardname_val] = React.useState("");
  const [cardnumber_val, setCardnumber_val] = React.useState("");
  const [expire, setExpire] = React.useState("");
  const [cvc_val, setCvc_val] = React.useState("");
  const [address_key, setAddress_key] = React.useState("");

  let checkState = true;
  const [checkBox, setcheckBox] = useState(checkState);

  const addCard = () => {
    //    let _length = props._LC.cards.length;

    props.callApi({
      operation: "/save_card",
      caller_ref: "PaymentPopup.addCard",
      params: {
        // bid: props._GLOBAL.business.bid,
        cname: cardname_val,
        cnumber: cardnumber_val,
        exp_month: expire.split("/")[0],
        exp_year: expire.split("/")[1],
        ctype: "",
        address_key: address_key,
        isDefault: checkBox,
      },
    });
  };

  // const clearForm = () => {
  //   clearFormData();
  //   setcheckBox(false);
  // };

  const updateCard = () => {
    props.callApi({
      operation: "/edit_card",
      caller_ref: "PaymentPopup.updateCard",
      params: {
        // bid: props._GLOBAL.business.bid,
        cid: props._CLICKED_INDEX,
        cname: cardname_val,
        cnumber: cardnumber_val,
        exp_month: expire.split("/")[0],
        exp_year: expire.split("/")[1],
        ctype: "",
        address_key: address_key,
        checkedDefaultCard: checkBox,
      },
    });
  };

  let editedValue = props._LC.cards?.find((card) => {
    return card._id === props._CLICKED_INDEX;
  });
  //console.log(editedValue);

  useEffect(() => {
    //console.log("useEffect Clicked Index");
    if (editedValue) {
      setCardname_val(`${editedValue.cname ? editedValue.cname : ""}`);
      setCardnumber_val(`${editedValue.cnumber ? editedValue.cnumber : ""}`);
      setExpire(`${editedValue.exp_month ? editedValue.exp_month : ""}`);
      setCvc_val(`${editedValue.ctype ? editedValue.ctype : ""}`);
      setAddress_key(
        `${editedValue.address_key ? editedValue.address_key : ""}`
      );
    } else {
      //console.log("clearing form fields");
      setCardname_val("");
      setCardnumber_val("");
      setExpire("");
      setCvc_val("");
      setAddress_key("");
      setcheckBox(false);
    }
  }, [props._CLICKED_INDEX]); // eslint-disable-line react-hooks/exhaustive-deps

  if (props._LC.cards?.length === 0) {
    checkState = true;
  } else {
    checkState = false;
  }
  // const checkDefaultCard = () => {
  //   if (checkBox === false) {
  //     setcheckBox(true);
  //   } else {
  //     setcheckBox(false);
  //   }
  // };

  return (
    <div
      className={
        props._PAPopup === "cart"
          ? "EditMiniPopup position-absolute PAYMENTPOPUP"
          : "address-popup_main fixed-top PAYMENTPOPUP"
      }
      id={props._PAPopup === "cart" ? "add-mini-popup" : "PaymentPopup"}
    >
      <div
        className={
          props._PAPopup === "cart"
            ? "subMiniPopup bg-white rounded"
            : "address-popup  position-relative rounded p-3"
        }
      >
        <i
          className="far fa-times-circle position-absolute"
          style={{
            color: "var(--color_purple)",
            top: "5px",
            right: "7px",
            cursor: "pointer",
          }}
          onClick={(e) => {
            paymentPopupClose();
          }}
        ></i>
        <h3
          className={
            props._PAPopup === "cart"
              ? "new-addr-head py-3 text-center"
              : "new-addr-head py-2 pb-3 text-center"
          }
          style={{ color: "var(--color_purple)" }}
        >
          {editedValue ? "Edit Card" : "New Card"}
        </h3>

        <div
          className={
            props._PAPopup === "cart"
              ? "d-flex flex-column px-md-3 px-2"
              : "d-flex flex-column"
          }
        >
          <TextField
            variant="outlined"
            size="small"
            label="Name on the card:"
            value={cardname_val}
            onChange={(e) => setCardname_val(e.target.value)}
            id="name"
            className="outlined-basic"
          />
          <TextField
            variant="outlined"
            size="small"
            label="Card number:"
            value={cardnumber_val}
            onChange={(e) => setCardnumber_val(e.target.value)}
            className="mt-2 outlined-basic"
          />
          <div className="mt-2 d-flex justify-content-between">
            <div className="col-5 p-0 pr-1 ">
              <TextField
                variant="outlined"
                size="small"
                label="Expiration:"
                value={expire}
                onChange={(e) => setExpire(e.target.value)}
                className="w-100 outlined-basic"
              />
            </div>
            <div className="col-5 p-0">
              <TextField
                variant="outlined"
                size="small"
                // style={{fontSize:"0.2rem"}}
                label="3 Digit CVC Code:(Back of Card):"
                value={cvc_val}
                onChange={(e) => setCvc_val(e.target.value)}
                className="w-100 outlined-basic"
              />
            </div>
            <div className="col-2 p-0 pl-1">
              <select
                className="address-popup-input rounded w-100 h-100 border outlined-basic"
                onChange={(e) => setAddress_key(e.target.value)}
                style={{ fontSize: ".7em" }}
              >
                <option value={Store} selected>
                  <LazyLoadImage src={Store} loading="lazy" alt="" />
                </option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select>
            </div>
          </div>

          <div className="mt-2 d-flex justify-content-between">
            <select
              className="w-100 py-2"
              onChange={(e) => setAddress_key(e.target.value)}
              style={{
                border: "1px solid silver",
                borderRadius: "5PX",
                padding: "auto 10px",
                fontSize: ".8em",
              }}
            >
              {props._LA.address?.map((addr) => {
                return (
                  <option
                    selected={
                      addr.checkedDefaultAddress === true ? "selected" : null
                    }
                    value={addr._id}
                  >
                    {addr.name +
                      " - " +
                      addr.addr_line1 +
                      " " +
                      addr.city +
                      " " +
                      addr.state +
                      " " +
                      addr.country +
                      " " +
                      addr.zipcode}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="py-3 d-flex justify-content-end">
          <div>
            <button
              className={
                props._PAPopup === "cart"
                  ? "btn-sm bg-white addressBtnPopop"
                  : "btn addressBtnPopop"
              }
              onClick={(e) => {
                paymentPopupClose();
              }}
              style={{
                border: "1px solid var(--color_purple)",
                color: "var(--color_purple)",
              }}
            >
              Cancel
            </button>
          </div>
          <div className="px-1">
            {editedValue ? (
              <button
                className={
                  props._PAPopup === "cart"
                    ? "btn-sm text-light addressBtnPopop addressUpdateBtnPopop"
                    : "btn text-light addressBtnPopop addressUpdateBtnPopop"
                }
                onClick={(e) => {
                  updateCard(e);
                  paymentPopupClose(e);
                }}
                style={{ background: "var(--color_purple)" }}
              >
                Update Card
              </button>
            ) : (
              <button
                className={
                  props._PAPopup === "cart"
                    ? "btn-sm text-light addressBtnPopop addressAddBtnPopop"
                    : "btn text-light addressBtnPopop addressAddBtnPopop"
                }
                onClick={(e) => {
                  addCard(e);
                  paymentPopupClose(e);
                }}
                style={{ background: "var(--color_purple)" }}
              >
                Add Card
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  //console.log(state, "AddressPopup state changed...");
  return {
    _GLOBAL: state.item.GLOBAL,
    _CLICKED_INDEX: state.item.CLICKED_INDEX,
    _LC: state.item.LC,
    _LA: state.item.LA,
    _PAPopup: state.item.PAPopup,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    callApi,
  })(PaymentPopup)
);
